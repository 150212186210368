import PropTypes from 'prop-types'
import { useMemo, useState, useContext, useRef, useEffect, useCallback } from 'react'
import { blobToBase64 } from 'lib/types'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import usePreviousValue from 'hooks/misc/usePreviousValue'
import useCalculateScale from './DrawHooks/useCalculateScale/useCalculateScale'
import DrawLayer from './DrawLayer/DrawLayer'
import DrawActions from './DrawActions/DrawActions'
import DrawCropper from './DrawCropper/DrawCropper'
import DrawSkeleton from './DrawSkeleton/DrawSkeleton'
import './Draw.scss'

const cloneImageOfCropper = (target) => {
  const image = target.cropper.image
  return image.cloneNode(false)
}

const getImageCrop = async (cropper) => {
  const croppedCanvas = cropper.getCroppedCanvas()
  const blob = await new Promise((resolve) => croppedCanvas.toBlob(resolve))
  return await blobToBase64(blob)
}

const Draw = ({
  activePointIndex,
  originalImageKey,
  progressiveImageKey,
  container,
  isLoading,
  isAssignedUser,
  onCrop,
  onProgressiveLoad,
  onOriginalLoad,
  refetchInspection,
  children: roisLayer
}) => {
  const {
    cropStatus,
    setCropStatus
  } = useContext(CropContext)

  const originalRef = useRef(null)
  const progressiveRef = useRef(null)
  const originalCropper = originalRef.current?.cropper
  const progressiveCropper = progressiveRef.current?.cropper
  const [count, setCount] = useState(0)

  const [isOriginalLoaded, setIsOriginalLoaded] = useState(false)

  const [skeletonImage, setSkeletonImage] = useState(null)
  const previousSkeletonImage = usePreviousValue(skeletonImage)

  const scale = useCalculateScale({
    progressiveCropper,
    originalCropper,
    isLoaded: isOriginalLoaded,
  })

  const getElement = useCallback(({ isProgressive = false }) => {
    if (!originalRef) {
      console.error('originalRef is not defined')
      return
    }
    const imageElement = isProgressive ? progressiveRef.current : originalRef?.current
    return imageElement?.cropper
  }, [progressiveRef, originalRef])

  useEffect(() => {
    setIsOriginalLoaded(false)
  },[activePointIndex, progressiveImageKey, originalImageKey])

  const handleAccept = async () => {
    setCropStatus(CROP_STATUS.CROPPING)
    getImageCrop(originalCropper).then(url => {
      onCrop(url)
      originalCropper.clear()
      setCropStatus(CROP_STATUS.CROPPED)
    }).catch(error => {
      console.error('Ha ocurrido un error al recortar la imagen', error)
    })
  }

  const handleCancel = () => {
    originalCropper.clear()
    setCropStatus(CROP_STATUS.READY_TO_DRAW)
  }

  useEffect(() => {
    if (!isOriginalLoaded) {
      setCropStatus(CROP_STATUS.LOADED)
      return
    }

    // setCropStatus(isExpanded ? CROP_STATUS.READY_TO_DRAW : CROP_STATUS.LOADED)
  }, [isOriginalLoaded, originalImageKey, setCropStatus])

  const handleProgressiveReady = (event) => {
    setCount(oldCount => oldCount + 1)
    onProgressiveLoad(event)
    const cloned = cloneImageOfCropper(event.target)
    setSkeletonImage(cloned)
  }

  const handleOriginalReady = (event) => {
    setCount(oldCount => oldCount + 1)
    onOriginalLoad(event)
    setIsOriginalLoaded(true)
  }

  const showDrawActions = useMemo(() => [
    CROP_STATUS.DRAWN,
    CROP_STATUS.CROPPING
  ].includes(cropStatus),
  [cropStatus])

  return (
    <article ref={ container } className="draw">
      <section className="draw-wrapper">

        { isLoading && (
          <DrawSkeleton
            image={ previousSkeletonImage }
            isVisible={ true }
          />
        )}

        { (!isLoading) && (
          <DrawLayer
            selector='cropper-canvas'
            index={ 0 }
            trigger={ count }
          >{ roisLayer }</DrawLayer>
        )}

        <DrawCropper
          ref={ originalRef }
          progressiveRef={ progressiveRef }
          container={ container }
          originalImageKey={ originalImageKey }
          progressiveImageKey={ progressiveImageKey }
          getElement={ getElement }
          scale={ scale }
          isLoading={ isLoading }
          isAssignedUser={ isAssignedUser }
          onProgressiveReady={ handleProgressiveReady }
          onOriginalReady={ handleOriginalReady }
        />
          { showDrawActions && (
            <DrawActions
              refetchInspection={ refetchInspection }
              handleCancel={ handleCancel }
              handleAccept={ handleAccept }
            />
          )}
      </section>
    </article>
  )
}

Draw.propTypes = {
  originalImageKey: PropTypes.string,
  progressiveImageKey: PropTypes.string,
  container: PropTypes.object,
  isLoading: PropTypes.bool,
  isAssignedUser: PropTypes.bool,
  onCrop: PropTypes.func,
  onProgressiveLoad: PropTypes.func,
  onOriginalLoad: PropTypes.func,
  refetchInspection: PropTypes.func,
  children: PropTypes.node
}

export default Draw
