import { useContext } from 'react'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import SplitPanelContext, { PANEL_STATUS } from 'adapter/context/SplitPanelContext'

export const MinifyMapButton = () => {
  const { setActivePanel } = useContext(SplitPanelContext)

  const handleMinify = () => {
    setActivePanel(PANEL_STATUS.MAP_MINIFY)
  }

  return (
    <section className="map__minify">
      <Tooltip
        label="Clica para minimizar el mapa"
        direction="right"
      >
        <span
          className="icon--union"
          onClick={ handleMinify }
        ></span>
      </Tooltip>
    </section>
  )
}

export const MaximizeMapButton = () => {
  const { setActivePanel } = useContext(SplitPanelContext)

  const handleMaximize = () => {
    setActivePanel(PANEL_STATUS.SPLIT_VIEW)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 300)
  }
  return (
    <section className="map__maximize" onClick={ handleMaximize }>
      <Tooltip
        label="Clica para maximizar el mapa"
        direction="top"
      >
        <span></span>
      </Tooltip>
    </section>
  )
}
