import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { formatBytes } from 'lib'
import Button from 'ui/elements/Button/Button'
import useConfirmButton from 'hooks/dom/useConfirmButton'

const FileRow = ({ file, onRemoveFile }) => {
  const callbackToAction = useCallback(() =>
    onRemoveFile({ id: file.id, type: file.type }),
  [file.id, file.type, onRemoveFile])

  const { handleConfirm, confirmText } = useConfirmButton({
    label: 'Eliminar',
    callbackToAction,
    secondsToConfirm: 10
  })
  return (
    <tr>
      <td className="cell-file">{ file.name }</td>
      <td className="cell-size">{ formatBytes(file.size) }</td>
      <td className="cell-remove">
        <Button
          variant="secondary"
          className="button-clean --failure"
          onClick={ handleConfirm }
        >{ confirmText }</Button>
      </td>
    </tr>
  )
}

FileRow.propTypes = {
  file: PropTypes.object.isRequired,
  onRemoveFile: PropTypes.func
}

export default FileRow
