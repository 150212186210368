import Tooltip from 'ui/elements/Tooltip/Tooltip'
import './HelpButton.scss'

const helpTooltip = () => (
  <div className="help-tooltip">
    <p>Atajos de teclado</p>
    <div className="help-tooltip__info">
      <p className="help-tooltip__subtitle">Navegar entre<br/> imágenes</p>
      <span className="keycap --first">a</span>
      <span className="keycap">d</span>
    </div>
  </div>
)

const HelpButton = ({ className }) => (
  <section className={`help-button ${ className ? className :'' }`}>
    <Tooltip
      className="help-button__tooltip"
      label={ helpTooltip() }
      scroll={ false }
    >?</Tooltip>
  </section>
)

export default HelpButton
