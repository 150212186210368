import * as Toaster from 'ui/elements/Toast/Toast'

async function isStillAssignedUser ({ trigger, onSuccess, onFailure }) {
  if (!onFailure) throw new Error('onFailure callback is not included')
  !trigger && onFailure?.('trigger paramater is not included')
  try {
    const response = await trigger()
    const { inspection } = response?.data
    if (!inspection?.isAssignedUser) {
      const errorMessage = 'El usuario actual ya no está asignado en esta inspección'
      Toaster.showCancel(errorMessage)
      onFailure?.(errorMessage)
      return
    }
    onSuccess?.()
  } catch (error) {
    Toaster.showCancel('Ha ocurrido un error. Inténtenlo más tarde')
    console.error(error)
    onFailure?.(error)
  }
}

export default isStillAssignedUser
