import PropTypes from 'prop-types'
import { useMemo, useContext, useCallback, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import Spinner from 'ui/elements/Spinner/Spinner'
import useAuthToken from 'hooks/auth/useAuthToken'
import useMarkers from 'hooks/models/markers/useMarkers'
import useImageKey from 'hooks/misc/useImageKey/useImageKey'
import useNavigationByKeyboard from 'hooks/event/useNavigationByKeyboard'
import useGetAssetsBySettings from 'hooks/models/assets/useGetAssetsBySettings'
import { useHandleLastImageVisited } from 'adapter/database/useHandleLastImageVisited'
import SplitPanelContext from 'adapter/context/SplitPanelContext'
import { MinifyMapButton, MaximizeMapButton } from './Minify/MinifyButtons'
import drawMarkers from './Markers/MarkersLayer/MarkersLayer'
import drawAssets from './Assets/AssetsLayer/AssetsLayer'
import HelpButton from './HelpButton/HelpButton'
import config, { GoogleMapsConfig } from './config/MapConfig'
import './Map.scss'

const Map = ({
  inspectionId,
  inspectionName,
  images,
  setActivePointIndex,
  isLoading,
  onPress = () => null,
}) => {
  const [centerPoint, setCenterPoint] = useState({})
  const [currentPointHovered, setCurrentPointHovered] = useState(null)
  const [currentAssetHovered, setCurrentAssetHovered] = useState(null)
  const { isMapMinified } = useContext(SplitPanelContext)
  const markers = useMarkers({ images })
  const { imageKey } = useImageKey({
    images,
    index: currentPointHovered,
    keyField: config.thumbnailUrlField
  })
  const { assets } = useGetAssetsBySettings({ inspectionId })
  const [, , , authUser] = useAuthToken()

  const {
    currentImageIndexVisited,
    setCurrentImageIndexVisited
  } = useHandleLastImageVisited({ inspectionId, username: authUser, callback: setActivePointIndex })

  const { isPressed } = useNavigationByKeyboard({
    isEnabled: markers?.length > 1,
    counter: currentImageIndexVisited,
    limit: markers?.length - 1,
    setter: setCurrentImageIndexVisited,
  })

  useEffect(() => {
    onPress(isPressed)
  }, [onPress, isPressed])

  useEffect(() => {
    const currentMarker = markers[currentImageIndexVisited]
    if (!markers || !currentMarker) return
    const { latitude, longitude } = markers?.length > 0 ? currentMarker : config.defaultCenter
    setCenterPoint({ lat: latitude, lng: longitude })
  }, [markers, setCenterPoint, currentImageIndexVisited, inspectionId])

  const handleHover = useCallback((index, isHovered) => {
    setCurrentPointHovered(isHovered ? index : null)
  }, [setCurrentPointHovered])

  const defaultCenter = useMemo(() => ({
    lat: config.defaultCenter.latitude,
    lng: config.defaultCenter.longitude
  }), [])

  const MarkersLayer = drawMarkers({
    markers,
    handleHover,
    activePointIndex: currentImageIndexVisited,
    urlKey: imageKey,
    onClick: (payload) => {
      setCurrentImageIndexVisited(payload)
      setActivePointIndex(payload)
    },
  })
  const AssetsLayer = drawAssets({ assets, handleHover: setCurrentAssetHovered, currentAssetHovered })

  return (
    <article className="map">
      <section className="map__container">
        { inspectionName && (<p className="map__name">{ inspectionName }</p>) }
        { isLoading && <Spinner className="loading" /> }
        <section className={ `map__inner ${isMapMinified ? '--is-mini': '' }`}>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: GoogleMapsConfig.apiKey }}
            options={ GoogleMapsConfig.mapOptions }
            defaultZoom={ config.defaultZoom }
            center={ centerPoint }
            defaultCenter={ defaultCenter }
            hoverDistance={ 41 / 2 }
          >
            { MarkersLayer }
            { AssetsLayer }
          </GoogleMapReact>

          { isMapMinified && <MaximizeMapButton /> }
        </section>
        { !isMapMinified && <MinifyMapButton /> }

        { markers.length > 1 && <HelpButton className="map__help" /> }
      </section>
    </article>
  )
}

Map.propTypes = {
  inspectionId: PropTypes.string,
  inspectionName: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  setActivePointIndex: PropTypes.func,
  isLoading: PropTypes.bool,
  onPress: PropTypes.func,
}

export default Map
