import { DETECTION_TYPES } from 'domain/detection'
import PropTypes from 'prop-types'
import Dot, { DOT_TYPES }from 'ui/elements/Dot/Dot'
import './Summary.scss'

const SummaryField = ({ statusId, label, value }) => {
  if (!statusId || !label) return null
  return (
    <p>
      <Dot statusId={ statusId } />
      <span>{ label } </span>
      <span className="photo-summary__status-dot">{ value || 0 }</span>
    </p>
  )
}

SummaryField.propTypes = {
  statusId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
}

const EMPTY_CHARACTER = '-'

const filterByValidation = (collection = [], type) =>
  collection.filter(({ validation }) => validation?.status === type)

const Summary = ({ index = 0, detections = [], className, typeDetection = DETECTION_TYPES.ANOMALY }) => {
  const validatedLength = filterByValidation(detections, DOT_TYPES.CORRECT.id).length
  const pendingLength = detections.length - validatedLength
  return (
   <div className={ `photo-summary ${className}`}>
      <i className="photo-summary__identifier"
        data-testid="photo-summary-identifier"
      >{ (index !== null ? index + 1 : EMPTY_CHARACTER ) }</i>
      <section className="photo-summary__status">
        <p className="photo-summary__total">{`${typeDetection === DETECTION_TYPES.ANOMALY ? 'Anomalías' : 'Conjuntos' } totales`} ({ detections.length || 0 })</p>
        <section className="photo-summary__shelled">
          <SummaryField
            statusId={ DOT_TYPES.CORRECT.id }
            label="validados"
            value={ validatedLength !== 0 ? validatedLength: EMPTY_CHARACTER }
          />
          <SummaryField
            statusId={ DOT_TYPES.PENDING.id }
            label="pendientes"
            value={ pendingLength !== 0 ? pendingLength : EMPTY_CHARACTER }
          />
        </section>
      </section>
    </div>
  )
}

Summary.propTypes = {
  index: PropTypes.number,
  detections: PropTypes.array,
  className: PropTypes.string,
}

export default Summary
