import { isVideoFile } from 'domain/files/types/types'

const canBeCreateAInspectionOfVideoType = (title, files) => {
  return (
    title?.length > 0 &&
    files.length === 1 &&
    isVideoFile(files[0])
  )
}

export default canBeCreateAInspectionOfVideoType
