const UPPY_EVENTS = Object.freeze({
  FILES_ADDED: 'files-added',
  UPLOAD_ERROR: 'upload-error',
  COMPLETE: 'complete',
  PROGRESS: 'progress',
  IS_OFFLINE: 'is-offline',
  BACK_ONLINE: 'back-online'
})

const UPPY_ACTIONS = Object.freeze({
  UPLOAD: 'upload',
  ADD_FILE: 'addFile',
  REMOVE_FILE: 'removeFile',
  PAUSE: 'pauseAll',
  RESUME: 'resumeAll',
  CANCEL: 'cancelAll'
})

export {
  UPPY_EVENTS as EVENTS,
  UPPY_ACTIONS as ACTIONS,
}
