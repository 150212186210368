import { pick } from 'lodash'
import { useState, useCallback, useEffect, useRef } from "react"

const pickSubset = (original, properties) => pick(original, properties)

function useCopyStyle ({ selector, index = 0, trigger, properties }) {
  if (!properties) {
    throw new Error('useCopyStyle hook need a properties parameter to be copied.')
  }
  const observer = useRef(null)
  const [styleCopied, copyStyle] = useState({})
  const ref = useRef(null)

  const applyTransform = useCallback((target) => {
    if (!target) return
    const picked = pickSubset(target.style, properties)
    copyStyle(picked)
  }, [copyStyle, properties])

  useEffect(() => {
    ref.current = document.getElementsByClassName(selector)[index]
    if (!ref.current) return
    applyTransform(ref.current)

    observer.current = new MutationObserver(() => {
      applyTransform(ref.current)
    })

    observer.current.observe(ref.current, {
      attributes: true,
      attributeFilter: ['style']
    })

    return (() => {
      observer.current.disconnect()
    })
  }, [selector, trigger, index, applyTransform])

  return { styleCopied }
}

export default useCopyStyle
