import { useLiveQuery } from "dexie-react-hooks"
import { db } from "adapter/database/database"
import { useState, useEffect } from "react"

const INITIAL_IMAGE = 0

const useGetLastImageIndexVisited = ({ inspectionId, username }) => {
  const inspectionFromDB = useLiveQuery(async () => {
    if (!inspectionId) return null
    const item = await db.inspections.where({ id: inspectionId, username }).first()
    return item || { lastImageVisited: INITIAL_IMAGE }
  }, [inspectionId])

  return inspectionFromDB?.lastImageVisited
}

const useUpdateLastImageIndexVisited = ({ inspectionId, currentImageIndexVisited, username }) => {
  useLiveQuery(async () => {
    if (!inspectionId) return
    if (currentImageIndexVisited === null) return
    if (Number.isNaN(currentImageIndexVisited)) return
    const row = {
      id: inspectionId,
      username,
      lastImageVisited: currentImageIndexVisited
    }
    db.inspections.put(row, inspectionId)
  }, [inspectionId, currentImageIndexVisited])
}

export const useHandleLastImageVisited = ({ inspectionId, username, callback }) => {
  const lastImageIndexVisited = useGetLastImageIndexVisited({ inspectionId, username })
  const [currentImageIndexVisited, setCurrentImageIndexVisited] = useState(lastImageIndexVisited || null)

  useEffect(() => {
    if (lastImageIndexVisited >= 0) {
      setCurrentImageIndexVisited(lastImageIndexVisited)
    }
  }, [lastImageIndexVisited])

  useEffect(() => {
    if (!callback) return
    callback(currentImageIndexVisited)
  }, [callback, currentImageIndexVisited])

  useUpdateLastImageIndexVisited({ inspectionId, currentImageIndexVisited, username })

  return {
    currentImageIndexVisited,
    setCurrentImageIndexVisited,
  }
}
