import PropTypes from 'prop-types'
import DrawLayer, { MODE as DrawLayerMode } from '../DrawLayer/DrawLayer'

const DrawSkeleton = ({ image = null }) => (
  <DrawLayer
    selector='cropper-canvas'
    trigger={ image !== null }
    toyImage={ image }
    isLoading={ true }
    mode={ DrawLayerMode.SKELETON }
    isVisible={ true }
  />
)

DrawSkeleton.propTypes = {
  image: PropTypes.object,
  isVisible: PropTypes.bool
}

export default DrawSkeleton
