import { isImageFile } from 'domain/files/types/types'
import geolocationTags from './geolocationTags'
import getMetadata from './getMetadata'

const getSignedCoordinates = (geolocationParams) =>{
  const signedGeolocationParams = {}
  let latitude = geolocationParams['latitude'] || null
  const latitudeRef = geolocationParams['latitudeRef'] || null
  if (latitudeRef && latitudeRef=="South latitude") {
    latitude = "-" + latitude
  } 

  let longitude = geolocationParams['longitude'] || null
  const longitudeRef = geolocationParams['longitudeRef'] || null
  if (longitudeRef && longitudeRef=="West longitude") {
    longitude = "-" + longitude
  } 

  signedGeolocationParams['latitude']  = latitude
  signedGeolocationParams['longitude']  = longitude
  signedGeolocationParams['altitude']  = geolocationParams['altitude'] || null

  return signedGeolocationParams
} 

const getGeolocationParams = (tags) => {
  const geolocationParams = {}
  Object.keys(geolocationTags).forEach(tag => {
    const param = geolocationTags[tag]
    const field = param?.field || param
    let value = tags[field]?.description
    if (param?.parse) {
      value = param.parse(value)
    }
    geolocationParams[tag] = value
  })
  return geolocationParams
}

const getGeolocationOfImage = async (file) => {
  try {
    const metadata = await getMetadata(file)
    const geolocation = getSignedCoordinates(getGeolocationParams(metadata))
    if (!geolocation?.latitude || !geolocation?.longitude) {
      throw new Error(`The image "${file.data.name}" doesn't have geolocation.`)
    }
    return geolocation
  } catch (error) {
    console.error(error)
    return null
  }
}

const getGeolocationOfImages = async (files) => {
  if (!files || !files?.length || files?.length === 0 || !Array.isArray(files)) {
    throw new Error('There are not images to look for his geolocation.')
  }
  const result = []
  const imageFiles = files.filter((file) => isImageFile(file))
  await Promise.all(imageFiles.map(async (image) => {
    const geolocation = await getGeolocationOfImage(image)
    result.push({ ...image, geolocation })
  }))
  return result
}

export default getGeolocationOfImages
