import PropTypes from 'prop-types'
import Video from 'ui/elements/Video/Video'
import './VideoViewer.scss'

const VideoViewer = ({ src }) => (
  <Video
    src={ src }
    width={ 740 }
    className="video-viewer"
    type="video/mp4"
  />
)

VideoViewer.propTypes = {
  src: PropTypes.string,
}

export default VideoViewer
