import { useMutation } from '@apollo/client'
import { REVIEW_DETECTION_MUTATION } from 'infra/detection/reviewDetection.mutation'

export default function useReviewDetection({ onCompleted, onError }) {
  const [reviewDetection, { loading }] = useMutation(REVIEW_DETECTION_MUTATION, {
    onCompleted(response) {
      onCompleted && onCompleted(response)
    },
    onError: (error) => {
      onError && onError(error)
    },
  })

  return {
    loading,
    launch: (input) => {
      reviewDetection({
        variables: {
          input,
        },
      })
    }
  }
}
