export const MODE = {
  REGULAR: 'regular',
  SKELETON: 'skeleton'
}

const TOY_IMAGE_STYLE = {
  width: '100%',
  height: 'auto'
}

export const STYLES_TO_BE_COPIED = [
  'width',
  'height',
  'transform'
]

export const append = (wrapper, child, mode) => {
  if (!wrapper || !child) return
  const node = wrapper.appendChild(child)
  node.style.width = TOY_IMAGE_STYLE.width
  node.style.height = TOY_IMAGE_STYLE.height
  mode === MODE.SKELETON && node.classList.add('draw-layer--skeleton')
  return node
}
