import { useState, useContext } from 'react'
import {
  BrowserRouter,
  Switch,
} from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { ToastContainer } from 'react-toastify'

import Protected, {
  RedirectProtected,
  NotFoundProtected
} from 'ui/elements/Protected/Protected'

import Layout from 'ui/elements/Layout/Layout'
import Main from 'ui/elements/Main/Main'
import Header from 'ui/components/Header/Header'
import Mobile from 'ui/components/Mobile/Mobile'

import SettingsContext from 'adapter/context/SettingsContext'

import useSettings from 'hooks/settings/useSettings'
import useAuthToken from 'hooks/auth/useAuthToken'

import Login, * as LoginConfig from 'ui/pages/Login/Login'
import Dashboard, * as DashboardConfig from 'ui/pages/Dashboard/Dashboard'
import NewInspection, * as NewInspectionConfig from 'ui/pages/NewInspection/NewInspection'
import GeoDashboard, * as GeoDashboardConfig from 'ui/pages/GeoDashboard/GeoDashboard'
import Detail, * as DetailConfig from 'ui/pages/Detail/Detail'
import GeoDetail, * as geoDetailConfig from 'ui/pages/GeoDetail/GeoDetail'
import GeoDetections, * as geoDetectionsConfig from 'ui/pages/GeoDetections/GeoDetections'
import GeoFiles, * as geoFilesConfig from 'ui/pages/GeoFiles/GeoFiles'
import Collection, * as collectionConfig from 'ui/pages/Collection/Collection'
import NotFound, * as NotFoundConfig from 'ui/pages/NotFound/NotFound'

const Router = () => {
  const [isSplitLayout, setIsSplitLayout] = useState(false)

  const [authToken] = useAuthToken() // (1)
  const { loading } = useSettings({ authToken })
  const settings = useContext(SettingsContext)

  const { cleanLayout } = settings || {}
  const DashboardComponent = cleanLayout ? GeoDashboard : Dashboard

  return (
    <BrowserRouter>
      <Layout isLoading={ loading }>
        { !isSplitLayout && authToken && <Header /> }
        <Main withoutPadding={ isSplitLayout }>
          <Switch>

            { /* DASHBOARD PAGE (LEGACY OR GEO) */ }
            <Protected
              path={ `/${DashboardConfig.PATH}` }
              component={ DashboardComponent }
              handlePathname={ setIsSplitLayout }
              title={ GeoDashboardConfig.pageName }
              exact
            />

            { /* COLLECTION PAGE > CATEGORY */ }
            <Protected
              path={`/${collectionConfig.PATH}/:${collectionConfig.PARAM}`}
              component={ Collection }
              title={ collectionConfig.pageName }
              exact
            />

            { /* COLLECTION PAGE > ALL CATEGORIES */ }
            <RedirectProtected
              path={`/${collectionConfig.PATH}`}
              to={ `/${collectionConfig.PATH}/${collectionConfig.CATEGORY_ALL}` }
              title={ collectionConfig.pageName }
              exact
            />

            { /* DETAIL OF INSPECTION PAGE (LEGACY) */ }
            <Protected
              path={`/${DetailConfig.PATH}/:${DetailConfig.PARAM}`}
              component={ Detail }
              title={ DetailConfig.pageName }
            />

            { /* NEW INSPECTION PAGE */ }
            <Protected
              path={`/${NewInspectionConfig.PATH}`}
              component={ NewInspection }
              handlePathname={ setIsSplitLayout }
              title={ NewInspection.pageName }
            />

            { /* GEO-DETAIL OF INSPECTION PAGE */ }
            <Protected
              path={`/${geoDetailConfig.PATH}/:${geoDetailConfig.PARAM}`}
              component={ GeoDetail }
              handlePathname={ setIsSplitLayout }
              title={ geoDetailConfig.pageName }
            />

            { /* GEO-DETECTIONS PAGE */ }
            <Protected
              path={`/${geoDetectionsConfig.PATH}/:${geoDetectionsConfig.PARAM}`}
              component={ GeoDetections }
              handlePathname={ setIsSplitLayout }
              title={ geoDetectionsConfig.pageName }
            />

            { /* GEO-FILES OF INSPECTION PAGE */ }
            <Protected
              path={`/${geoFilesConfig.PATH}/:${geoFilesConfig.PARAM}`}
              component={ GeoFiles }
              handlePathname={ setIsSplitLayout }
              title={ geoFilesConfig.pageName }
            />

            { /* LOGIN PAGE */ }
            <Protected
              path={`/${LoginConfig.PATH}`}
              component={ Login }
              handlePathname={ setIsSplitLayout }
              exact
            />

            { /* DEFAULT PAGE (REDIRECT TO DASHBOARD) */ }
            <RedirectProtected
              path="/"
              to={`/${DashboardConfig.PATH}`}
              handlePathname={ setIsSplitLayout }
              exact
            />

            { /* NOT FOUND PAGE */ }
            <NotFoundProtected
              path={ `/${NotFoundConfig.PATH}` }
              component={ NotFound }
              handlePathname={ setIsSplitLayout }
              title={ NotFoundConfig.pageName }
              exact
            />

            <NotFoundProtected
              path={ '/*' }
              component={ NotFound }
              handlePathname={ setIsSplitLayout }
            />

          </Switch>
        </Main>
        <MediaQuery maxDeviceWidth={ 1023 }>
          <Mobile />
        </MediaQuery>
        <ToastContainer
          icon={ false }
          limit={ 2 }
          newestOnTop={ true }
        />
      </Layout>
    </BrowserRouter>
  )
}

export default Router

// (1): This hack allows get the settings the first time the user is logged in.
// This is necessary because the settings are not available in the first render.
// The settings are available in the second render.
// This causes the settings to be loaded twice, but it's assumable.
// See adapter/context/SettingsContext.js:
