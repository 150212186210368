import PropTypes from 'prop-types'
import Dates from 'ui/elements/Dates/Dates'
import Button from 'ui/elements/Button/Button'
import RadialStatus from 'ui/components/RadialStatus/RadialStatus'
import DownloadFiles from 'ui/components/DownloadFiles/DownloadFiles'
import InspectionTitle from '../InspectionTitle/InspectionTitle'
import './InspectionLegacy.scss'

const InspectionLegacy = ({
  path,
  inspection: {
    id,
    name,
    description,
    createdAt,
    progress,
    status,
    metadata,
  },
  withFiles,
}) => (
  <tr
    id={ `inspection-item-${id}` }
    className='inspection-item-legacy'
  >
    <td className="inspection-item-legacy__detail">
      <InspectionTitle
        name={ name }
        description={ description }
      />
    </td>
    <td className="inspection-item-legacy__date">
      <Dates isoDate={ createdAt } />
    </td>
    <td className="inspection-item-legacy__progress">
      <RadialStatus
        status={ status }
        progress={ progress }
      />
    </td>

    { withFiles && (
      <td className="inspection-item-legacy__files">
        <DownloadFiles files={ metadata } />
      </td>
    )}

    <td className="inspection-item-legacy__detail">
      <Button to={ `/${path}/${id}` }
        variant="secondary"
      >Ver detalle</Button>
    </td>
  </tr>
)

InspectionLegacy.propTypes = {
  path: PropTypes.string,
  inspection: PropTypes.object,
  withFiles: PropTypes.bool,
}

export default InspectionLegacy
