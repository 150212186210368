const FieldFile = ({
  className,
  label,
  children,
  required,
  invalid,
  error,
}) => {
  return (
    <div
      className={`field field--file
        ${className}
        ${invalid && 'invalid'}
        ${required && 'required'}
      `}
    >
      <span className="field__label">{invalid ? error : label}</span>
      {children}
    </div>
  )
}

export default FieldFile
