import { gql } from '@apollo/client'

const INSPECTION_BASE = gql`
  fragment InspectionBaseFragment on Inspection {
    id
    name
    createdAt
    status
    description
    progress
    accountId
    assignedUser {
      id
      username
    }
    isAssignedUser
    metadata {
      key
      value
    }
  }
`

export default INSPECTION_BASE
