import PropTypes from 'prop-types'
import InspectionLegacy from '../../Inspection/legacy/InspectionLegacy'
import './InspectionsLegacy.scss'

const InspectionsLegacy = ({
  inspections,
  path,
  withFiles,
  isLoading,
}) => (
  <table className="inspections-list-legacy table">
    <thead className="inspections-list-legacy__header">
      <tr>
        <th>Título y Descripción</th>
        <th>Fecha / Hora</th>
        <th>Progreso</th>
        { withFiles && <th>Archivos</th> }
        <th>Fuentes</th>
      </tr>
    </thead>
    <tbody className="inspections-list-legacy__body">

      { isLoading && <tr><td colSpan="5">Cargando...</td></tr> }

    { inspections.map((inspection) => (
      <InspectionLegacy
        key={ `inspection-${inspection.id}` }
        path={ path }
        inspection={ inspection }
        withFiles={ withFiles }
      />
    ))}
    </tbody>
  </table>
)

InspectionsLegacy.propTypes = {
  inspections: PropTypes.array,
  path: PropTypes.string,
  withFiles: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default InspectionsLegacy
