import { useContext } from 'react'
import SkeletonTable from 'ui/elements/Table/SkeletonTable/SkeletonTable'
import InspectionSummary from 'ui/components/InspectionSummary/InspectionSummary'

import useLayout from 'hooks/misc/useLayout'
import useInspectionDetail from 'hooks/models/inspection/useInspectionDetail'
import useFormatFilesOfInspection from 'hooks/models/inspection/useFomatFilesOfInspection'
import { isVideoUploadType } from 'ui/pages/NewInspection/Uploader/UploadTypes/UploadTypes'
import SettingsContext from 'adapter/context/SettingsContext'
import ReassignUserForm from './ReassignUserForm/ReassignUserForm'
import ListOfFiles from './ListOfFiles/ListOfFiles'
import Layout from '../NewInspection/elements/Layout/Layout'
import './GeoFiles.scss'

export const PARAM = 'inspectionId'
export const PATH = 'inspection-files'
export const pageName = 'Fuente de datos'

const GeoFiles = ({ match, handlePathname }) => {
  const { location } = useLayout({ path: PATH, handlePathname })
  const { params } = match
  const inspectionId = params[PARAM]
  const { uploadType, multipleFileUpload } = useContext(SettingsContext)
  const areVideoFiles = isVideoUploadType(uploadType)

  const {
    data: inspection,
    loading: isLoading,
    error,
    refetch
  } = useInspectionDetail({ inspectionId, isWithFiles: true })

  const parsedFiles = useFormatFilesOfInspection(inspection, { areVideoFiles })
  const numberOfSkeletonRows = multipleFileUpload ? 4 : 1

  return (
    <Layout
      inspectionId={ inspectionId }
      isInspectionWithError={ location?.state?.isInspectionWithError }
      aside={
        <InspectionSummary
          name={ inspection?.name }
          description={ inspection?.description }
          createdAt={ inspection?.createdAt }
        >
          <ReassignUserForm
            inspectionId={ inspectionId }
            assignedUser={ inspection?.assignedUser }
            refetch={ refetch }
          />
        </InspectionSummary>
      }
    >
      <article className="wrapper-scroll">
        <ListOfFiles
          files={ parsedFiles || [] }
          isLoading={ isLoading }
          error={ error }
          areVideoFiles={ areVideoFiles }
          skeleton={ <SkeletonTable numberOfRows={ numberOfSkeletonRows }/> }
        />
      </article>
    </Layout>
  )
}

export default GeoFiles
