import { gql } from '@apollo/client'

export const field = 'settings'

export default gql`
  query settings {
    settings {
      cleanLayout
      multipleLevelCatalog
      uploadType
      multipleFileUpload
      bigChunk
      inspectionFiles
    }
  }
`
