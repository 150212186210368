import PropTypes from 'prop-types'
import { useEffect } from 'react'
import './Asset.scss'

const Asset = ({
  active,
  children,
  reference,
  $hover,
  handleHoverCallback
}) => {
  const hoverClassName = $hover ? '--hover': ''

  useEffect(() => {
    handleHoverCallback($hover)
  }, [$hover, handleHoverCallback])

  return (<>
    <div
      className={ `asset ${hoverClassName}` }
      data-active={ active }
      data-external-reference={ reference }
      data-testid="asset"
    ></div>
    { $hover && children }
  </>)
}

Asset.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  reference: PropTypes.string,
  $hover: PropTypes.bool,
  handleHoverCallback: PropTypes.func
}

export default Asset
