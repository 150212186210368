import { useMutation } from '@apollo/client'
import { mutation } from 'infra/inspection/deleteInspection.mutation'

function useDeleteInspection ({
  onCompleted,
  onError,
}) {
  const [deleteInspection, { loading }] = useMutation(mutation, {
    onCompleted (response) {
      onCompleted?.(response)
    },
    onError (error) {
      console.error(error)
      onError?.(error)
    }
  })

  return {
    loading,
    launch: ({ inspectionId }) => {
      deleteInspection({
        variables: { inspectionId },
      })
    }
  }
}

export default useDeleteInspection
