import PropTypes from 'prop-types'
import { capitalize, SKELETON_TEXT } from 'lib'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Dot, { isStatusInRange, getStatusLabel } from 'ui/elements/Dot/Dot'
import './Tooltip.scss'
import { TYPES } from 'domain/detection'

function textTooltip (
  parentCategory,
  asset,
  category,
  anomaly,
  severity,
  validation
) {
  const hasAsset = asset || validation?.asset || false
  const isLight = !hasAsset && !parentCategory && !severity

  return (
    <dl>
      { !isLight && (<>
        <dt>Tipo de objeto</dt>
        <dd>{ capitalize(parentCategory) || SKELETON_TEXT }</dd>
        <dt>Matrícula</dt>
        <dd>{ asset || validation?.asset?.name || SKELETON_TEXT }</dd>
      </>)}

      { category && (<>
        <dt>Conjunto</dt>
        <dd>{ capitalize(category) }</dd>
      </>)}

      { anomaly && (<>
        <dt>Operación</dt>
        <dd>{ capitalize(anomaly) }</dd>
      </>)}

      { !isLight && (<>
        <dt>Valor PM/Grado repercusión</dt>
        <dd>{ (severity && capitalize(severity)) || capitalize(validation?.severity?.value) || SKELETON_TEXT }</dd>
      </>)}

      { isStatusInRange(validation?.status) && (<>
        <dt>Estado</dt>
        <dd><Dot statusId={ validation?.status }/>{ getStatusLabel(validation?.status) }</dd>
      </>)}
    </dl>
  )
}

const PhotoTooltip = ({
  detection,
  anomaly,
  asset,
  severity,
  validation
}) => {
  if (detection?.type === TYPES.CATEGORY) {
    return (
      <Tooltip
        className="photo-tooltip"
        label={
          textTooltip(
            detection?.parentCategory?.name,
            asset?.name,
            detection?.category?.name,
            SKELETON_TEXT,
            SKELETON_TEXT,
            validation
          )
        }
        scroll={ false }
      />
    )
  }
  if (!anomaly) {
    return null;
  }
  return (
    <Tooltip
      className="photo-tooltip"
      label={
        textTooltip(
          anomaly?.parentCategory?.name,
          asset?.name,
          anomaly?.category?.name,
          anomaly?.name,
          severity?.value,
          validation
        )
      }
      scroll={ false }
    />
  )
}

PhotoTooltip.propTypes = {
  anomaly: PropTypes.object,
  asset: PropTypes.object,
  severity: PropTypes.object,
  validation: PropTypes.object
}

export default PhotoTooltip
