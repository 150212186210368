export const SKELETON_TEXT = '---';

export const capitalize = (string) => {
  if (!string) return ''
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const trimUnderscore = (string) => {
  if (!string) return ''
  if (typeof string !== 'string') return ''
  return string.replace(/_/g, ' ')
}

export const print = (field) => capitalize(field) || SKELETON_TEXT
