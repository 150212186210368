export function updateWithCache (cache, newEntry, {
  fieldCached,
  queryCached,
  variables
}) {
  const allResulstsOfCache = cache.readQuery({
    query: queryCached,
    variables
  })
  const rest = allResulstsOfCache[fieldCached]
  const data = [newEntry, ...rest]
  cache.writeQuery({
    query: queryCached,
    variables,
    data: { [fieldCached]: data }
  })
}

export function updateWithCachePaginated (cache, newEntry, {
  fieldCached,
  queryCached,
  variables
}) {
  const allResulstsOfCache = cache.readQuery({
    query: queryCached,
    variables: { id: variables.inspectionId , limit: 100, cursor: 10 },
  })
  const rest = allResulstsOfCache[fieldCached].edges
  const itemCached = rest.find(({ node }) => node.id === newEntry.detectionId)

  cache.modify({
    id: cache.identify(itemCached.node),
    fields: {
      validation(validationCache) {
        return newEntry.status
      }
    }
  })
}
