import { useContext } from 'react'
import useGetCategoryFilteredByName from 'hooks/models/categories/useGetCategoryFilteredByName'
import useGetAssets from 'hooks/models/assets/useGetAssets'
import SettingsContext from 'adapter/context/SettingsContext'

const KILOMETER_ASSET = 'Punto Kilométrico'
const SUPPORT_ASSET = 'Apoyo'

const useGetAssetsBySettings = ({ inspectionId }) => {
  const settings = useContext(SettingsContext)
  const multipleLevelCatalog = settings?.multipleLevelCatalog || false
  const categoryName = multipleLevelCatalog ? SUPPORT_ASSET : KILOMETER_ASSET
  const { data: category } = useGetCategoryFilteredByName({ categoryName })

  const { data: assets } = useGetAssets({
    inspectionId,
    categoryId: category?.id,
  })

  return { assets }
}

export default useGetAssetsBySettings
