import { useState, useEffect } from 'react'
import Button from 'ui/elements/Button/Button'
import { FieldText, TYPES } from 'ui/elements/Field'
import useLogin from 'hooks/auth/useLogin'
import * as Skin from './Skin'
import './Login.scss'

export const PATH = 'login'
export const pageName = 'Login'

const Login = () => {
  const { launch: login, loading, error } = useLogin()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    Skin.add()
    return () => Skin.remove()
  }, [])

  const userAdded = (event) => {
    setUsername(event.target.value)
    setIsValid(false)
  }

  const passwordAdded = (event) => {
    setPassword(event.target.value)
    setIsValid(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    login(username, password)
    setIsValid(true)
  }

  return (
    <section className="login">
      <h1>Iniciar sesión</h1>
      <p>
        { isValid && error
          ? 'Usuario y/o contraseña incorrectos'
          : 'Por favor, incluye usuario y contraseña'}
      </p>

      <form
        id="login-form"
        data-testid="login-form"
        className="login__form"
        autoComplete="off"
        onSubmit={ handleSubmit }
      >
        <fieldset className="login__fieldset">
          <FieldText
            id="login-name"
            className="login__field"
            name="username"
            type={ TYPES.TEXT }
            placeholder=""
            label="Usuario"
            required
            invalid={ isValid && error }
            error="Comprueba que el usuario es el correcto"
            autoComplete="off"
            onChange={ userAdded }
          />

          <FieldText
            id="login-pass"
            className="login__field"
            name="password"
            type={ TYPES.PASSWORD }
            placeholder=""
            label="Contraseña"
            required
            invalid={ isValid && error }
            error="Comprueba que la contraseña sea correcta"
            autoComplete="new-password"
            onChange={ passwordAdded }
          />
        </fieldset>

        <fieldset className="login__buttonset">
          <Button
            id="login-btn"
            type="submit"
            disabled={ loading || !username || !password }
          >
            { loading ? '...' : 'Entrar' }
          </Button>
        </fieldset>
      </form>
    </section>
  )
}

export default Login
