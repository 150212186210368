import Button from 'ui/elements/Button/Button'
import {
  PATH as newInspectionPath
} from 'ui/pages/NewInspection/NewInspection'

const NewInspectionButton = ({ className, children }) => (
  <Button
    variant="secondary"
    className={ `button-clean --success ${className}` }
    to={ `/${newInspectionPath}` }
  >{ children }</Button>
)

export default NewInspectionButton
