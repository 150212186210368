import PropTypes from 'prop-types'
import { memo, useEffect } from 'react'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import useCategorizeDetection from 'hooks/models/detection/useCategorizeDetection'

const Dropdowns = memo(
  ({
    inputPrecharged,
    onChange,
    cleanTrigger,
    whenShowFirstDropdown = true,
    isAnomaly = true,
  }) => {
    const { category, anomaly, severity } = useCategorizeDetection({
      cleanTrigger,
    })

    useEffect(() => {
      const itemsSelected = {
        category: category.selected,
        anomaly: anomaly?.selected,
        severity: severity.selected,
        severities: severity.collection,
      }
      onChange && onChange(itemsSelected)
    }, [
      category.selected,
      anomaly?.selected,
      severity.selected,
      severity.collection,
      onChange,
    ])

    return (
      <>
        {whenShowFirstDropdown && (
          <>
            <Dropdown
              label="Conjunto"
              itemSelected={inputPrecharged?.category || category.selected}
              items={
                inputPrecharged?.category?.collection || category.collection
              }
              onSelect={category.select}
              selectable={
                !inputPrecharged?.category ||
                inputPrecharged?.category?.collection ||
                false
              }
            />
            {isAnomaly && (
              <Dropdown
                label="Operación"
                itemSelected={inputPrecharged?.anomaly || anomaly.selected}
                items={anomaly.collection}
                onSelect={anomaly.select}
                isLoading={anomaly.loading}
                selectable={!inputPrecharged?.anomaly}
              />
            )}
            {isAnomaly && (
              <Dropdown
                label="Valor de PM / grado de repercusión"
                itemSelected={inputPrecharged?.severity || severity.selected}
                items={severity.collection}
                onSelect={severity.select}
                isLoading={severity.loading}
                selectable={!inputPrecharged?.severity}
              />
            )}
          </>
        )}
      </>
    )
  }
)

Dropdowns.propTypes = {
  inputPrecharged: PropTypes.object,
  onChange: PropTypes.func,
  cleanTrigger: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

export default Dropdowns
