import React from 'react'
import './Flag.scss'

const mod = 'flag'

function Flag({ className, children, design }) {
  return <b className={`${mod} ${className} --${design || "old"}`}>{children}</b>
}

export default Flag
