import { useState, useEffect } from 'react'
import useCountdown from 'hooks/time/useCountdown'

const TIMEOUT = 10

const useCreatingInspectionModal = () => {
  const [progress, setProgress] = useState(0)
  const [isCreatingInspection, setIsCreatingInspection] = useState(false)

  const { start, clear  } = useCountdown({
    timeout: TIMEOUT,
    onTick: () => { setProgress(progress + 10) },
    onEnd: () => { setProgress(100) }
  })

  useEffect(() => {
    if (!isCreatingInspection) { clear() }
    start()
    return () => { clear() }
  }, [isCreatingInspection, start, clear])

  return {
    isCreatingInspection,
    showCreateInspectionModal: setIsCreatingInspection,
    progress,
  }
}

export default useCreatingInspectionModal
