import { useQuery } from '@apollo/client'
import SEVERITIES_QUERY, { field } from 'infra/severities/severities.query'

export default function useGetSeverities({ anomalyId }) {
  const { data, loading, error } = useQuery(SEVERITIES_QUERY, {
    variables: {
      anomalyId,
    },
  })

  return {
    data: data?.[field],
    loading,
    error,
  }
}
