import PropTypes from 'prop-types'
import { useState, useCallback, useEffect } from 'react'
import * as Toaster from 'ui/elements/Toast/Toast'
import SimpleModal from 'ui/components/SimpleModal/SimpleModal'
import useConfirmButton from 'hooks/dom/useConfirmButton'
import { ACTIONS } from '../../../vendor/Uppy/UppyAPI'
import { showUploadCancelled } from '../../../Messages/Messages'
import StatusBar from '../Progress/StatusBar'
import Progress from '../Progress/Progress'
import './UploadingFilesModal.scss'

const RESUME_LABEL = 'Retomar'
const PAUSE_LABEL = 'Pausar'
const CANCEL_LABEL = 'Cancelar'
const PREFIX_LABEL = 'La subida se ha '

const UploadingFilesModal = ({
  uploader,
  isUploading: isOpen,
  isOnline,
  files,
  progress,
  onCancel
}) => {
  const [isPaused, setIsPaused] = useState(false)

  const resume = useCallback(() => {
    uploader[ACTIONS.RESUME]()
    setIsPaused(false)
  }, [uploader])

  const pause = useCallback(() => {
    uploader[ACTIONS.PAUSE]()
    Toaster.showError(PREFIX_LABEL + 'pausado.')
    setIsPaused(true)
  }, [uploader])

  const cancel = useCallback(() => {
    uploader[ACTIONS.CANCEL]()
    onCancel?.(files)
    showUploadCancelled()
  }, [uploader, files, onCancel])

  const {
    handleConfirm: handlePauseConfirm,
    confirmText: pauseText
  }  = useConfirmButton({
    label: PAUSE_LABEL,
    callbackToAction: () => {
      !isPaused && pause()
    },
  })

  const {
    handleConfirm: handleCancelConfirm,
    confirmText: cancelText,
    clear: clearCancel
  } = useConfirmButton({
    label: CANCEL_LABEL,
    callbackToAction: cancel,
    secondsToConfirm: 10
  })

  useEffect(() => {
    const action = isOnline ? ACTIONS.RESUME : ACTIONS.PAUSE
    uploader[action]()
  }, [uploader, isOnline])

  useEffect(() => {
    if (isOpen) return
    clearCancel()
  }, [isOpen, clearCancel])

  return (
    <SimpleModal
      title="Subiendo"
      isOpen={ isOpen }
      className="upload-modal"
      actions={
        <section className="upload-modal__actions">
          <button
            variant="secondary"
            className="button-clean --failure"
            onClick={ handleCancelConfirm }
          >{ cancelText }</button>
          <button
            variant="secondary"
            className="button-clean --regular"
            disabled={ !isOnline }
            onClick={ (!isPaused && handlePauseConfirm) || resume }
          >{ isPaused || !isOnline ? RESUME_LABEL: pauseText } subida</button>
        </section>
      }
    >
      <p>Se paciente hasta que se realice la subida.</p>
      <Progress value={ progress } />
      <StatusBar uploader={ uploader } />
    </SimpleModal>
  )
}

UploadingFilesModal.propTypes = {
  uploader: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  isOnline: PropTypes.bool,
  files: PropTypes.array,
  filesUploaded: PropTypes.number,
  progress: PropTypes.number,
  totalSize: PropTypes.number,
  totalUploadedSize: PropTypes.number,
  onCancel: PropTypes.func
}

export default UploadingFilesModal
