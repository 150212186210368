import PropTypes from 'prop-types'
import { formatBytes, SKELETON_TEXT } from 'lib'
import Thumbnail from 'ui/components/Thumbnail/Thumbnail'
import { isTextFile, isVideoFile, TYPE_VIDEO } from 'domain/files/types/types'
import FileRowTooltip from './Tooltip/FileRowTooltip'

const getStatus = (file) => (
  isTextFile(file) ?
    SKELETON_TEXT :
    <FileRowTooltip
      status={ file?.status }
      errors={ file?.errors || [] }
    />
)

const FileRow = ({ file, handleOpenFile }) => {
  const name = file?.name || SKELETON_TEXT
  const status = getStatus(file)
  const size = formatBytes(file?.size) || SKELETON_TEXT
  const isVideo = isVideoFile(file, { field: TYPE_VIDEO.alternative })

  return (
    <tr>
      <td className="cell-large">{ name }</td>
      <td className="cell-partial">{ status }
      </td>
      <td className="cell-partial">{ size }</td>
      <td className="cell-partial">
        { !isTextFile(file) && (
          <Thumbnail
            urlKey={ file?.thumbnailKey }
            isSquare
            isSmall
            withHover
            withFallback
            isVideo={ isVideo }
            onClick={ () => { handleOpenFile(file) }}
          />
        )}
      </td>
    </tr>
  )
}

FileRow.propTypes = {
  file: PropTypes.object,
  handleOpenFile: PropTypes.func,
}

export default FileRow
