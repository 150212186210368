import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import useCopyStyle from 'hooks/dom/useCopyStyle'
import {
  MODE,
  STYLES_TO_BE_COPIED,
  append
} from './DrawLayer.constants'
import './DrawLayer.scss'

export { MODE }

const DrawLayer = ({
  selector,
  index = 0,
  trigger,
  toyImage,
  onToyRendered,
  mode,
  isLoading,
  isVisible = true,
  children
}) => {
  const ref = useRef(null)
  const { styleCopied: childrenStyle } = useCopyStyle({
    selector,
    index,
    trigger,
    properties: STYLES_TO_BE_COPIED
  })

  useEffect(() => {
    async function appendToyImage () {
      if (!toyImage || !ref?.current) return
      if (mode) {
        ref.current.innerHTML = ''
      }
      await append(ref.current, toyImage, mode)
      onToyRendered?.()
    }
    appendToyImage()
  }, [toyImage, isLoading, trigger, mode, onToyRendered])

  useEffect(() => {
    if (isVisible || !ref?.current) return
    ref.current.innerHTML = ''
  }, [isVisible])

  if (!selector) return null

  return (
    <section
      ref={ ref }
      data-testid="draw-layer"
      className="draw-layer"
      style={ childrenStyle }
    >{ children }</section>
  )
}

DrawLayer.propTypes = {
  selector: PropTypes.string,
  index: PropTypes.number,
  toyImage: PropTypes.object,
  onToyRendered: PropTypes.func,
  isLoading: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(MODE))
}

export default DrawLayer
