import { TYPES, VALIDATION_STATUS } from 'domain/detection'
import FORM_TYPES from '..'

const CALLBACK_TO_FIND = (detectionItem, detectionToCompare) =>
  detectionItem.rois[0].id === detectionToCompare.id

const isDetectionWithRois = ({ rois }) => rois?.length > 0

const decideTypeForm = ({ detection: detectionTarget, isAssignedUser }) => {
  if (!isAssignedUser) {
    return detectionTarget?.type === TYPES.ANOMALY
      ? FORM_TYPES.VIEW_DETECTION
      : FORM_TYPES.VIEW_DETECTION
  }
  const isRoiValidated =
    detectionTarget?.validation?.status === VALIDATION_STATUS.CORRECT

  if (detectionTarget?.type === TYPES.CATEGORY) {
    return isRoiValidated
      ? FORM_TYPES.VIEW_DETECTION
      : FORM_TYPES.VALIDATE_DETECTION_CATEGORY
  }
  return isRoiValidated
    ? FORM_TYPES.VIEW_DETECTION
    : FORM_TYPES.VALIDATE_DETECTION
}

const findDetection = (currentItem, collectionToSearch, callbackToFind) =>
  collectionToSearch.find(
    (item) => isDetectionWithRois(item) && callbackToFind(item, currentItem)
  )

function getDetectionForm({
  isAssignedUser,
  currentDetection,
  detectionsToSearch,
  callbackToFind = CALLBACK_TO_FIND,
}) {
  if (!currentDetection || detectionsToSearch?.length === 0) return

  const detection = findDetection(
    currentDetection,
    detectionsToSearch,
    callbackToFind
  )

  if (!detection) {
    console.error('No detection found for this ROI.')
    return null
  }

  const detectionForm = {
    detection,
    typeForm: decideTypeForm({ detection, isAssignedUser }),
  }
  return detectionForm
}

const getDetectionFormByField = (input, field = 'url') => {
  const detectionForm = getDetectionForm(input)
  if (!detectionForm) return null
  return {
    ...detectionForm,
    [field]: detectionForm.detection.crop?.[field] || '',
  }
}

export default getDetectionFormByField
