import PropTypes from 'prop-types'
import Table from 'ui/elements/Table/Table'
import { openModal } from 'ui/elements/Modal/Modal'
import Thumbnail from 'ui/components/Thumbnail/Thumbnail'
import Review from '../Review/Review'
import launchValidationsTooltip from './ValidationsTooltip/ValidationsTooltip'
import Semaphore, { orderUsersByValidationStatus } from '../Semaphore/Semaphore'
import "./Validations.scss"

const headers = [
  'ID',
  'Conjunto',
  'Tipo de Fallo',
  'Validación Conjunto',
  'Validación Tipo de fallo',
  'Imagen'
]

const Validations = ({ detections, className, onReview }) => (
  <Table className={`validations ${className}`} headers={ headers }>
    { detections.map((detection, index) => {
        if (!detection?.frames[0]) return null

        const { id, anomaly, type, frames, review } = detection

        const {
          identifier,
          categories,
          anomalyTypes
        } = orderUsersByValidationStatus(index, detection)

        return (
          <tr key={ identifier } id={ identifier }>
            <td className="validations__id">{ id }</td>

            <td className="validations__category">
              <dl>
                <dt>{ anomaly?.category.name }</dt>
                <dd>{ anomaly?.category.externalReference }</dd>
              </dl>
            </td>
            <td className="validations__type">
              <dl>
                <dt>{ anomaly?.type.name }</dt>
                <dd>{ anomaly?.type.externalReference }</dd>
              </dl>
            </td>

            <td className="validations__status">
              <Semaphore
                selected={ review?.categoryStatus }
                collection={ categories }
                className="validations__flag"
                onLaunchTooltip={ launchValidationsTooltip }
              />
            </td>

            <td className="validations__status">
              <Semaphore
                selected={ review?.anomalyTypeStatus }
                collection={ anomalyTypes }
                className="validations__flag"
                onLaunchTooltip={ launchValidationsTooltip }
              />
            </td>

            <td className="validations__detail">
              <Thumbnail
                type={ type }
                urlKey={ frames[0].thumbnailKey }
                onClick={ () => openModal(`review-${index + 1}`) }
                withHover
              />

              <Review
                modalID="validations"
                index={ index }
                item={ detection }
                detections={ detections }
                categories={ categories }
                anomalyTypes={ anomalyTypes }
                onLaunchTooltip={ launchValidationsTooltip }
                onReview={ onReview }
              />
            </td>
          </tr>
        )
      }
    )}
  </Table>
)

Validations.propTypes = {
  detections: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
  onReview: PropTypes.func
}

export default Validations
