import PropTypes from 'prop-types'
import Modal, { closeModal, openModal } from 'ui/elements/Modal/Modal'
import Uploader from '../Uploader/Uploader'

const MODAL_ID = 'modalUploader'

export const launch = () => {
  openModal(MODAL_ID)
}

const labelByDefault = "Nueva Inspección"

const NewInspectionButton = ({
  wrapperSelector,
  label = labelByDefault,
  variant = 'primary',
  onDone,
  className = '',
}) => (
  <Modal
    className="modal--uploader"
    label={ label }
    buttonContainer={ wrapperSelector }
    id={ MODAL_ID }
    variant={ variant }
    buttonClassName={ className }
  >
    <Uploader
      onCancel={ () => closeModal(MODAL_ID) }
      onDone={ onDone }
    />
  </Modal>
)

NewInspectionButton.propTypes = {
  wrapperSelector: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  onDone: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default NewInspectionButton
