import { useQuery } from '@apollo/client'
import {
  query,
  field
} from 'infra/detection/detectionsWithValidations.query'

export const CATEGORY_ALL = 'all'

function useDetectionsWithValidations ({ categoryId }) {
  const categoryExternalReference = (categoryId === CATEGORY_ALL ? "" : categoryId)

  const {
    data,
    loading,
    error,
    fetchMore,
    refetch
  } = useQuery(
    query, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        categoryExternalReference
      }
    }
  )

  return {
    data: data?.[field],
    loading,
    error,
    fetchMore,
    refetch
  }
}

export default useDetectionsWithValidations
