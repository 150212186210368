import { StatusBar } from '@uppy/react'
import locale from './StatusBar.locale'

const StatusBarConfig = {
  showProgressDetails: true,
  hideUploadButton: true,
  hideRetryButton: true,
  hidePauseResumeButton: true,
  hideCancelButton: true
}

const StatusBarVendor = ({ uploader }) => {
  return (
    <StatusBar
      uppy={ uploader }
      { ...StatusBarConfig }
      locale={ locale }
    />
  )
}

export default StatusBarVendor
