import { gql } from '@apollo/client'

export const FILES = gql`
  fragment FilesFragment on Inspection {
    files {
      id
      name
      size
      status
      url
      contentType
      errors {
        code
        value
        associatedItems
      }
    }
  }
`
