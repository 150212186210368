import PropTypes from 'prop-types'
import { useRef, useReducer, useCallback, useEffect } from 'react'

import applyRulesToCreateAInspectionByUploadType from 'domain/inspection/rules'
import UploadingFilesModal from 'ui/pages/NewInspection/elements/Modal/UploadingFilesModal/UploadingFilesModal'
import CheckGeolocationFile from 'ui/pages/NewInspection/CheckGeolocationFile/CheckGeolocationFile'
import useCheckGeolocationFiles from 'hooks/models/inspection/useCheckGeolocationFiles'
import { ACTIONS } from 'ui/pages/NewInspection/vendor/Uppy/UppyAPI'
import NewInspectionFiles from 'ui/pages/NewInspection/Files/NewInspectionFiles'
import * as Messages from 'ui/pages/NewInspection/Messages/Messages'
import useFilesToBeUpload from '../hooks/useFilesToBeUpload/useFilesToBeUpload'
import { initialState, reducer, UPLOAD_STATUS } from './orchestrator.reducer'
import Uploader from '../Uploader'
import { mergeIdsWithGeolocation } from '../Parser/parseFiles'

const Orchestrator = ({ withoutTextFile, uploader, uploadConfig, user, title, onComplete, onCancel }) => {
  const { current: uploaderRef } = useRef(uploader)
  const [{ fileIds, uploadStatus, ...uploadState }, dispatch] = useReducer(reducer, initialState)
  const isUploading = uploadStatus === UPLOAD_STATUS.UPLOADING
  const isUploadSuccessful = uploadStatus === UPLOAD_STATUS.SUCCESS

  const { files, actions: { removeFile, addFile, cancelUpload } } = useFilesToBeUpload({
    withoutTextFile,
    uploaderRef,
    isUploading,
    onRemoveFile: Messages.showRemovedFileMessage,
    onCancel: () => {
      dispatch({ type: 'CLEAR' })
    },
  })

  const { haveCheckErrors, ...checkGeolocation } = useCheckGeolocationFiles({
    isLocal: withoutTextFile,
    fileIds,
    files: files.toBeUpload,
    isAllFilesUploaded: isUploadSuccessful
  })
  const isPossibleCreateInspectionByUploadType = applyRulesToCreateAInspectionByUploadType[uploadConfig.id]
  const isPossibleCreateInspection = isPossibleCreateInspectionByUploadType(title, files.toBeUpload)
  const isUploadAndCheckLoaded = checkGeolocation.loaded && isUploadSuccessful && fileIds.length > 0
  const isUploadButtonEnabled = (!isUploading && uploadState.isOnline && user) && isPossibleCreateInspection

  const propsDrill = {
    user,
    title,
    files: files.toBeUpload,
    uploader: uploaderRef,
    uploadConfig,
    isUploading,
  }

  // (*A)
  const startTheUpload = async () => {
    dispatch({ type: 'START_THE_UPLOAD' })
    const response = await uploaderRef[ACTIONS.UPLOAD]()
    if (response?.successful) {
      dispatch({ type: 'UPLOAD_SUCCESS', payload: response?.successful })
    }
    if (response?.failed?.length === 0) return
    Messages.showErrors(response?.failed)
    dispatch({ type: 'UPLOAD_ERROR' })
  }

  // (*A3)
  const handleUploadFinished = useCallback(() => {
    dispatch({ type: 'UPLOAD_IDDLE' })
    const filesUploaded = mergeIdsWithGeolocation(fileIds, files.toBeUpload)
    onComplete(filesUploaded)
  }, [onComplete, fileIds, files.toBeUpload])

  const handleShowCheckGeolocation = useCallback(() => {
    const showCheckGeolocation = uploadConfig.withCheckGeolocation && haveCheckErrors
    showCheckGeolocation ?  dispatch({ type: 'SHOW_CHECK_GEOLOCATION' }): handleUploadFinished()
  }, [haveCheckErrors, uploadConfig.withCheckGeolocation, handleUploadFinished])

  useEffect(() => {
    if (!isUploadAndCheckLoaded) return
    handleShowCheckGeolocation()
    return () => { dispatch({ type: 'CLEAR' }) }
  }, [isUploadAndCheckLoaded, handleShowCheckGeolocation])

  return (<>
    { uploaderRef && (<>
      <Uploader
        { ...propsDrill }
        setFilesToBeUploaded={ addFile }
        onProgress={ (payload) => { dispatch({ type: 'UPDATE_PROGRESS', payload }) }}
        onUploadCompleted={ (payload) => { dispatch({ type: 'UPDATE_FILE_IDS', payload }) }}
        onOnline={ () => { dispatch({ type: 'IS_ONLINE' }) }}
        onOffline={ () => {
          dispatch({ type: 'IS_OFFLINE' })
          Messages.showOnlineNotification({ isOnline: false })
        }}
      />

      { isUploading && (
        <UploadingFilesModal
          { ...propsDrill }
          { ...uploadState }
          onCancel={ (payload) => {
            dispatch({ type: 'UPLOAD_IDDLE' })
            cancelUpload(payload)
          }}
        />
      )}
    </>)}

    { uploadState.showCheckGeolocation && (
      <CheckGeolocationFile
        { ...checkGeolocation }
        files={ files.toBeUpload }
        isOpen={ true }
        onContinue={ handleUploadFinished }
        onCancel={ (payload) => {
          dispatch({ type: 'UPLOAD_IDDLE' })
          cancelUpload(payload, { withReset: false })
        }}
      />
    )}

    <NewInspectionFiles
      { ...propsDrill }
      isUploadButtonEnabled={ isUploadButtonEnabled }
      onUpload={ startTheUpload }
      areSomeFilesAdded={ files.areSomeAdded }
      onRemoveFile={ removeFile }
      onCancel={ onCancel }
    />
  </>)
}

Orchestrator.propTypes = {
  uploader: PropTypes.object.isRequired,
  uploadConfig: PropTypes.object.isRequired,
  user: PropTypes.object,
  title: PropTypes.string,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func
}

export default Orchestrator
// (*A): The order of handlers in the upload process is the next:
//    A1) startTheUpload: starts the upload process.
//    A2) UPDATE_FILE_IDS (dispatch): the files are uploaded.
//    A3) The checking of file errors can be shown
//    A4) handleUploadFinished: the files uploaded are exported to out of this component
//                          (potentially used later in mutation to create the inspection).
