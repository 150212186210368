import { useState } from 'react'
import useEventListener from "./useEventListener"

const NAVIGATION_KEYS = {
  PREVIOUS: '65',
  NEXT: '68'
}

const KEY_EVENTS = {
  DOWN: 'keydown',
  UP: 'keyup',
}

const useNavigationByKeyboardHook = ({
  isEnabled,
  next = () => {},
  previous = () => {},
}) => {
  const [isPressed, setIsPressed] = useState(false)

  useEventListener(KEY_EVENTS.DOWN, ({ keyCode }) => {
    if (!isEnabled) return
    const keycode = keyCode.toString()
    if (keycode === NAVIGATION_KEYS.PREVIOUS) {
      setIsPressed(true)
      previous()
    } else if (keycode === NAVIGATION_KEYS.NEXT) {
      setIsPressed(true)
      next()
    }
  })

  useEventListener(KEY_EVENTS.UP, ({ keyCode }) => {
    if (!isEnabled) return
    const keycode = keyCode.toString()
    if (keycode !== NAVIGATION_KEYS.PREVIOUS && keycode !== NAVIGATION_KEYS.NEXT) return
    setIsPressed(false)
  })

  return { isPressed }
}

export default function useNavigationByKeyboard ({
  isEnabled = true,
  counter,
  setter = () => {},
  limit = 0,
  step = 1,
  initial = 0,
}) {
  const { isPressed } = useNavigationByKeyboardHook({
    isEnabled,
    next: () => {
      if (counter < limit) {
        setter((oldValue) => oldValue + step)
      } else {
        setter(initial)
      }
    },
    previous: () => {
      if (counter > initial) {
        setter((oldValue) => oldValue - step)
      } else {
        setter(limit)
      }
    }
  })

  return { isPressed }
}
