import ContextProviders from 'adapter/context/ContextProviders'
import Router from 'adapter/router'

const App = () => (
  <ContextProviders>
    <Router />
  </ContextProviders>
)

export default App
