import {
  getNumberOfTextFiles,
  getTextFile,
  getImagesWithoutTextFile,
} from 'domain/files'
import { areAllImages as checkAreAllImages } from 'domain/files/types/types'

const canBeCreateAInspectionOfImagesType = (title, files) => {
  const numberOfTextFiles = getNumberOfTextFiles(files)
  const textFileIsNotEmpty = getTextFile(files)?.size > 0
  const hasMoreThanOneFile = files.length > 1
  const areAllImages = checkAreAllImages(getImagesWithoutTextFile(files))

  return (
    title?.length > 0 &&
    hasMoreThanOneFile &&
    textFileIsNotEmpty &&
    numberOfTextFiles === 1 &&
    areAllImages
  )
}

export default canBeCreateAInspectionOfImagesType
