import PropTypes from 'prop-types'
import { useState, useReducer, useContext, useEffect } from 'react'

import SplitPanelContext, { PANEL_STATUS } from 'adapter/context/SplitPanelContext'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import ImagesContext from 'adapter/context/ImagesContext'

import { get as getCoordinate } from 'domain/coordinate'
import useGetAssets from 'hooks/models/assets/useGetAssets'
import useGetCategoriesWithHierarchy from 'hooks/models/categories/useGetCategoriesWithHierarchy'
import useCreateDetection, { CreateDetectionInput } from 'hooks/models/detection/useCreateDetection'
import isStillAssignedUser from 'infra/users/isStillAssignedUser'

import Spinner from 'ui/elements/Spinner/Spinner'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import Actions from '../../Actions/Actions'
import Dropdowns from '../../Dropdowns/Dropdowns'
import * as Toaster from 'ui/elements/Toast/Toast'
import MESSAGES from '../../Messages.js'
import { Header, ErrorMessage } from '..'
import { initialState, reducer } from './CreateDetection.reducer'

const CreateDetectionForm = ({
  inspectionId,
  refetchInspection,
  isAssignedUser,
  onClose,
  onSuccess,
  withCache = false,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isClickedActions, setIsClickedActions] = useState(false)
  const { image } = useContext(ImagesContext)
  const { setCropStatus, coordinates: roi } = useContext(CropContext)
  const { setActivePanel } = useContext(SplitPanelContext)

  const [{
    parentCategory,
    asset,
    categories,
    inputPrecharged
  }, dispatch] = useReducer(reducer, initialState)
  const [inputByUser, setInputByUser] = useState({})

  const {
    data: parentCategories,
    loading: loadingCategories,
    error: errorParentCategories,
    refetch
  } = useGetCategoriesWithHierarchy()

  const { data: assets, loading: loadingAssets } = useGetAssets({
    inspectionId,
    categoryId: parentCategory?.id,
    imageId: image?.id
  })
  const { category, anomaly, severity, severities } = inputByUser

  useEffect(() => {
    if (!parentCategory) return
    dispatch({ type: 'UPDATE_CATEGORIES' })
    dispatch({ type: 'RESET_ASSET' })
  }, [parentCategories, parentCategory, dispatch])

  useEffect(() => {
    dispatch({ type: 'UPDATE_PRECHARGED_INPUT' })
  }, [categories, dispatch])

  const { launch: create, loading: isCreatingDetection } = useCreateDetection({
    withCache,
    imageId: image.id,
    onError: () => { Toaster.showError(MESSAGES.ERROR) },
    onCompleted: (response) => {
      Toaster.showSuccess(MESSAGES.SUCCESS)
      setCropStatus(CROP_STATUS.LOADED)
      setActivePanel(PANEL_STATUS.SPLIT_VIEW)
      onClose()
      !withCache && (onSuccess && onSuccess(response))
    }
  })

  const createDetectionInput = () =>
    CreateDetectionInput.create({
      imageId: image.id,
      roi: [getCoordinate(roi)],
      anomalyId: anomaly.id,
      ...severity?.id && { severityId: severity.id },
      ...asset?.id && { assetId: asset.id }
    })

  const handleSend = () => {
    if (isCreatingDetection) return
    const detection = createDetectionInput()
    create(detection)
  }

  const handleCancel = () => {
    onClose()
    Toaster.showCancel(MESSAGES.CANCEL)
    setCropStatus(CROP_STATUS.READY_TO_DRAW)
  }

  const handleSuccessfulCreatedDetection = async () => {
    setIsLoading(true)
    await isStillAssignedUser({
      trigger: refetchInspection,
      onSuccess: () => { handleSend() },
      onFailure: () => {
        setIsClickedActions(false)
        setIsLoading(false)
      }
    })
  }

  const isSeveritySelected = Boolean(severities?.length > 0 && severity) || severities?.length === 0
  const hasAssets = Boolean(assets?.length > 0 && asset) || assets?.length === 0
  const isAllSelected = Boolean(anomaly && hasAssets && category && isSeveritySelected)

  if (errorParentCategories) return (
    <ErrorMessage
      message="Ha ocurrido un error recuperando las categorías."
      refetch={ refetch }
    />
  )

  return (
    <form className="form-detection">
      <Header />

      { isLoading && <Spinner className="anomaly-form__loading" dark /> }

      { !isLoading && (<>
        <Dropdown
          label="Tipo de objeto"
          itemSelected={ parentCategory }
          items={ parentCategories }
          onSelect={ (payload) => { dispatch({ type: 'SET_PARENT_CATEGORY', payload })} }
          isLoading={ loadingCategories }
        />
        <Dropdown
          label="Matrícula"
          itemSelected={ asset }
          items={ assets }
          onSelect={ (payload) => { dispatch({ type: 'SET_ASSET', payload })} }
          isLoading={ loadingAssets }
          uppercase
        />
        <Dropdowns
          inputPrecharged={ inputPrecharged }
          onChange={ setInputByUser }
          cleanTrigger={ parentCategory }
          whenShowFirstDropdown={ hasAssets }
        />
      </>
    )}
    { !isLoading && (
      <Actions
        isEnabled={ isAllSelected && isAssignedUser }
        isClicked={ isClickedActions }
        setIsClicked={ setIsClickedActions }
        onSend={ handleSuccessfulCreatedDetection }
        onCancel={ () => { handleCancel() }}
      />
    )}
    </form>
  )
}

CreateDetectionForm.propTypes = {
  inspectionId: PropTypes.string.isRequired,
  refetchInspection: PropTypes.func,
  isAssignedUser: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  withCache: PropTypes.bool
}
export default CreateDetectionForm
