import PropTypes from 'prop-types'

import FORM_TYPES, { renderForm } from './types'
import './AnomalyForm.scss'

const AnomalyForm = ({
  from,
  inspectionId,
  type = FORM_TYPES.VIEW_DETECTION,
  detection,
  refetchInspection,
  isAssignedUser,
  onSuccess,
  onClose
}) => {
  if (!type) return null
  const { component: FormComponent, withCache } = renderForm(type)
  if (!FormComponent) return null

  return (
    <aside className="anomaly-form">
      <FormComponent
        from={ from }
        inspectionId={ inspectionId }
        detection={ detection }
        refetchInspection={ refetchInspection }
        isAssignedUser={ isAssignedUser }
        onSuccess={ onSuccess }
        onClose={ onClose }
        withCache={ withCache }
      />
    </aside>
  )
}

AnomalyForm.propTypes = {
  from: PropTypes.string,
  inspectionId: PropTypes.string,
  type: PropTypes.object,
  detection: PropTypes.object,
  refetchInspection: PropTypes.func,
  isAssignedUser: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default AnomalyForm
