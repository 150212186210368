import { useQuery } from '@apollo/client'
import {
  INSPECTION_QUERY,
  INSPECTION_QUERY_LIGHT,
  INSPECTION_QUERY_WITH_FILES,
  field
} from 'infra/inspection/inspection.query'

const useInspectionDetail = ({
  inspectionId,
  isLight = false,
  isWithFiles = false,
}) => {
  let query = INSPECTION_QUERY
  if (isLight) { query = INSPECTION_QUERY_LIGHT }
  if (isWithFiles) { query = INSPECTION_QUERY_WITH_FILES }

  const { data, loading, error, refetch } = useQuery(query, {
    variables: {
      id: inspectionId
    },
  })

  const inspection = data?.[field] || null

  return {
    data: inspection,
    loading,
    error,
    refetch
  }
}

export default useInspectionDetail
