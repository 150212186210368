const ERRORS = Object.freeze({
  ALL_FILES_WITHOUT_GEOLOCATION: {
    code: 'allFilesWithoutGeolocation',
    message: 'El archivo no dispone de ninguna imágen geolocalizada.',
    isContinueable: false,
  },
  NO_GEOLOCATION_IN_SOME_FILE: {
    code: 'filesWithoutGeolocation',
    message: (index) => `La "imagen ${index}" no presenta geolocalización.`,
    isContinueable: true,
  },
  BAD_SCHEMA_IN_SOME_LINE: {
    code: 'geolocationFileBadSchema',
    message: (index) => `La "línea ${index}" tiene formato erróneo.`,
    isContinueable: true,
  },
  ALL_FILES_WITH_BAD_SCHEMA: {
    code: 'allGeolocationFileBadSchema',
    message: 'El archivo no tiene el formato correcto.',
    isContinueable: false,
  },
  NO_GEOLOCATION_FILE: {
    code: 'noGeolocationFile',
    message: 'No se ha encontrado ningún archivo con geolocalización.',
    isContinueable: false,
  }
})

const getValues = (errors = ERRORS) => {
  if (Array.isArray(errors)) {
    return errors
  }
  return Object.values(errors)
}

const getCodes = (errors = ERRORS) => {
  const values = getValues(errors)
  const onlyWithCodes = values.map(({ code }) => code)
  return onlyWithCodes
}

function getErrorByCode(errorCode) {
  const errorTyped = getValues().find(({ code }) => code === errorCode)
  if (!errorTyped) console.error(`ErrorCode ${errorCode} not found`)
  return errorTyped
}

function isContinueable (errors) {
  if (errors?.length === 0) return true
  const codesPossibles = getCodes(errors)
  const errorsTyped = getValues().filter(({ code }) => codesPossibles.includes(code))
  if (!errorsTyped) return false
  const areAllContinueable = errorsTyped.every(({ isContinueable }) => isContinueable)
  return areAllContinueable === true
}

const calculateNumberOfErrors = (errors) => {
  let errorCount = 0
  errors.forEach((error) => {
    if (error?.associatedItems) {
      errorCount += error.associatedItems.length
    } else {
      errorCount += 1
    }
  })
  return errorCount
}

export { ERRORS, getErrorByCode, isContinueable, calculateNumberOfErrors }
