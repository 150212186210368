import { useCallback, useState, useEffect } from 'react'

const INITIAL_ZOOM = 0
const INITIAL_OFFSET = { x: 0, y: 0 }

const useSyncZoomAndPosition = ({ getElement, scale }) => {
  const [zoom, setZoom] = useState(INITIAL_ZOOM)
  const [offset, setOffset] = useState(INITIAL_OFFSET)

  // Functions to sync the zoom and movement of two croppers:
  const updateZoom = useCallback(() => {
    const originalCropper = getElement({ isProgressive: false })
    if (!originalCropper.ready) return
    const zoomScaled = zoom / scale
    if (zoomScaled === 0) return
    originalCropper.zoomTo(zoomScaled)
  }, [zoom, getElement, scale])

  const updatePosition = useCallback(() => {
    const originalCropper = getElement({ isProgressive: false })
    if (!originalCropper.ready) return
    originalCropper.moveTo(offset.x, offset.y)
  }, [offset, getElement])

  const syncPosition = useCallback(() => {
    const progressiveCropper = getElement({ isProgressive: true })
    const { left: x, top: y } = progressiveCropper.getCanvasData()
    setOffset({ x, y })
  }, [getElement])

  const syncZoom = ({ detail }) => {
    setZoom(detail.ratio)
  }

  // Triggers to sync croppers, zoom and movement:
  useEffect(() => {
    updateZoom()
    syncPosition()
  }, [zoom, updateZoom, syncPosition])

  useEffect(() => {
    updatePosition()
  }, [offset, updatePosition])

  // API:
  return {
    updateZoom,
    syncPosition,
    syncZoom,
    resetSync: useCallback(() => {
      setZoom(INITIAL_ZOOM)
      // setOffset(INITIAL_OFFSET)
    }, []),
    restore: () => {
      getElement({ isProgressive: false }).reset()
    }
  }
}

export default useSyncZoomAndPosition
