import { SKELETON_TEXT } from 'lib/text'
import Dot, { DOT_TYPES } from 'ui/elements/Dot/Dot'
import { INSPECTION_STATUS } from 'domain/inspection'
import './ProgressWithDot.scss'

const statusMessages = (progress, totalDetections) => {
  return {
    [INSPECTION_STATUS.PROCESSING]: {
      message: 'Analizando inspección',
      dotType: DOT_TYPES.PENDING.id,
      description: `${progress}% completado`
    },
    [INSPECTION_STATUS.ERROR]: {
      message: 'Inspección fallida',
      dotType: DOT_TYPES.INCORRECT.id,
      description: SKELETON_TEXT
    },
    [INSPECTION_STATUS.COMPLETED]: {
      message: 'Inspección completada',
      dotType: DOT_TYPES.CORRECT.id,
      description: totalDetections ? `${totalDetections} defectos pendientes`: null
    },
    [INSPECTION_STATUS.PENDING]: {
      message: 'Inspección pendiente',
      dotType: null,
      description: 'Creando entorno para realizar el análisis.'
    }
  }
}

const ProgressWithDot = ({ progress, status, totalDetections }) => {

  if (!status) return null
  if (!statusMessages(progress, totalDetections)[status]) return null
  const currentStatus = statusMessages(progress)[status]
  return (
    <section className="progress-dot">
      <p className="progress-dot__title">
        { currentStatus.dotType && (<Dot statusId={ currentStatus.dotType } />) }
        { currentStatus.message }
      </p>
      { currentStatus.description && (
        <p className="progress-dot__description">{ currentStatus.description }</p>
      )}
    </section>
  )
}

export default ProgressWithDot
