import { VALIDATION_STATUS as STATUS } from 'domain/detection'
import Semaphore from '../../Semaphore/Semaphore'
import './ReviewQuestion.scss'

const options = [
  {
    label: 'Sí',
    value: STATUS.CORRECT,
    identifier: 'correct'
  },
  {
    label: 'No',
    value: STATUS.INCORRECT,
    identifier: 'incorrect'
  },
  {
    label: 'No lo sé',
    value: STATUS.DOUBT,
    identifier: 'doubt'
  }
]

const Question = ({
  identifier,
  title,
  name,
  collection,
  mod,
  onLaunchTooltip,
  fieldSelected,
  onSetField
}) => {
  return (
    <fieldset className="review-question">
      { title }
      <section className="review-status">
        Respuestas:
        <Semaphore
          collection={ collection }
          onLaunchTooltip={ onLaunchTooltip }
          className="validations__flag"
        />
      </section>
      <section
        className="review-fieldset"
      >
        { options.map(({ label, value, identifier: optionIdentifier }) => {

          const matchInputWithLabelId = `${mod}__${name}__${optionIdentifier}__${identifier}`

          return (
            <p key={`${mod}__${name}${optionIdentifier}`}>
              <input
                type="radio"
                name={`${mod}__${name}__${identifier}`}
                id={ matchInputWithLabelId }
                value={ value }
                checked={ fieldSelected === value }
                onChange={ onSetField }
              />
              <label
                htmlFor={ matchInputWithLabelId }
              >{ label }
              </label>
            </p>
          )
        }) }
      </section>
    </fieldset>
  )
}

export default Question
