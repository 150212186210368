import PropTypes from 'prop-types'
import './Progress.scss'

const Progress = ({ filled, className, id }) => (
  <div className={`progress ${className}`}>
    <progress
      className="progress__bar"
      id={ id }
      value={ filled }
      max="100"
    >
      { filled }%
    </progress>
    <label
      className="progress__label"
      htmlFor={ id }
      style={{ marginLeft: filled + '%' }}
    >
      { filled }%
    </label>
  </div>
)

Progress.propTypes = {
  filled: PropTypes.number,
  className: PropTypes.string,
  id: PropTypes.string
}

export default Progress
