import { useContext } from 'react'
import Headline from 'ui/elements/Headline/Headline'
import Button from 'ui/elements/Button/Button'
import SettingsContext from 'adapter/context/SettingsContext'
import './DetailHeadline.scss'

function DetailHeadline ({
  item: {
    name,
    description,
    metadata
  } }) {
  const settings = useContext(SettingsContext)
  const { inspectionFiles } = settings || {}

  let kmz = inspectionFiles ? metadata?.filter(obj => obj.key === "kmzUrl") : null
  let xls = inspectionFiles ? metadata?.filter(obj => obj.key === "excelUrl") : null
  let csv = inspectionFiles ? metadata?.filter(obj => obj.key === "csvUrl") : null

  return (
    <Headline className='detail-headline'>
      <section>
        <h1 className='detail-headline__title'>{ name }</h1>
        <p className='detail-headline__intro'>{ description }</p>
      </section>
      <section className="detail-headline__extra --buttons">
        { <div>
          { kmz && kmz[0] && (
            <Button
              variant="secondary"
              href={ kmz[0]?.value }
              target="_blank"
              role="download"
              rel="noreferrer"
            >KMZ
            </Button>
          )}
          { xls && xls[0] && (
            <Button
              variant="secondary"
              href={ xls[0]?.value }
              target="_blank"
              role="download"
              rel="noreferrer"
            >XLS
            </Button>
          )}
          { csv && csv[0] && (
            <Button
              variant="secondary"
              href={ csv[0]?.value }
              target="_blank"
              role="download"
              rel="noreferrer"
            >CSV
            </Button>
          )}
        </div> }
      </section>
    </Headline>
  )
}

export default DetailHeadline
