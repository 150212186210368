import PropTypes from 'prop-types'
import { useState, useContext } from 'react'
import SplitPane from 'react-split-pane'
import SplitPanelContext, { PANEL_STATUS } from 'adapter/context/SplitPanelContext'
import './SplitView.scss'
import './Resizer.scss'

const SplitView = ({ withExpandButton = false, children }) => {
  const [isDragging, setIsDragging] = useState(false)
  const {
    activePanel,
    setActivePanel,
    isSplitViewOpened
  } = useContext(SplitPanelContext)

  return (
    <section className={ `split-view${(isDragging ? ' --free-mode': '')}` }>
      { (withExpandButton && isSplitViewOpened) &&
        <span
          className="split-view__arrow"
          onClick={ () => setActivePanel(PANEL_STATUS.VIEWER_OPENED) }
        ></span>
      }
      <SplitPane
        split="vertical"
        primary='second'
        allowResize={ false }
        size={`${activePanel}%`}
        pane1Style={{
          position: 'relative',
          zIndex: 1,
        }}
        onDragStarted={ () => setIsDragging(true) }
        onDragFinished={ () => setIsDragging(false) }
      >{ children }
      </SplitPane>
    </section>
  )
}

SplitView.propTypes = {
  withExpandButton: PropTypes.bool,
  children: PropTypes.node
}

export default SplitView
