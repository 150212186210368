import { gql } from '@apollo/client'
import createInput from 'lib/mutations'

export const field = 'createDetection'

const INPUT = {
  imageId: 'string.isRequired',
  roi: 'array',
  anomalyId: 'string',
  severityId: 'string',
  assetId: 'string',
}

export const input = createInput(INPUT)

export const mutation = gql`
  mutation createDetection($input: CreateDetectionInput!) {
    createDetection(input: $input) {
      id
      rois {
        id
        x1
        y1
        x2
        y2
      }
    }
  }
`
