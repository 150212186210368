const STATUS = Object.freeze({
  PROCESSING: 'PROCESSING',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
  COMPLETED: 'COMPLETED'
})

export {
  STATUS as INSPECTION_STATUS,
}
