import { gql } from '@apollo/client'

export const field = 'assets'

export default gql`
  query assets($inspectionId: ID, $categoryId: ID, $imageId: ID) {
    assets(inspectionId: $inspectionId, categoryId: $categoryId, imageId: $imageId) {
      id
      externalReference
      name
      category {
        name
      }
      position {
        latitude
        longitude
      }
    }
  }
`
