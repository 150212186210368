const cleanURL = (url) => url.split('/').pop()

const field = 'uploadURL'
const vendorId = 'uppyId'

const parseFiles = (successful) => {
  const filteredWithField = successful.filter(file => file?.[field])
  const parsedFiles = filteredWithField.map((file) => ({
    [vendorId]: file?.id,
    id: cleanURL(file[field])
  }))
  return parsedFiles
}

export const mergeIdsWithGeolocation = (fileIds, files) => {
  const merged = files.map(file => {
    const fileId = fileIds.find(fileId => fileId?.[vendorId] === file.id)
    if (!fileId) return null
    const geolocation = file?.geolocation || null
    return {
      id: fileId.id,
      geolocation,
    }
  })
  return merged.filter(file => file)
}

export default parseFiles
