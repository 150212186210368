import PropTypes from 'prop-types'

const Video = ({ src, width, height, className }) => {
  return (
    <video
      width={ width || 320 }
      height={ height || 240 }
      controls
      className={ className }
    >
      <source src={ src } type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
}

Video.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string
}

export default Video
