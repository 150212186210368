import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Wrapper from 'ui/elements/Wrapper/Wrapper'
import Spinner from 'ui/elements/Spinner/Spinner'
import Pagination from 'ui/components/Pagination/Pagination'
import Filter from 'ui/components/Filter/Filter'
import Validations from 'ui/pages/Collection/Validations/Validations'
import useDetectionsWithValidations, {
  CATEGORY_ALL as categoryAll
} from 'hooks/models/detection/useDetectionsWithValidations'
import useReviewDetection from 'hooks/models/detection/useReviewDetection'
import './Collection.scss'

export const CATEGORY_ALL = categoryAll
export const PATH = 'collection'
export const PARAM = 'catId'
export const pageName = 'Revisiones'

const Collection = ({ match }) => {
  const { params: { [PARAM]: categoryId }} = match

  const history = useHistory()

  const {
    data,
    loading,
    error,
    fetchMore,
    refetch
  } = useDetectionsWithValidations({ categoryId })

  const {
    launch: reviewDetection
  } = useReviewDetection({
    onCompleted: (response) => {
      refetch()
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const onFilter = useCallback((event) => {
    const categorySlug = event.target.value
    history.push(`/${PATH}/${categorySlug}`)
  }, [categoryId])

  error && console.error(error)
  const detections = data?.edges?.map((edge) => edge.node)
  const info = data?.pageInfo
  const isEmptyDetections = !detections || detections?.length === 0

  return (
    <>
      <Filter
        className="collection__filter"
        onChange={ onFilter }
        selected={ categoryId }
      />

      <Wrapper className="collection">
        { loading && <Spinner dark /> }

        { (!data && !loading && !error) && <p>No data</p> }

        { error && <p>Error</p>}

        { (isEmptyDetections && !loading ) && <p className="collection__empty">Sin resultados.</p>}

        { (!isEmptyDetections && !loading) && (
          <>
            <Validations
              className="collection__results"
              detections={ detections }
              onReview={ reviewDetection }
            />

            <Pagination
              disabled={ !info.hasNextPage }
              onLoadMore={ () => {
                fetchMore({
                  variables: {
                    cursor: info.endCursor,
                    categoryExternalReference: (categoryId === CATEGORY_ALL ? "" : categoryId)
                  }
                })
              }}
            />
          </>
        )}
      </Wrapper>
    </>
  )
}

export default Collection
