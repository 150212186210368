import { useQuery } from '@apollo/client'
import { CATEGORIES_QUERY, field } from 'infra/categories/categories.query'

export default function useGetCategories () {
  const { data, loading, error, refetch  } = useQuery(CATEGORIES_QUERY)

  return {
    data: data?.[field],
    loading,
    error,
    refetch
  }
}
