import useImageKey from 'hooks/misc/useImageKey/useImageKey'

export const IMAGE_KEY = Object.freeze({
  ORIGINAL: 'key',
  PROGRESSIVE: 'mediumKey',
  COMPRESSED: 'compressedKey',
})

const useImageKeys = ({ images = [], activePointIndex, skip }) => {

  const imageConfig = {
    images,
    index: activePointIndex,
  }

  const { imageKey: progressiveImageKey } = useImageKey({
   ...imageConfig,
    keyField: IMAGE_KEY.PROGRESSIVE,
    skip
  })


  const { image, imageKey: originalImageKey } = useImageKey({
    ...imageConfig,
    keyField: IMAGE_KEY.COMPRESSED,
    keyFallback: IMAGE_KEY.ORIGINAL,
    skip
  })

  return {
    image,
    progressiveImageKey,
    originalImageKey,
  }
}

export default useImageKeys
