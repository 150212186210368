import { useState, useEffect } from 'react'
import { CROPPERS } from '../../DrawCropper/DrawCropper'

const calculateLabel = (isProgressiveLoaded, isOriginalLoaded) => (
  isOriginalLoaded ?
    CROPPERS.ORIGINAL.label :
    (isProgressiveLoaded ? CROPPERS.PROGRESSIVE.label : CROPPERS.LOADING.label)
)

const useDrawLabel = ({ isLoading, isProgressiveLoaded, isOriginalLoaded }) => {
  const [debugLabel, setDebugLabel] = useState(CROPPERS.LOADING.label)

  useEffect(() => {
    const debugLabel = isLoading ?
      CROPPERS.LOADING.label :
      calculateLabel(isProgressiveLoaded, isOriginalLoaded)
    setDebugLabel(debugLabel)
  }, [setDebugLabel, isLoading, isProgressiveLoaded, isOriginalLoaded])

  return debugLabel
}

export default useDrawLabel
