import { useMutation } from '@apollo/client'
import LogRocket from 'logrocket'
import { isProd } from 'lib/env'
import { mutation, field } from 'infra/login/login.mutation'
import useAuthToken from './useAuthToken'

function useLogin ({ onSuccess, onError } = {}) {
  const [,
    setAuthToken,
    removeAuthToken,
    ,
    setAuthUser,
    removeAuthUser
  ] = useAuthToken()
  const [login, { loading, error }] = useMutation(mutation, {
    onCompleted: async (response) => {
      const { user, accessToken } = response[field]
      if (isProd) {
        LogRocket.identify(user.id, { email: user.username })
      }
      setAuthToken(accessToken)
      setAuthUser(user.username)
      // (1)
      onSuccess?.(response)
    },
    onError: (error) => {
      console.error(error)
      onError?.(error)
    },
  })

  return {
    loading,
    error,
    launch: (user, password) => {
      removeAuthToken()
      removeAuthUser()

      return login ({
        variables: {
          username: user,
          password,
        },
      })
    }
  }
}

export default useLogin

// (1): Existed a tricky hack for asure that
// the login is done before the next router rendering.
// But this trick caused double-login in dev and staged env.
// So It is removed. Consisted in the following code:
//      setTimeout(() => { window.location.reload() }, 1000)
