import PropTypes from 'prop-types'
import { getMessagesByUploadType } from '../../Messages/Messages'
import ListOfFilesEmpty from './Empty/ListOfFilesEmpty'
import FilesTable from './FilesTable/FilesTable'

const ListOfFiles = ({
  files = [],
  user,
  title,
  uploadConfig,
  areSomeFilesAdded,
  onRemoveFile
}) => {
  const { empty } = getMessagesByUploadType(uploadConfig)

  return (<>

  { (files.length === 0) && (
    <ListOfFilesEmpty
      title={ empty.title }
      description={ empty.description }
    />
  )}

  { (files.length > 0) && (
    <FilesTable
      files={ files }
      user={ user }
      title={ title }
      areSomeFilesAdded={ areSomeFilesAdded }
      uploadConfig={ uploadConfig }
      onRemoveFile={ onRemoveFile }
    />
  )}
</>
)}

ListOfFiles.propTypes = {
  files: PropTypes.array,
  user: PropTypes.object,
  title: PropTypes.string,
  uploadConfig: PropTypes.object,
  areSomeFilesAdded: PropTypes.bool,
  onRemoveFile: PropTypes.func
}

export default ListOfFiles
