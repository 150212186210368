import Question from '../ReviewQuestion/ReviewQuestion'
import './ReviewForm.scss'

const ReviewForm = ({
  identifier,
  mod,
  categories,
  anomalyTypes,
  categoryState: [categoryStatus, setCategoryStatus],
  anomalyTypeState: [anomalyTypeStatus, setAnomalyTypeStatus],
  observationsState: [observations, setObservations],
  onLaunchTooltip,
  children
}) => (
  <form className="review-form">
    <Question
      title={ <p>¿Es correcto el <b>conjunto</b> detectado?</p> }
      mod={ mod }
      identifier={ identifier }
      name="category"
      collection={ categories }
      fieldSelected={ categoryStatus }
      onLaunchTooltip={ onLaunchTooltip }
      onSetField={ ({ target }) => setCategoryStatus(target.value) }
    />

    <Question
      title={ <p>¿Es correcto el <b>defecto</b> detectado en el conjunto?</p> }
      mod={ mod }
      identifier={ identifier }
      name="type"
      collection={ anomalyTypes }
      fieldSelected={ anomalyTypeStatus }
      onLaunchTooltip={ onLaunchTooltip }
      onSetField={ ({ target }) => setAnomalyTypeStatus(target.value) }
    />

    <p>¿Algún comentario que nos ayude?</p>
    <textarea
      id={`${mod}__form__info__${identifier}`}
      name={`${mod}__form__info__${identifier}`}
      placeholder="Anotaciones"
      value={ observations || '' }
      onChange={ ({ target }) => setObservations(target.value) }
    ></textarea>

    { children }
  </form>
)

export default ReviewForm
