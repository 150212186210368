import { useEffect, useState } from 'react'
import useCountdown from 'hooks/time/useCountdown'

const CONFIRM_PREFIX_BY_DEFAULT = 'Si, '
const CONFIRM_LABEL_BY_DEFAULT = 'Confirmar'

const useConfirmButton = ({
  label = CONFIRM_LABEL_BY_DEFAULT,
  confirmedLabel,
  confirmPrefix = CONFIRM_PREFIX_BY_DEFAULT,
  callbackToAction = () => {},
  secondsToConfirm,
  withCountdown = false,
}) => {
  const [isPreviouslyClicked, setIsPreviouslyClicked] = useState(false)
  const [confirmText, setConfirmText] = useState(label)

  useEffect(() => {
    setConfirmText(label)
  }, [label, setConfirmText])

  const getConfirmText = (suffix) => {
    const suffixParsed = suffix && withCountdown && ` (${suffix})`
    const text = confirmPrefix + label.toLowerCase()
    if (suffixParsed) return text + suffixParsed
    return text
  }

  const { start, clear } = useCountdown({
    timeout: secondsToConfirm,
    onTick: (count) => {
      withCountdown && count && setConfirmText(getConfirmText(count))
    },
    onEnd: () => {
      setIsPreviouslyClicked(false)
      setConfirmText(label)
    }
  })

  useEffect(() => {
    return () => { clear() }
  }, [clear])

  const handleConfirm = async () => {
    if (!isPreviouslyClicked) {
      setIsPreviouslyClicked(true)
      start()
      setConfirmText(getConfirmText(secondsToConfirm))
      return
    }
    setIsPreviouslyClicked(false)
    setConfirmText(confirmedLabel || label)
    callbackToAction()
  }

  return {
    handleConfirm,
    confirmText,
    clear,
  }
}

export default useConfirmButton
