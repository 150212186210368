import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { capitalize, trimUnderscore } from 'lib'
import { checkShape } from 'lib/types'
import useLoadingText from 'hooks/dom/useLoadingText'
import './Dropdown.scss'

const Dropdown = ({
  items = [],
  label = '',
  placeholder = 'Seleccionar',
  isLoading = false,
  tabIndex = 0,
  onSelect,
  itemSelected = null,
  selectable = true,
  uppercase = false
}) => {
  const dropdown = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(itemSelected || null)
  const { loadingText } = useLoadingText({ isLoading })
  const parseTextName = (string) => trimUnderscore(string && (uppercase ? string.toUpperCase() : capitalize(string))) || null

  const handleClickOutside = (event) => {
    if (dropdown && !dropdown.current?.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    setSelected(itemSelected)
  }, [itemSelected])

  const handleSelect = (item) => {
    setSelected(item)
    setIsOpen(false)
    onSelect && onSelect(item)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return (() => document.removeEventListener('mousedown', handleClickOutside))
  }, [])

  if (selectable && (!items || items.length === 0) && !isLoading) return null
  if (isLoading && items.length === 0 && !itemSelected) return null
  if (!selectable && !itemSelected) return null

  return (
    <fieldset
      ref={ dropdown }
      role="listbox"
      name="dropdown"
      className={`dropdown ${ isOpen ? '--is-open': '' } ${ selectable ? '--selectable' : '' }`}
    >
      { label && <label className="dropdown__label">{ label }</label>}
      <p
        data-testid="dropdown-placeholder"
        className="dropdown__trigger"
        tabIndex={ tabIndex }
        onKeyPress={ (event) => event.key === 'Enter' && selectable && setIsOpen(!isOpen) }
        onClick={ () =>  selectable && !isLoading && setIsOpen(!isOpen) }
      >
        { !isLoading && (parseTextName(selected?.name) || placeholder) }
        { isLoading && <span>Cargando{ loadingText }</span>}
      </p>
      <span className="dropdown__separator"></span>

      <ul className="dropdown__select">
        { items.map((item, index) => (
          <li
            key={ `dropdown-${index}` }
            tabIndex={ isOpen ? 0 : -1 }
            value={ item.value }
            className="dropdown__item"
            onKeyPress={ (event) => event.key === 'Enter' && handleSelect(item) }
            onClick={ () => handleSelect(item) }
          >
            { parseTextName(item.name) }
          </li>
        ))}
      </ul>
      { (selectable && !isLoading) && <span className="dropdown__arrow"></span>}
    </fieldset>
  )
}

Dropdown.propTypes = {
  items: PropTypes.arrayOf((propValue, key, componentName) => {
    const args = [propValue[key], 'items', componentName]
    return checkShape({ name: 'string' }, ...args)
  }),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  tabIndex: PropTypes.number,
  onSelect: PropTypes.func,
  uppercase: PropTypes.bool,
}

export default Dropdown
