import { useRef, useState, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import useLayout from 'hooks/misc/useLayout'
import useAuthToken from 'hooks/auth/useAuthToken'
import useCreateInspection, {
  CreateInspectionInput
} from 'hooks/models/inspection/useCreateInspection'

import SettingsContext from 'adapter/context/SettingsContext'

import { PATH as DashboardPath } from 'ui/pages/Dashboard/Dashboard'

import useUploader from './Uploader/hooks/useUploader'
import Layout from './elements/Layout/Layout'
import { showSuccess, showError } from './Messages/Messages'
import NewInspectionForm from './Form/NewInspectionForm'
import Orchestrator from './Uploader/Orchestrator/Orchestrator'
import {
  getUploadConfigByType,
  isTypeOfImageUploadWithoutText,
} from './Uploader/UploadTypes/UploadTypes'
import useCreateInspectionModal from './elements/Modal/CreatingInspectionModal/useCreatingInspectionModal'
import CreatingInspectionModal from './elements/Modal/CreatingInspectionModal/CreatingInspectionModal'
import './NewInspection.scss'

export const PATH = 'new-inspection'
export const pageName = 'Nueva inspección'

const NewInspection = ({ handlePathname }) => {
  useLayout({ path: PATH, handlePathname })
  const history = useHistory()
  const [authToken] = useAuthToken()

  const settings = useContext(SettingsContext)
  let { uploadType } = settings || {}

  const [userSelected, selectUser] = useState(null)
  const [title, setTitle] = useState(null)
  const [description, setDescription] = useState('')
  const uploadConfig = getUploadConfigByType(uploadType)
  const isTypeOfImageUploadWithoutTextFile = isTypeOfImageUploadWithoutText(uploadType)

  const uploader = useRef(null)
  uploader.current = useUploader({ authToken, ...uploadConfig })

  const {
    isCreatingInspection,
    showCreateInspectionModal,
    progress
  } = useCreateInspectionModal()

  const redirectToDashboard = useCallback(() => {
    showCreateInspectionModal(false)
    history.push(DashboardPath, { refetchInspections: true })
  }, [history, showCreateInspectionModal])

  const { launch: createInspection } = useCreateInspection({
    onCompleted: async () => {
      showSuccess()
      redirectToDashboard()
    },
    onError: () => {
      showError()
      showCreateInspectionModal(false)
    }
  })

  const handleUploadCompleted = useCallback((filesWithGeolocation) => {
    showCreateInspectionModal(true)
    const inputForMutation = CreateInspectionInput.create({
      name: title,
      description,
      filesWithGeolocation,
      userId: userSelected.id
    })
    createInspection(inputForMutation)
  }, [createInspection, userSelected?.id, description, title, showCreateInspectionModal])

  const handleUploadCanceled = useCallback(() => {
    redirectToDashboard()
  }, [redirectToDashboard])

  return (
    <Layout
      aside={
        <NewInspectionForm
          userSelected={ userSelected }
          onChangeUser={ selectUser }
          onChangeTitle={ setTitle }
          onChangeDescription={ setDescription }
        />
      }
    >
      <article className="wrapper-scroll">

        <CreatingInspectionModal
          isOpen={ isCreatingInspection }
          progress={ progress }
        />

        <Orchestrator
          withoutTextFile={ isTypeOfImageUploadWithoutTextFile }
          uploader={ uploader.current }
          user={ userSelected }
          title={ title }
          uploadConfig={ uploadConfig }
          onComplete={ handleUploadCompleted }
          onCancel={ handleUploadCanceled }
        />
      </article>
    </Layout>
  )
}

export default NewInspection
