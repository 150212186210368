import Headline from 'ui/elements/Headline/Headline'
import Catalog from 'ui/containers/Catalog/Catalog'

export const PATH = 'dashboard'

const Dashboard = () => (
  <article>
    <Headline>
      <h1>Listado de inspecciones</h1>
    </Headline>
    <Catalog />
  </article>
)

export default Dashboard
