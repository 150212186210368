const geolocationTags = {
  latitude: {
    field: 'GPSLatitude',
    parse: (value) => value?.toString()
  },
  latitudeRef: {
    field: 'GPSLatitudeRef',
    parse: (value) => value?.toString()
  },
  longitude: {
    field: 'GPSLongitude',
    parse: (value) => value?.toString()
  },
  longitudeRef: {
    field: 'GPSLongitudeRef',
    parse: (value) => value?.toString()
  },
  altitude: {
    field: 'GPSAltitude',
    parse: (value) => value?.split(' ')[0] 
  },
}

export default geolocationTags
