import { gql } from '@apollo/client'
import createInput from 'lib/mutations'

export const field = 'validateDetection'

const INPUT = {
  detectionId: 'string.isRequired',
  categoryStatus: 'string.isRequired',
  anomalyTypeStatus: 'string.isRequired',
  severityId: 'string',
  assetId: 'string',
}

export const input = createInput(INPUT)

export const mutation = gql`
  mutation ValidateDetection($input: ValidateDetectionInput!) {
    validateDetection(input: $input) {
      id
      detectionId
      status
      categoryStatus
      anomalyTypeStatus
      observations
      validatedAt
      severity {
        value
      }
      asset {
        name
      }
    }
  }
`
