import './Main.scss'

function Main({ withoutPadding, children }) {
  return (
    <main className={
      'main ' +
      (withoutPadding && 'main--without-padding')
    }>
      <article className="main__container">{ children }</article>
    </main>
  )
}

export default Main
