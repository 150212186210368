function Time({ date }) {
  const dateInit = new Date(date)
  const dateDay = dateInit.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })
  const dateHour = dateInit.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', timeZoneName: 'short', hour12: false })
  const dateFinal = `${dateDay} a las ${dateHour}`

  return (
    <>
      { dateFinal }
    </>
  )
}

export default Time
