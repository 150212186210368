import { createContext } from 'react'
import { useQuery } from '@apollo/client'
import query, { field } from 'infra/settings/settings.query'

export default function useSettings ({ authToken }) {
  const { data, loading, error } = useQuery(query, {
    skip: !authToken,
    variables: {}
  })
  const settings = data?.[field]

  return {
    data: settings || null,
    loading,
    error
  }
}

export const SettingsContext = createContext(null)
