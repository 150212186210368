import { convertMapToArray } from "lib/types/arrays"
import findNearbyMarkersInGroups from "./nearbyMarkerFinder"

import applyOffsetInCollections from './offsetApplicator'

const DISTANCE_THRESHOLD_IN_METERS = 3

const registerMarkers = (mapMutable, markers) => {
  markers.forEach(marker => {
    mapMutable.set(marker.id, marker)
  })
  return mapMutable
}

export default function spiderfier({ markers: originalMarkers, groupBy }) {
  if (originalMarkers?.length === 0) return []
  const markersWithOffset = new Map()
  const groups = findNearbyMarkersInGroups({
    markers: originalMarkers,
    distanceThreeshold: DISTANCE_THRESHOLD_IN_METERS,
    groupBy
  })

  applyOffsetInCollections(groups, (groupWithOffset) => {
    registerMarkers(markersWithOffset, groupWithOffset)
  })
  registerMarkers(markersWithOffset, originalMarkers)

  return convertMapToArray(markersWithOffset)
}
