import { gql } from '@apollo/client'

export const ROIS = gql`
  fragment RoisFragment on Detection {
    rois {
      id
      x1
      x2
      y1
      y2
    }
  }
`
