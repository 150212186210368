import PropTypes from 'prop-types'
import "./Table.scss"

const Table = ({ headers, className, children }) => (
  <table className={ `table ${className ? className : '' }` }>
    <thead>
      <tr>
        { headers.map((value, index) => (
          <th key={ index }
            className={ `${className ? className + '__th': ''} --${value.id || 'no-id' }` }
          >{ value.label || value }</th>
        ))}
      </tr>
    </thead>
    <tbody>{ children }</tbody>
  </table>
)

Table.propTypes = {
  headers: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Table
