import { xor, sortBy } from 'lodash'

export const convertMapToArray = (map) =>
  (map instanceof Map && [...map.values()]) || []

export const arraysAreSymmetric = (array1, array2) =>
  (Array.isArray(array1) && Array.isArray(array2) &&
  xor(array2, array1).length === 0) || false

export const sortArrayByFields= (array, fields) => {
  if (!Array.isArray(array)) return array
  if (!array.every(item => Object.getPrototypeOf(item) === Object.prototype)) return array
  return sortBy(array, fields)
}
