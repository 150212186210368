import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useLayout ({ path, force = false, handlePathname }) {
  const location = useLocation()

  useEffect(() => {
    const { pathname } = location
    const includesPath = pathname.includes(path)
    handlePathname?.(force ? true : includesPath)

    return () => { handlePathname(false) }
  }, [location, handlePathname, path, force])

  return { location }
}

export default useLayout
