import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  CATEGORIES_QUERY_WITH_HIERARCHY,
  field
} from 'infra/categories/categories.query'

function useGetCategoriesWithHierarchy ({ certainCategoryByName } = { certainCategoryByName: null }) {
  const [certainCategory, setCertainCategory] = useState(null)
  const { data, loading, error } = useQuery(
    CATEGORIES_QUERY_WITH_HIERARCHY, {
    variables: { hierarchy: true }
  })

  const categories = data?.[field]

  useEffect(() => {
    if (!certainCategoryByName) return
    if (!categories || categories.length === 0) return
    const [category] = categories?.filter(({ name }) => name === certainCategoryByName)
    setCertainCategory(category)
  }, [categories, certainCategoryByName, setCertainCategory])

  return {
    data: certainCategoryByName ? certainCategory?.id : categories,
    loading,
    error
  }
}

export default useGetCategoriesWithHierarchy
