const initialState = {
  parentCategory: null,
  asset: null,
  categories: [],
  inputPrecharged: {}
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'SET_PARENT_CATEGORY':
      return { ...state, parentCategory: payload }
    case 'SET_ASSET':
      return { ...state, asset: payload }
    case 'RESET_ASSET':
      return { ...state, asset: null }
    case 'UPDATE_CATEGORIES':
      return {
        ...state,
        categories: state.parentCategory.subcategories
      }
    case 'UPDATE_PRECHARGED_INPUT':
      return {
        ...state,
        inputPrecharged: { category: { collection: state.categories } }
      }
    default: return state
  }
}

export {
  initialState,
  reducer
}
