import { useEffect, useRef, useState, useCallback} from 'react'

const MAX_CHARACTERS = 3
const INTERVAL = 500 // ms
const CHARACTER = '.'

function useLoadingText ({
  isLoading,
  prefix = '',
  character = CHARACTER,
  interval = INTERVAL,
  maxCharacters = MAX_CHARACTERS
}) {
  const intervalRef = useRef()
  const [literal, setLiteral] = useState('')

  const updateLiteral = useCallback((oldLiteral) => {
    const isAllow = oldLiteral.length < maxCharacters
    return isAllow ? oldLiteral + character : ''
  }, [character, maxCharacters])

  useEffect(() => {
    if (!isLoading) return
    intervalRef.current = setInterval(() => {
      setLiteral(updateLiteral)
    }, interval)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isLoading, updateLiteral, interval])

  return {
    loadingText: prefix ? (prefix + ' ' + literal) : literal,
    cancel: () => clearInterval(intervalRef.current)
  }
}

export default useLoadingText
