const locale = {
  strings: {
    uploading: 'Subiendo',
    complete: 'Completado',
    uploadFailed: 'Subida fallada',
    paused: 'Pausado',
    retry: 'Reintentar',
    cancel: 'Cancelar',
    pause: 'Pausar',
    resume: 'Resume',
    done: 'Hecho',
    filesUploadedOfTotal: {
      0: '%{complete} de %{smart_count} fichero subido',
      1: '%{complete} de %{smart_count} ficheros subidos',
    },
    dataUploadedOfTotal: '%{complete} de %{total}',
    xTimeLeft: 'Falta %{time}',
    uploadXFiles: {
      0: 'Upload %{smart_count} file',
      1: 'Upload %{smart_count} files',
    },
    uploadXNewFiles: {
      0: 'Upload +%{smart_count} file',
      1: 'Upload +%{smart_count} files',
    },
    upload: 'Upload',
    retryUpload: 'Retry upload',
    xMoreFilesAdded: {
      0: '%{smart_count} more file added',
      1: '%{smart_count} more files added',
    },
    showErrorDetails: 'Show error details',
  },
}

export default locale
