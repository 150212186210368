import PropTypes from 'prop-types'
import Button from './Button'
import useLoadingText from 'hooks/dom/useLoadingText'

const tricky = (callback) => { setTimeout(callback, 1) }

const ButtonSuccess = ({
  isClicked = false,
  setIsClicked,
  label,
  children,
  onAction,
  disabled
}) => {
  const { loadingText } = useLoadingText({ prefix: label, isLoading: isClicked })

  return (
    <Button
      variant="none"
      className="button-clean --success"
      disabled={ isClicked || disabled }
      onClick={ (event) => {
        setIsClicked?.(true)
        tricky(() => { onAction?.(event)})
      } }
    >{ !isClicked ? children : loadingText }</Button>
  )
}

ButtonSuccess.propTypes = {
  isClicked: PropTypes.bool,
  setIsClicked: PropTypes.func,
  label: PropTypes.string,
  onAction: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ButtonSuccess
