import { TYPES } from 'domain/detection'
import Button from 'ui/elements/Button/Button'
import CreateDetectionForm from './CreateDetection/CreateDetectionForm'
import CreateDetectionFormLight from './CreateDetection/CreateDetectionFormLight'
import ValidateDetectionForm from './ValidateDetectionForm'
import ValidateDetectionCategoryForm from './ValidateDetectionCategoryForm'
import ViewDetectionForm from './ViewDetectionForm'

export const ErrorMessage = ({ message, textButton, refetch }) => (
  <section>
    <Header />
    <p>{message || 'Ha ocurrido un error'} </p>
    <Button
      className="button-clean"
      variant="secondary"
      onClick={() => refetch && refetch()}
    >
      {textButton || 'Recargar'}
    </Button>
  </section>
)

const HEADER_LITERALS = {
  TITLE_ANOMALY: 'Categorizar defecto',
  TITLE_CATEGORY: 'Categorizar conjunto',
  DESCRIPTION: `Rellena los formularios contiguos y
    pulsa aceptar para categorizar un defecto. Si por
    el contrario quieres descartar el defecto, pulsa
    el botón cancelar.`,
}

export const Header = ({ title, description, type = TYPES.ANOMALY }) => (
  <section className="anomaly-form__intro">
    <h3 className="anomaly-form__title">
      {title ||
        (type === TYPES.ANOMALY
          ? HEADER_LITERALS.TITLE_ANOMALY
          : HEADER_LITERALS.TITLE_CATEGORY)}
    </h3>
    <p>{description || HEADER_LITERALS.DESCRIPTION}</p>
  </section>
)

const clearAndUpper = (text) => text.replace(/-/, '').toUpperCase()
const toPascalCase = (text) => text.replace(/(^\w|-\w)/g, clearAndUpper)

const FormTypes = Object.freeze({
  CREATE_DETECTION: {
    component: CreateDetectionForm,
    name: 'CreateDetectionForm',
    withCache: true,
  },
  CREATE_DETECTION_LIGHT: {
    component: CreateDetectionFormLight,
    name: 'CreateDetectionFormLight',
    withCache: true,
  },
  VALIDATE_DETECTION: {
    component: ValidateDetectionForm,
    name: 'ValidateDetectionForm',
    withCache: true,
  },
  VALIDATE_DETECTION_CATEGORY: {
    component: ValidateDetectionCategoryForm,
    name: 'ValidateDetectionCategoryForm',
    withCache: true,
  },
  VIEW_DETECTION: {
    component: ViewDetectionForm,
    name: 'ViewDetectionForm',
  },
})

const FormTypesValues = Object.values(FormTypes)

const exists = (componentToCheck) =>
  FormTypesValues.find(
    ({ name }) => name === toPascalCase(componentToCheck.name)
  )

export function renderForm(type) {
  if (!exists(type)) {
    console.error(`Invalid form type: ${type}`)
    return null
  }
  return type
}

export default FormTypes
