import { useEffect, useState, useContext, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { isProd } from 'lib/env'
import Headline from 'ui/elements/Headline/Headline'
import Sidebar from 'ui/components/Sidebar/Sidebar'
import Catalog from 'ui/containers/Catalog/Catalog'
import DeleteInspections from 'ui/components/DeleteInspections/DeleteInspections'

import useClickCounter from 'hooks/misc/useClickCounter/useClickCounter'
import useLayout from 'hooks/misc/useLayout'

import ImagesContext from 'adapter/context/ImagesContext'

import { PATH as path } from 'ui/pages/Dashboard/Dashboard'
import NewInspectionButton from 'ui/pages/NewInspection/elements/Button/NewInspectionButton'
import './GeoDashboard.scss'

export const pageName = 'Listado de inspecciones'

const GeoDashboard = ({ handlePathname }) => {
  const [refetching, setRefetching] = useState(false)
  const location = useLocation()
  useLayout({ path, handlePathname })
  const { resetActivePointIndex } = useContext(ImagesContext)

  const [inspectionIds, setInspectionIds] = useState('')
  const [showDeleteInspections, setShowDeleteInspections] = useState(false)
  const { handleClickCount, clearClickCount } = useClickCounter({
    onClick: () => {
      if (!showDeleteInspections || inspectionIds.length === 0) return
      clearClickCount()
    },
    callback: useCallback(() => {
      setShowDeleteInspections(!showDeleteInspections)
    }, [showDeleteInspections, setShowDeleteInspections])
  })

  useEffect(() => {
    setRefetching(location?.state?.refetchInspections ?? false)
  }, [location])

  useEffect(() => {
    resetActivePointIndex()
  }, [resetActivePointIndex])

  return (
    <section className="dashboard">
      <Sidebar />
      <article className="dashboard__content">
        <section className="dashboard__scroll">
          <Headline className="dashboard__headline"
            onClick={ () => { !isProd && handleClickCount() }}
          >
            { (!isProd && showDeleteInspections) && (
                <DeleteInspections
                  inspectionIds={ inspectionIds }
                  setInspectionIds={ setInspectionIds }
                  onCompleted={ () => {
                    setRefetching(true)
                  }}
                />
            )}
            <NewInspectionButton>Crear inspección</NewInspectionButton>

          </Headline>
          <Catalog
            refetchInspections={ refetching }
            refetched={ () => setRefetching(false) }
          />
        </section>
      </article>
    </section>
  )
}

export default GeoDashboard
