import React from 'react'
import Anomalies from 'ui/components/Anomalies/Anomalies'
import Time from 'ui/components/Time/Time'
import Chart from 'ui/elements/Chart/Chart'
import Tooltip from 'ui/elements/Tooltip/Tooltip'

import "./DetailResults.scss"

const mod = "detail-results"

const txtTooltip = (correct, incorrect, doubt) => (
  <p>
    <span>Estado:</span>
    <br /><strong className="ok">{Math.floor(correct)}%</strong> correctas
    <br /><strong className="ko">{Math.floor(incorrect)}%</strong> erróneas
    <br /><strong className="doubt">{Math.floor(doubt)}%</strong> en duda
  </p>)

function DetailResults ({ inspection, validateDetectionHandler }) {
  const detections = inspection.detections
  const nodes = detections?.edges?.map((edge) => edge.node) || []

  const getPercent = (num) => (num * 100) / detections?.totalCount

  const validationProgress = {
    correct: getPercent(detections?.totalCorrect),
    incorrect: getPercent(detections?.totalIncorrect),
    doubt: getPercent(detections?.totalDoubt),
    pending: getPercent(detections?.totalPending),
  }
  const validationColors = ["#90C566", "#DB4F4F", "#E3A357", "#EDEDED"]

  return (
    <div className={`${mod}`}>
      { inspection.status === 'COMPLETED' && nodes.length > 0 ? (
        <>
          <aside className={`${mod}__sidebar`}>
            <dl>
              <dt>Defectos a identificar por la IA</dt>
              <dd>{"Sin datos"}</dd>

              <dt>Fecha</dt>
              <dd><Time date={inspection.createdAt} /></dd>
            </dl>
          </aside>

          <div className={`${mod}__main`}>
            <div className={`${mod}__heading`}>
              <div className={`${mod}__heading__col`}>
                <h2 className={`${mod}__subtitle`}>Listado de validación</h2>
                <p>
                  <strong>
                    {nodes.length || 0} defecto{nodes.length !== 1 && 's'}
                  </strong>{' '}
                  ha{nodes.length !== 1 && 'n'} sido detectado{nodes.length !== 1 && 's'} por la IA.
                </p>
              </div>
              <div className={`${mod}__heading__col`}>
                <h3>Validación</h3>
                <Tooltip label={txtTooltip(
                  validationProgress.correct,
                  validationProgress.incorrect,
                  validationProgress.doubt
                )}>
                  <Chart data={validationProgress} colors={validationColors} />
                </Tooltip>
              </div>
            </div>
            {nodes.length > 0 && (
              <Anomalies entries={ nodes } validate={ validateDetectionHandler } />
            )}
          </div>
        </>
      ) : inspection.status === 'ERROR' ? (
        <p>
          No ha sido posible <strong>realizar el análisis</strong> de la
          inspección.
        </p>
      ) : (
        (inspection.status === 'PROCESSING' ||
          inspection.status === 'PENDING' ||
          nodes.length < 1) && (
          <p>
            Por el momento <strong>no tenemos</strong> resultados que mostrar.
          </p>
        )
      )}
    </div>
  )
}

export default DetailResults
