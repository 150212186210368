import _ from 'lodash'
import IMAGES_UPLOAD_CONFIG from './images/images.uploadType'
import IMAGES_WITHOUT_TEXT_UPLOAD_CONFIG from './images/images.withoutText.uploadType'
import VIDEO_UPLOAD_CONFIG from './video/video.uploadType'

const isByFeatureFlag = (UPLOAD_TYPE) => (type) =>
  (_.isEqual(UPLOAD_TYPE?.byFeatureFlag, type)) || false

const isUploadType = (UPLOAD_TYPE) => (type) => isByFeatureFlag(UPLOAD_TYPE)(type)

const isVideoUploadType = (uploadType) => isUploadType(VIDEO_UPLOAD_CONFIG)(uploadType)
const isImagesUploadType = (uploadType) => isUploadType(IMAGES_UPLOAD_CONFIG)(uploadType)
const isTypeOfImageUploadWithoutText = (uploadType) => isUploadType(IMAGES_WITHOUT_TEXT_UPLOAD_CONFIG)(uploadType)

function getUploadConfigByType (uploadType) {
  if (isUploadType(VIDEO_UPLOAD_CONFIG)(uploadType)) {
    return VIDEO_UPLOAD_CONFIG
  } else if (isUploadType(IMAGES_UPLOAD_CONFIG)(uploadType)) {
    return IMAGES_UPLOAD_CONFIG
  } else if (isUploadType(IMAGES_WITHOUT_TEXT_UPLOAD_CONFIG)(uploadType)) {
    return IMAGES_WITHOUT_TEXT_UPLOAD_CONFIG
  }
  console.error('Ha ocurrido un error con el tipo de subida de ficheros.')
  return null
}

export {
  isVideoUploadType,
  isImagesUploadType,
  isTypeOfImageUploadWithoutText,
  getUploadConfigByType,
}
