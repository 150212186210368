export const TYPES = Object.freeze({
  ANOMALY: 'ANOMALY',
  CATEGORY: 'CATEGORY',
})

const STATUS = Object.freeze({
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  DOUBT: 'DOUBT',
  PENDING: 'PENDING',
})

const IGNORE_BY_VALIDATION = [STATUS.INCORRECT, STATUS.DOUBT]

const TEXTS = Object.freeze({
  [STATUS.CORRECT]: 'validado',
  [STATUS.INCORRECT]: 'descartado',
  [STATUS.PENDING]: 'pendiente',
})

function filterByValidation(detections) {
  if (!detections) return []
  const detectionsFiltered = detections.filter(({ validation }) => {
    if (!validation?.status) return true
    return !IGNORE_BY_VALIDATION.includes(validation?.status)
  })
  return detectionsFiltered
}

function filterByType(detections, typeSelected) {
  if (!detections) return []
  const detectionsFiltered = detections.filter(({ type }) => {
    if (!type) return false
    return type === typeSelected
  })
  return detectionsFiltered
}

const hasSomeCategorySelected = (categories = null) =>
  categories &&
  typeof categories === 'object' &&
  (Object.keys(categories) || []).reduce(
    (acc, categoryKey) => acc || categories[categoryKey],
    false
  )

function filterByCategories(detections, categories = null) {
  if (!detections) return []
  if (!categories || !hasSomeCategorySelected(categories)) return detections
  const detectionsFiltered = detections.filter(
    ({ anomaly, category, type }) => {
      const detectionCategoryExternalReference =
        type === TYPES.ANOMALY ? anomaly?.category?.externalReference : category?.externalReference
      if (!detectionCategoryExternalReference) return false
      return categories[detectionCategoryExternalReference]
    }
  )
  return detectionsFiltered
}

export function filterDetections(
  detections,
  type = TYPES.ANOMALY,
  categoriesSelected = null
) {
  if (!detections) return []
  const detectionsByValidation = filterByValidation(detections)
  const detectionsByType = filterByType(detectionsByValidation, type)
  return filterByCategories(detectionsByType, categoriesSelected)
}

export function filterDetectionsWithoutRoi(
  detections,
) {
  if (!detections) return []
  return detections.filter(
    ({ polygons, rois }) =>
      (!polygons || !polygons.length) && (!rois || !rois.length)
  )
}

export function getValidationStatusVerb(status) {
  if (status in TEXTS) return TEXTS[status]
  return ''
}

export function belongsToGeolocatedImage(detection) {
  return detection?.images[0]?.position
}

export {
  STATUS as VALIDATION_STATUS,
  TEXTS as VALIDATION_TEXTS,
  TYPES as DETECTION_TYPES,
}
