import { gql } from '@apollo/client'

export const ASSET = gql`
  fragment AssetFragment on Detection {
    asset {
      id
      externalReference
      name
    }
  }
`
