import { useEffect, useState } from 'react'
import Button from 'ui/elements/Button/Button'
import Time from 'ui/components/Time/Time'
import Modal, { closeModal, openModal } from 'ui/elements/Modal/Modal'
import ReviewHeader from './ReviewHeader/ReviewHeader'
import ReviewImage from './ReviewImage/ReviewImage'
import ReviewForm from  './ReviewForm/ReviewForm'
import './Review.scss'

const mod = "review"

const range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx)

const Review = ({
  index,
  item,
  categories,
  anomalyTypes,
  detections,
  onLaunchTooltip,
  onReview
}) => {
  const categoryState = useState(item?.review?.categoryStatus)
  const anomalyTypeState = useState(item?.review?.anomalyTypeStatus)
  const observationsState = useState(item?.review?.observations)
  const [doneSubmit, setDoneSubmit] = useState(false)
  const [hasChange, setHasChange] = useState(false)

  const preDate = item?.review?.validatedAt || ""
  const identifier = item?.id || ""
  const reviewIdentifier = `${mod}-${index + 1}`

  const findNext = (n) => {
    const nextIndex = Number(n) + 1
    const nextAnomaly = `${mod}-${nextIndex}`
    const nextAnomalyNode = document.querySelector("#" + nextAnomaly)
    nextAnomalyNode && openModal(nextAnomaly)
  }

  const openNext = (detections, index) => {
    const length = detections.length - 1

    if (length === index) {
      closeModal(reviewIdentifier)
      return
    }
    const ary = [...range(index + 1, length), ...Array(index).keys()]

    for (let i of ary) {
      const { review } = detections[i]
      if (!review?.reviewedAt) {
        findNext(i)
        return
      }
    }
  }

  const onSubmit = () => {
    setDoneSubmit(true)
    onReview({
      detectionId: identifier,
      categoryStatus: categoryState[0],
      anomalyTypeStatus: anomalyTypeState[0],
      observations: observationsState[0],
    })

    setTimeout(() => {
      setDoneSubmit(false)
      closeModal(reviewIdentifier)
      openNext(detections, index)
      setHasChange(false)
      cleanState()
    }, 800)
  }

  const cleanState = () => {
    if (!hasChange) return
    categoryState[1](item?.review?.categoryStatus)
    anomalyTypeState[1](item?.review?.anomalyTypeStatus)
    observationsState[1](item?.review?.observations || '')
  }

  useEffect(() => {
    if ((item?.review?.categoryStatus !== categoryState[0]) ||
      (item?.review?.anomalyTypeStatus !== anomalyTypeState[0]) ||
      (item?.review?.observations !== observationsState[0])) {
      setHasChange(true)
    }
  }, [categoryState[0], anomalyTypeState[0], observationsState[0]])

  return (
    <Modal
      id={ reviewIdentifier }
      className="review"
      trigger={ false }
      onExit={ cleanState }
    >
      <article className="review__container">
        <section>
          <ReviewHeader
            identifier={ identifier }
            anomaly={ item.anomaly }
          />
          <ReviewImage
            identifier={ index }
            frame={ item.frames[0] }
          />
        </section>
        <section className="review__aside">
          <ReviewForm
            identifier={ identifier }
            mod={ mod }
            categories={ categories }
            anomalyTypes={ anomalyTypes }
            categoryState={ categoryState }
            anomalyTypeState={ anomalyTypeState }
            observationsState={ observationsState }
            onLaunchTooltip={ onLaunchTooltip }
          >
            <Button
              type="button"
              disabled={ !categoryState[0] || !anomalyTypeState[0] || doneSubmit }
              onClick={ onSubmit }
            >{ doneSubmit ? '...' : 'Validar'}
            </Button>

            { preDate && (<p className="note"
              >La anomalía fue validada el <Time date={ preDate } /></p>)}
          </ReviewForm>
        </section>
      </article>
    </Modal>
  )
}

export default Review
