const FIELDS = Object.freeze({
  DATE: 'date',
  TIME: 'time',
  ZONE: 'zone',
})

const CONFIG = {
  [FIELDS.DATE]: {
    separator: '.',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  [FIELDS.TIME]: {
    hour: '2-digit',
    minute: '2-digit',
  },
  [FIELDS.ZONE]: {
    timeZoneName: 'short',
  }
}

const locale = 'es-MX'

const format = {
  [FIELDS.DATE]: (target) => target.toLocaleDateString(locale, CONFIG[FIELDS.DATE])
    .split('/').join(CONFIG[FIELDS.DATE].separator),
  [FIELDS.TIME]: (target) => target.toLocaleTimeString(locale, CONFIG[FIELDS.TIME]),
  [FIELDS.ZONE]: (target) => target.toLocaleString(locale, CONFIG[FIELDS.ZONE])
    .split(' ').pop(),
}

const handler = {
  get (target, prop) {
    if (!Reflect.has(format, prop)) {
      throw new Error('Error with Date: Invalid property')
    }
    return Reflect.get(format, prop)(target)
  }
}

export {
  FIELDS as DATE_FIELDS,
  handler,
}
