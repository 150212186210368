import PropTypes from 'prop-types'
import { useState, useContext } from 'react'

import useCategorizeDetectionWithoutSeverity from 'hooks/models/detection/useCategorizeDetectionWithoutSeverity'
import useCreateDetection, { CreateDetectionInput } from 'hooks/models/detection/useCreateDetection'
import isStillAssignedUser from 'infra/users/isStillAssignedUser'

import SplitPanelContext, { PANEL_STATUS } from 'adapter/context/SplitPanelContext'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import ImagesContext from 'adapter/context/ImagesContext'
import { get as getCoordinate } from 'domain/coordinate'

import * as Toaster from 'ui/elements/Toast/Toast'
import Spinner from 'ui/elements/Spinner/Spinner'
import MESSAGES from '../../Messages.js'
import Actions from '../../Actions/Actions'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import { Header } from '..'

const CreateDetectionFormLight = ({
  refetchInspection,
  onClose,
  onSuccess,
  withCache = false
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isClickedActions, setIsClickedActions] = useState(false)
  const { image } = useContext(ImagesContext)
  const { setCropStatus, coordinates: roi } = useContext(CropContext)
  const { setActivePanel } = useContext(SplitPanelContext)

  const { category, anomaly } = useCategorizeDetectionWithoutSeverity()

  const { launch: create, loading: isCreatingDetection } = useCreateDetection({
    withCache,
    imageId: image.id,
    onError: () => { Toaster.showError(MESSAGES.ERROR) },
    onCompleted: (response) => {
      Toaster.showSuccess(MESSAGES.SUCCESS)
      setCropStatus(CROP_STATUS.LOADED)
      setActivePanel(PANEL_STATUS.SPLIT_VIEW)
      onClose()
      !withCache && (onSuccess && onSuccess(response))
    }
  })

  const createDetectionInput = () =>
    CreateDetectionInput.create({
      imageId: image.id,
      roi: [getCoordinate(roi)],
      anomalyId: anomaly.selected.id,
    })

  const handleSend = () => {
    if (isCreatingDetection) return
    const detection = createDetectionInput()
    create(detection)
  }

  const handleCancel = () => {
    onClose()
    Toaster.showCancel(MESSAGES.CANCEL)
    setCropStatus(CROP_STATUS.READY_TO_DRAW)
  }

  const handleSuccessfulCreatedDetection = async () => {
    setIsLoading(true)
    await isStillAssignedUser({
      trigger: refetchInspection,
      onSuccess: () => { handleSend() },
      onFailure: () => {
        setIsClickedActions(false)
        setIsLoading(false)
      }
    })
  }

  const hasAnomalies = Boolean(anomaly.collection.length > 0 && anomaly.selected) || anomaly.collection.length === 0
  const isAllSelected = Boolean(category.selected && hasAnomalies)

  return (
    <form className="form-detection">
      <Header />

      { isLoading && <Spinner className="anomaly-form__loading" dark /> }

      { !isLoading && (<>
       <Dropdown
          label="Conjunto"
          itemSelected={ category.selected }
          items={ category.collection }
          onSelect={ category.select }
          isLoading={ category.loading }
        />
        <Dropdown
          label="Operación"
          itemSelected={ anomaly.selected }
          items={ anomaly.collection }
          onSelect={ anomaly.select }
          isLoading={ anomaly.loading }
        />
      </>)}
      { !isLoading && (
        <Actions
          isEnabled={ isAllSelected }
          isClicked={ isClickedActions }
          setIsClicked={ setIsClickedActions }
          onSend={ handleSuccessfulCreatedDetection }
          onCancel={ handleCancel }
        />
      )}
    </form>
  )
}

CreateDetectionFormLight.propTypes = {
  inspectionId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  withCache: PropTypes.bool,
}

export default CreateDetectionFormLight
