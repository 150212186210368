import { useContext } from 'react'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import useKeydown from 'hooks/event/useKeydown'
import { DRAG_MODE } from '../../DrawVendor/DrawVendorConfig'

const KEYCODES = {
  R: '82',
  C: '67',
}

const useDrawShortcuts = ({
  isOriginalReady,
  originalCropper,
  isAssignedUser,
  onReset,
}) => {
  const { cropStatus, setCropStatus } = useContext(CropContext)

  const handleToggleCrop = () => {
    if (!isOriginalReady || !isAssignedUser) return
    if (cropStatus === CROP_STATUS.LOADED) {
      originalCropper.setDragMode(DRAG_MODE.CROP)
      setCropStatus(CROP_STATUS.READY_TO_DRAW)
    } else {
      originalCropper.clear()
      originalCropper.setDragMode(DRAG_MODE.MOVE)
      setCropStatus(CROP_STATUS.LOADED)
    }
  }

  useKeydown({ keyCode: KEYCODES.R, onAction: onReset })
  useKeydown({ keyCode: KEYCODES.C, onAction: handleToggleCrop })
}

export default useDrawShortcuts
