import PropTypes from 'prop-types'
import './ReviewHeader.scss'

const ReviewHeader = ({ identifier, anomaly }) => (
  <header className="review-heading">
    <section className="review-heading__index">
      ID:<span className='index'>{ identifier }</span>
    </section>
    <section>
      <h2 className="review-heading__title">
        { anomaly?.category.externalReference } · { anomaly?.category.name }
      </h2>
      <h3 className="review-heading__subtitle">
        { anomaly?.type.externalReference} · { anomaly?.type.name }
      </h3>
    </section>
  </header>
)

ReviewHeader.propTypes = {
  identifier: PropTypes.string.isRequired,
  anomaly: PropTypes.object.isRequired
}

export default ReviewHeader
