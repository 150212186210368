import PropTypes from 'prop-types'
import Button from 'ui/elements/Button/Button'
import ButtonSuccess from 'ui/elements/Button/ButtonSuccess'
import useConfirmButton from 'hooks/dom/useConfirmButton'

const CANCEL_LABEL_BY_DEFAULT = 'Cancelar'
const SUCCESS_LABEL_BY_DEFAULT = 'Aceptar'

const Actions = ({
  labelSuccess,
  labelCancel,
  isEnabled = true,
  isClicked,
  setIsClicked,
  onCancel,
  onSend,
}) => {
  const { handleConfirm, confirmText: cancelText } = useConfirmButton({
    label: labelCancel || CANCEL_LABEL_BY_DEFAULT,
    confirmedLabel: '...',
    callbackToAction: onCancel,
    secondsToConfirm: 10
  })

  return (
    <section className="anomaly-form__actions">
      <Button
        variant="none"
        className="button-clean --failure"
        onClick={ handleConfirm }
      >{ cancelText }</Button>
      <ButtonSuccess
        isClicked={ isClicked }
        setIsClicked={ setIsClicked }
        disabled={ !isEnabled }
        onAction={ onSend }
      >{ labelSuccess || SUCCESS_LABEL_BY_DEFAULT }</ButtonSuccess>
    </section>
  )
}

Actions.propTypes = {
  labelSuccess: PropTypes.string,
  labelCancel: PropTypes.string,
  isEnabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSend: PropTypes.func,
}

export default Actions
