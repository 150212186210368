import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isProd } from 'lib/env'
import Dot from 'ui/elements/Dot/Dot'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import useCountdown from 'hooks/time/useCountdown'
import useAddClasses from 'hooks/dom/useAddClasses'
import calculateCoordinates from './calculateCoordinates'
import './Rois.scss'
import { TYPES } from 'domain/detection'

const WIDTH_BY_DEFAULT = 1440
const HEIGHT_BY_DEFAULT = 1080
const SECONDS_INVISIBLE = isProd ? 10 : 2

const Rois = ({
  index,
  coordinates,
  type,
  width = WIDTH_BY_DEFAULT,
  height = HEIGHT_BY_DEFAULT,
  validationStatus,
  onMouseEnter,
  onMouseLeave,
  onClick,
  children,
  className,
}) => {
  const [roiIsVisible, setRoiIsVisible] = useState(true)
  const [isDotHovered, setIsDotHovered] = useState(false)
  const { start: startInvisibleCountdown } = useCountdown({
    timeout: SECONDS_INVISIBLE,
    onEnd: () => {
      setRoiIsVisible(true)
    },
  })
  const style = useMemo(
    () => calculateCoordinates(coordinates, width, height),
    [coordinates, width, height]
  )

  const classNames = useAddClasses(
    ['rois', className],
    [roiIsVisible ? '' : 'hidden']
  )

  const labelText = !type
    ? 'Clica para ocultar el elemento'
    : type === TYPES.ANOMALY
    ? 'Clica para ocultar el defecto'
    : 'Clica para ocultar el conjunto'

  return (
    <section
      className={classNames}
      onClick={onClick}
      data-testid={`roi-${index}`}
    >
      <div
        className="rois__item"
        style={style}
        onMouseEnter={() => {
          !roiIsVisible ? onMouseLeave?.() : onMouseEnter?.()
        }}
        onMouseLeave={() => {
          onMouseLeave?.()
        }}
      >
        <Tooltip label={labelText}>
          <Dot
            className="rois-dot"
            withBorder
            statusId={validationStatus}
            onMouseEnter={() => {
              setIsDotHovered(true)
            }}
            onMouseLeave={() => {
              setIsDotHovered(false)
            }}
            onClick={(event) => {
              setRoiIsVisible(false)
              startInvisibleCountdown()
              event.stopPropagation()
            }}
          >
            {isDotHovered && <span className="rois-dot__close">X</span>}
          </Dot>
        </Tooltip>

        {children}
      </div>
    </section>
  )
}

Rois.propTypes = {
  index: PropTypes.number,
  coordinates: PropTypes.shape({
    x1: PropTypes.number.isRequired,
    y1: PropTypes.number.isRequired,
    x2: PropTypes.number.isRequired,
    y2: PropTypes.number.isRequired,
  }).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  validationStatus: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Rois
