const IMAGES = {
  id: 'IMAGES',
  byFeatureFlag: ['images', 'text'],
  fileTypesSupported: ['image/*', '.txt'],
  allowMultipleUploads: true,
  maxNumberOfFiles: null,
  withCheckGeolocation: true,
}

export default IMAGES
