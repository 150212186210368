import PropTypes from 'prop-types'
import { capitalize, SKELETON_TEXT } from 'lib'
import Tooltip from '../../Tooltip/Tooltip'
import './AssetTooltip.scss'

const AssetTooltip = ({ asset }) => {
  if (!asset) return null

  return (
    <Tooltip className="asset-tooltip">
      <dl>
        <dt>Tipo de objeto</dt>
        <dd>{ capitalize(asset?.category?.name) || SKELETON_TEXT }</dd>
        <dt>Matrícula</dt>
        <dd>{ asset?.name || asset?.externalReference || SKELETON_TEXT }</dd>
      </dl>
    </Tooltip>
  )
}

AssetTooltip.propTypes = {
  asset: PropTypes.object,
}

export default AssetTooltip
