import { gql } from '@apollo/client'

export const field = 'anomalies'

export default gql`
  query getAnomalies($categoryExternalReference: ID) {
    anomalies(categoryExternalReference: $categoryExternalReference) {
      id
      name
      externalReference
    }
  }
`
