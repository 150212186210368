import { useCallback, useEffect } from 'react'
import { CATEGORIES_QUERY, field as CATEGORIES_FIELD } from 'infra/categories/categories.query'
import { addNameField } from 'infra'
import ANOMALIES_QUERY, { field as ANOMALIES_FIELD } from 'infra/anomalies/anomalies.query'
import SEVERITIES_QUERY, { field as SEVERITIES_FIELD } from 'infra/severities/severities.query'
import useDependentQueries from '../../query/useDependentQueries'

const mainQuery = {
  query: CATEGORIES_QUERY,
  field: CATEGORIES_FIELD,
  variables: { hierarchy: true }
}

const dependentQuery = {
  query: ANOMALIES_QUERY,
  field: ANOMALIES_FIELD
}

export default function useCategorizeDetection ({ cleanTrigger }) {
  const getVariablesForAnomalies = useCallback(({ externalReference: ref }) => ({ categoryExternalReference: ref }), [])
  const getVariablesForSeverities = useCallback(({ id }) => ({ anomalyId: id }), [])

  const [category, anomaly] = useDependentQueries({
    main: mainQuery,
    dependent: dependentQuery,
    getVariables: getVariablesForAnomalies
  })

  const [, severity] = useDependentQueries({
    dependent: { query: SEVERITIES_QUERY, field: SEVERITIES_FIELD },
    getVariables: getVariablesForSeverities,
    cached: anomaly.collection,
    cachedSelected: anomaly.selected
  })

  useEffect(() => {
    anomaly.select(null)
    severity.select(null)
    cleanTrigger && anomaly.setCollection([])
  }, [category.selected, cleanTrigger])

  const categoriesParsed = addNameField(category.collection, ({ externalReference, name }) => externalReference + ' · ' + name)
  const severitiesParsed = addNameField(severity.collection, ({ value }) => value)

  return {
    category: { ...category, collection: categoriesParsed },
    severity: { ...severity, collection: severitiesParsed },
    anomaly,
  }
}
