import PropTypes from 'prop-types'
import { useState } from 'react'
import useAddClasses from 'hooks/dom/useAddClasses'
import useUrlOfKey from 'hooks/query/useUrlOfKey'
import noPhotoImage from 'ui/theme/assets/icons/noPhoto.svg'
import './Thumbnail.scss'

const SkeletonByDefault = ({ children }) => (<section className="skeleton">{ children }</section>)

const ErrorMessageByDefault = ({ withFallback, isVideo }) =>
  <SkeletonByDefault>
    { !withFallback && 'Error' }
    { (withFallback && !isVideo) && <img src={ noPhotoImage } alt="Not found" /> }
    { (withFallback && isVideo) && <span className="icon--play" /> }
  </SkeletonByDefault>

const Thumbnail = ({
  url,
  urlKey: key,
  type,
  withHover,
  isSquare,
  isFull,
  isSmall,
  isVideo,
  withFallback,
  onClick,
  width,
  className,
  errorMessage,
  skeleton,
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { data: urlRequested, loading, error } = useUrlOfKey({ key })
  const classNames = useAddClasses([className], [
    isFull && 'is-full',
    isSquare && 'is-square',
    isSmall && 'is-small',
    isVideo && 'is-video',
    withHover && 'with-hover',
  ])

  return (<>
    <figure
      className={ 'thumbnail ' + classNames }
      onClick={ onClick }
    >
      { (error || (!key && !url)) && (errorMessage || <ErrorMessageByDefault withFallback={ withFallback } isVideo={ isVideo } />)}
      { (!error && !isVideo && (!isLoaded || loading)) && (<SkeletonByDefault>{ skeleton }</SkeletonByDefault>) }
      <img
        src={ url || urlRequested }
        className={ 'thumbnail__image ' + (className && className + '__image') }
        onLoad={ () => setIsLoaded(true) }
        style={{ opacity: isLoaded ? 1 : 0 }}
        width={ width }
        alt={ type ? `${type} - thumbnail` : '' }
      />
    </figure>
  </>)
}

Thumbnail.propTypes = {
  url: PropTypes.string,
  urlKey: PropTypes.string,
  type: PropTypes.string,
  withHover: PropTypes.bool,
  isSquare: PropTypes.bool,
  isFull: PropTypes.bool,
  isSmall: PropTypes.bool,
  isVideo: PropTypes.bool,
  withFallback: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
  className: PropTypes.string,
  errorMessage: PropTypes.node,
  skeleton: PropTypes.node,
}

export default Thumbnail
