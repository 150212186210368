import { gql, useQuery } from '@apollo/client'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Spinner from 'ui/elements/Spinner/Spinner'
import Wrapper from 'ui/elements/Wrapper/Wrapper'
import Crumbs from 'ui/components/Crumbs/Crumbs'
import DetailResults from 'ui/containers/DetailResults/DetailResults'
import DetailSource from 'ui/containers/DetailSource/DetailSource'
import useValidateDetection from 'hooks/models/detection/useValidateDetection'
import DetailHeadline from './DetailHeadline/DetailHeadline'
import './Detail.scss'

export const PATH = 'inspection'
export const PARAM = 'inspectionId'

export const INSPECTION_WITH_DETECTIONS_QUERY = gql`
  query inspection($id: ID!) {
    inspection(id: $id) {
      id
      name
      description
      createdAt
      status
      progress
      accountId
      metadata {
        key
        value
      }
      files {
        id
        name
        url
        contentType
        metadata {
          key
          value
        }
      }
      detections {
        edges {
          cursor
          node {
            id
            anomaly {
              id
              externalReference
              name
              category {
                id
                externalReference
                name
              }
              type {
                id
                externalReference
                name
              }
            }
            frames {
              url
              thumbnailUrl
              mediumUrl
              height
              width
              rois {
                id
                x1
                x2
                y1
                y2
              }
              polygons {
                points
              }
            }
            crop {
              smallUrl
            }
            validation{
              id
              status
              categoryStatus
              anomalyTypeStatus
              observations
              validatedAt
              user {
                id
                username
              }
            }
            metadata {
              key
              value
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
        totalPending
        totalCorrect
        totalIncorrect
        totalDoubt
      }
      images {
        id
        file {
          id
          name
          url
          contentType
          metadata {
            key
            value
          }
        }
        url
        thumbnailUrl
        mediumUrl
        metadata {
          key
          value
        }
        height
        width
      }
      anomalies {
        id
        externalReference
        name
        category {
          id
          externalReference
          name
        }
        type {
          id
          externalReference
          name
        }
      }
    }
  }
`

export const INSPECTION_QUERY = gql`
  query inspection($id: ID!) {
    inspection(id: $id) {
      id
      name
      description
      createdAt
      status
      progress
      accountId
      metadata {
        key
        value
      }
      videos {
        id
        file {
          id
          name
          url
          contentType
          metadata {
            key
            value
          }
        }
      }
      files {
        id
        name
        url
        contentType
        metadata {
          key
          value
        }
      }
      anomalies {
        id
        externalReference
        name
        category {
          id
          externalReference
          name
        }
        type {
          id
          externalReference
          name
        }
      }
    }
  }
`

export const pageName = 'Detalle de inspección (legacy)'

function Detail({ match }) {
  const { params } = match
  const inspectionId = params[PARAM]

  const { data, loading, error, refetch } = useQuery(INSPECTION_QUERY, {
    variables: {
      id: inspectionId,
    },
  })

  const { launch: validateDetection } = useValidateDetection({ onCompleted: refetch })

  if (loading) return <Spinner dark />
  if (error) {
    console.error(error)
    return <p>Error - Detail</p>
  }
  if (!data) return <p>Inspección no encontrada.</p>

  const { inspection } = data

  return (
    <article className="detail">
      <Crumbs title="Detalle inspección" />
      <DetailHeadline item={ inspection } />
      <Tabs>
        <TabList className='detail__tabs'>
          <Tab className='detail__tab'>Fuente de datos</Tab>
          <Tab className='detail__tab'>Listado de Defectos</Tab>
        </TabList>

        <Wrapper className='detail__content'>
          <TabPanel>
            <DetailSource inspection={ inspection } />
          </TabPanel>

          <TabPanel>
            <DetailResults
              inspection={ inspection }
              validateDetectionHandler={ validateDetection }
            />
          </TabPanel>
        </Wrapper>
      </Tabs>
    </article>
  )
}

export default Detail
