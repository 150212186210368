import PropTypes from 'prop-types'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Button from 'ui/elements/Button/Button'
import useAddClasses from 'hooks/dom/useAddClasses'

const WithTooltip = ({ username, children  }) => (
  <Tooltip
    label={ `Solamente ${username} puede añadir defectos` }
    direction="down-left"
  >{ children }</Tooltip>)

const AddDetectionButton = ({
  isOriginalLoaded,
  isLoading,
  username,
  isAssignedUser,
  onClick
}) => {

  const classNames = useAddClasses(
    ['button-clean --success'], [
    isLoading && 'is-loading',
    !isAssignedUser && 'is-disabled-with-pointer'
  ])

  const AddButton = () => (
    <Button
      className={ classNames }
      variant="secondary"
      disabled={ isLoading || !isOriginalLoaded || !isAssignedUser }
      onClick={ onClick }
    >Añadir defecto&nbsp;(C)</Button>
  )

  const showButtonWithTooltip = !isAssignedUser && username

  return (
    <section className="photo-actions__option --top">
      { showButtonWithTooltip && <WithTooltip username={ username }><AddButton /></WithTooltip> }
      { !showButtonWithTooltip && <AddButton /> }
    </section>
  )
}

AddDetectionButton.propTypes = {
  isOriginalLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  username: PropTypes.string,
  isAssignedUser: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default AddDetectionButton
