import { TYPE_TEXT, isTextFile } from "./types/types"

const STATUS = Object.freeze({
  NOT_CHECKED: 'NOT_CHECKED',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT'
})

const TEXTS = Object.freeze({
  [STATUS.CORRECT]: 'Correcta',
  [STATUS.INCORRECT]: 'Incorrecta',
  [STATUS.NOT_CHECKED]: 'Analizando'
})

const ORDER = Object.freeze({
  ASC: -1,
  DESC: 1
})

const getNumberOfTextFiles = (files) => files.filter(isTextFile).length
const getTextFile = (files) => files.find(isTextFile)
const getImagesWithoutTextFile = (files) =>
  files.filter(file => !isTextFile(file))

const copyDeeply = (object) => JSON.parse(JSON.stringify(object))

function findImagesCorrupted (images, files) {
  const filesCopy = copyDeeply(files)
  const filesOfImages = images.map(({ file }) => file.id)
  const filesWithImageCorrupted = filesCopy.filter(file =>
    !filesOfImages.includes(file.id) && !isTextFile(file, { field: TYPE_TEXT.alternative }))
  return filesWithImageCorrupted
}

const sortImagesWithTextFile = (files) =>
  files.sort((fileA, fileB) => {
    if (isTextFile(fileA)) { return ORDER.ASC }
    if (isTextFile(fileB)) { return ORDER.DESC }
    return ORDER.DESC
  })

export {
  STATUS as FILE_STATUS,
  TEXTS as FILE_TEXTS,
  findImagesCorrupted,
  sortImagesWithTextFile,
  getImagesWithoutTextFile,
  getNumberOfTextFiles,
  getTextFile
}
