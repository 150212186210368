import React from 'react'
import './Connection.scss'

const mod = 'connection'
let txtOfflineTitle = '¡Oh no! La conexión a internet se ha perdido'
let txtOfflineDetail = 'Restablécela para continuar con la subida del vídeo.'
let txtOnlineTitle = '¡Conexión reestablecida!'
let txtOnlineDetail = 'La subida del vídeo se retomará automáticamente.'

function Connection({ connection }) {
  return (
    <div className={`${mod} ${connection ? 'online' : 'offline'}`}>
      <div className={`${mod}__banner`}>
        <p className={`${mod}__content`}>
          <strong className={`${mod}__title`}>
            {connection ? txtOnlineTitle : txtOfflineTitle}
          </strong>
          <span className={`${mod}__detail`}>
            {connection ? txtOnlineDetail : txtOfflineDetail}
          </span>
        </p>
      </div>
    </div>
  )
}

export default Connection
