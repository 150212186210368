import { useState } from 'react'

const LIMIT = 5

function useClickCounter ({ limit = LIMIT, onClick, callback } = { limit: LIMIT }) {
  const [clickCount, setClickCount] = useState(0)

  const handleClickCount = () => {
    if (clickCount < limit - 1) {
      setClickCount(clickCount + 1)
      onClick?.()
    } else {
      callback?.()
      setClickCount(0)
    }
  }

  return {
    clickCount,
    handleClickCount,
    clearClickCount: () => setClickCount(0)
  }
}

export default useClickCounter
