const GLOBAL_MESSAGES = {
  NO_USER: 'Asigna la inspección a un usuario',
  NO_TITLE: 'Incluye un título para la inspección',
  OFFLINE: `¡Oh no! la conexión a Internet se ha perdido.
    Restablécela para continuar con la subida de archivos.`,
  SUCCESS: 'La inspección ha sido creada con éxito.',
  ERROR: 'La inspección no se pudo crear. Inténtelo más tarde.',
  CANCEL: 'La subida se ha cancelado',
  FILE_REMOVED: (fileType) => `${fileType} se ha borrado con éxito.`,
}

export default GLOBAL_MESSAGES
