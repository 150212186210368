import PropTypes from 'prop-types'
import Button from 'ui/elements/Button/Button'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import useConfirmButton from 'hooks/dom/useConfirmButton'
import AddFilesButton from './AddFilesButton/AddFilesButton'
import { calculateWarningMessage, getMessagesByUploadType } from '../../Messages/Messages'
import './NewInspectionHeader.scss'

const NewInspectionButton = ({ isDisabled, action }) => (
  <Button
    variant="secondary"
    className="button-clean --success"
    disabled={ isDisabled }
    onClick={ action }
  >Crear inspección</Button>
)

const NewInspectionHeader = ({
  uploader,
  isUploadButtonEnabled = false,
  files,
  user,
  title,
  uploadConfig,
  onCancel,
  onUpload,
}) => {
  const {
    handleConfirm: handleDiscard,
    confirmText: discardConfirmText,
  } = useConfirmButton({
    label: 'Descartar inspección',
    callbackToAction: onCancel,
    secondsToConfirm: 10
  })

  const warningMessage = calculateWarningMessage(files, user, title, uploadConfig)
  const addFilesLabel = getMessagesByUploadType(uploadConfig).addFile
  const isAddFilesButtonEnabled = uploadConfig?.maxNumberOfFiles && files.length >= uploadConfig.maxNumberOfFiles

  return (
    <header className="new-inspection-header fixed">

      <Button
        variant="secondary"
        className="button-clean --failure"
        onClick={ handleDiscard }
      >{ discardConfirmText }</Button>

      <section className="new-inspection-header__actions">
        <AddFilesButton
          uploader={ uploader }
          label={ addFilesLabel }
          disabled={ isAddFilesButtonEnabled }
        />

        { isUploadButtonEnabled && (
          <NewInspectionButton
            isDisabled={ false }
            action={ onUpload }
          />
        )}

        { (!isUploadButtonEnabled && warningMessage) && (
          <Tooltip
            label={ `Por favor, ${warningMessage.toLowerCase() }` }
            direction="down-left"
          >
            <NewInspectionButton
              isDisabled={ true }
              action={ onUpload }
            />
          </Tooltip>
        )}
      </section>
    </header>
  )
}

NewInspectionHeader.propTypes = {
  uploader: PropTypes.object,
  isUploadButtonEnabled: PropTypes.bool,
  files: PropTypes.array,
  user: PropTypes.object,
  title: PropTypes.string,
  uploadConfig: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
}

export default NewInspectionHeader
