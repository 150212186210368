import PropTypes from 'prop-types'
import Dot, { DOT_TYPES } from 'ui/elements/Dot/Dot'
import { isTextFile, TYPE_TEXT } from 'domain/files/types/types'
import { FILE_STATUS } from 'domain/files'
import './Counter.scss'

const ignoreTextFiles = (files) =>
  files.filter(file => !isTextFile(file, { field: TYPE_TEXT.alternative }))

const filterByStatus = (files, status) =>
  files.filter(file => file?.status === status)

const Counter = ({ files = [], areVideoFiles = false }) => {
  const totalFiles = ignoreTextFiles(files)
  const totalCorrects = filterByStatus(totalFiles, FILE_STATUS.CORRECT)
  const totalIncorrects = filterByStatus(totalFiles, FILE_STATUS.INCORRECT)
  const gender = areVideoFiles ? 'o' : 'a'
  const typeFileName = areVideoFiles ? 'vídeos ' : 'imágenes '

  return (
    <header className="files-header fixed">
      <ul className="files-header__list">
        <li>Total { typeFileName }<span className="files-header__count">{ totalFiles.length }</span></li>
        <li>
          <Dot statusId={ DOT_TYPES.CORRECT.id } />
          Correct{gender}s
          <span className="files-header__count"> { totalCorrects.length || 0 }</span>
        </li>
        <li>
          <Dot statusId={ DOT_TYPES.INCORRECT.id } />
          Incorrect{ gender }s
          <span className="files-header__count"> { totalIncorrects.length || 0 }</span>
        </li>
      </ul>
    </header>
  )
}

Counter.propTypes = {
  files: PropTypes.array,
  areVideoFiles: PropTypes.bool,
}

export default Counter
