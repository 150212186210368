import React, { useEffect } from "react";
import * as d3 from "d3";
import "./Chart.scss"

const mod = "chart"

function Chart({ data, w, h, s, colors, id, className }) {

    let width = w || 40
    let height = h || 40
    let margin = 0
    let size = w / 2 - s || 18
    let radius = Math.min(width, height) / 2 - margin
    let progress = Math.floor(100 - data.pending)
    let chartID = id || mod

    useEffect(() => {
        document.querySelector("#" + chartID).textContent = ""
        drawChart();
    }, [data]);

    function drawChart() {
        let svg = d3.select("#" + chartID)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

        let color = d3.scaleOrdinal()
            .domain(data)
            .range(colors)

        let pie = d3.pie()
            .value(function (d) { return d.value; })
        let data_ready = pie(d3.entries(data))

        svg
            .selectAll('whatever')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('d', d3.arc()
                .innerRadius(size)
                .outerRadius(radius)
            )
            .attr('fill', function (d) { return (color(d.data.key)) })

    }

    return <div id={chartID} title={progress} className={`${mod} ${className || ""}`}
        style={{ width: width + "px", height: height + "px" }} />;
}

export default Chart