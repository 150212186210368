import { useMutation } from '@apollo/client'
import { updateWithCache } from 'lib'

import {
  mutation,
  input,
  field
} from 'infra/detection/createDetection.mutation'
import { DETECTIONS_OF_IMAGE_QUERY, fieldDetectionsOfImage } from 'infra/inspection/detections.query'

function useCreateDetection ({
  onCompleted,
  onError,
  withCache = false,
  imageId
}) {
  const [createDetection, { loading }] = useMutation(mutation, {
    update(cache, { data }) {
      if (!withCache || !imageId) return
      updateWithCache(cache, data[field], {
        fieldCached: fieldDetectionsOfImage,
        queryCached: DETECTIONS_OF_IMAGE_QUERY,
        variables: { imageId }
      })
    },
    onCompleted(response) {
      onCompleted?.(response)
    },
    onError (error) {
      console.error(error)
      onError?.()
    },
  })
  return {
    loading,
    launch: (input) => {
      createDetection({
        variables: {
          input,
        },
      })
    }
  }
}

export default useCreateDetection
export { input as CreateDetectionInput }
