import PropTypes from 'prop-types'
import { useContext } from 'react'
import { PATH as detailPath } from 'ui/pages/Detail/Detail'
import { PATH as geoDetailPath } from 'ui/pages/GeoDetail/GeoDetail'
import SettingsContext from 'adapter/context/SettingsContext'
import Inspections from './Inspections/Inspections'
import InspectionsLegacy from './Inspections/legacy/InspectionsLegacy'

export const TitleAndDescription = ({ name, description }) => (
  <div className="results__text">
    <p className="results__title">{ name }</p>
    <span className="results__content">
      <i className="results__description">{ description }</i>
    </span>
  </div>
)

const InspectionsList = ({ inspections, isLoading }) => {
  const settings = useContext(SettingsContext)
  const { inspectionFiles, cleanLayout } = settings || {}
  const inspectionPath = cleanLayout ? geoDetailPath : detailPath

  if (!cleanLayout) {
    return (
      <InspectionsLegacy
        inspections={ inspections }
        path={ inspectionPath }
        withFiles={ inspectionFiles }
        isLoading={ isLoading }
      />
    )
  }

  return (
    <Inspections
      inspections={ inspections }
      path={ inspectionPath }
      isLoading={ isLoading }
    />
  )
}

InspectionsList.propTypes = {
  inspections: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default InspectionsList
