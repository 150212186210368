import { useMutation } from '@apollo/client'
import { updateWithCache, updateWithCachePaginated } from 'lib'
import { mutation, input, field as validateDetectionField } from 'infra/detection/validateDetection.mutation'
import {
  DETECTIONS_OF_INSPECTION_QUERY,
  DETECTIONS_OF_IMAGE_QUERY,
  field as fieldDetectionsOfInspection,
  fieldDetectionsOfImage
} from 'infra/inspection/detections.query'

export const field = validateDetectionField

function prepareQueryCached (isWithAllDetections, inspectionId, imageId) {
  const queryCached = isWithAllDetections ? DETECTIONS_OF_INSPECTION_QUERY : DETECTIONS_OF_IMAGE_QUERY
  const fieldCached = isWithAllDetections ? fieldDetectionsOfInspection : fieldDetectionsOfImage
  const variables = isWithAllDetections ? { inspectionId } : { imageId }
  return { queryCached, fieldCached, variables }
}

function useValidateDetection ({
  onCompleted,
  onError,
  withCache = false,
  allDetections = false,
  payload
}) {
  const [validateDetection, { loading }] = useMutation(mutation, {
    update(cache, { data }) {
      if (!withCache) return
      const { inspectionId, imageId } = payload
      const isWithAllDetections = allDetections || !imageId
      const queryCached = prepareQueryCached(isWithAllDetections, inspectionId, imageId)
      const updateCacheHelper = isWithAllDetections ? updateWithCachePaginated : updateWithCache
      updateCacheHelper(cache, data[validateDetectionField], queryCached)
    },
    onCompleted(response) {
     onCompleted?.(response)
    },
    onError: (error) => {
      console.error(error)
      onError?.(error)
    },
  })

  return {
    loading,
    launch: (input) => {
      validateDetection({
        variables: {
          input,
        },
      })
    }
  }
}
export default useValidateDetection
export { input as ValidateDetectionInput }
