import { TYPES } from 'domain/detection'
import { createContext, useState } from 'react'

const Context = createContext({})

export function DetectionsContextProvider({ children }) {
  const [typeDetection, setTypeDetection] = useState(TYPES.ANOMALY)
  const [categoriesSelected, setCategoriesSelected] = useState({})

  const toogleTypeDetection = () =>
    setTypeDetection(
      typeDetection === TYPES.ANOMALY ? TYPES.CATEGORY : TYPES.ANOMALY
    )

  return (
    <Context.Provider
      value={{
        typeDetection,
        setTypeDetection,
        categoriesSelected,
        setCategoriesSelected,
        toogleTypeDetection,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Context
