import {
  getNumberOfTextFiles,
  getImagesWithoutTextFile,
} from 'domain/files'
import { areAllImages as checkAreAllImages } from 'domain/files/types/types'

const canBeCreateAInspectionOfImagesTypeWithoutText = (title, files) => {
  const numberOfTextFiles = getNumberOfTextFiles(files)
  const hasMoreThanOneFile = files.length >= 1
  const areAllImages = checkAreAllImages(getImagesWithoutTextFile(files))

  return (
    title?.length > 0 &&
    hasMoreThanOneFile &&
    numberOfTextFiles === 0 &&
    areAllImages
  )
}

export default canBeCreateAInspectionOfImagesTypeWithoutText
