import PropTypes from 'prop-types'
import { useMemo } from 'react'
import FileRow from '../FileRow/FileRow'
import Notifier from '../Notifier/Notifier'
import { sortImagesWithTextFile } from 'domain/files'
import './FilesTable.scss'

const FilesTable = ({
  files = [],
  user,
  title,
  areSomeFilesAdded,
  uploadConfig,
  onRemoveFile
}) => {
  const sortedFiles = useMemo(() => sortImagesWithTextFile(files), [files])

  return (
    <table className="files-table table-simplified">
      <thead className="fixed">
        <tr>
          <th className="cell-file">Archivo</th>
          <th className="cell-size">Tamaño</th>
          <th className="cell-remove">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <Notifier
          files={ files }
          user={ user }
          title={ title }
          areSomeFilesAdded={ areSomeFilesAdded }
          uploadConfig={ uploadConfig }
        />
        { sortedFiles.map((file) => (
          <FileRow
            key={ file.name}
            file={ file }
            onRemoveFile={ onRemoveFile }
          />
        )) }
      </tbody>
    </table>
  )
}

FilesTable.propTypes = {
  files: PropTypes.array,
  user: PropTypes.object,
  title: PropTypes.string,
  areSomeFilesAdded: PropTypes.bool,
  uploadConfig: PropTypes.object,
  onRemoveFile: PropTypes.func
}

export default FilesTable
