import { useState, useEffect } from 'react'
import { isANumber } from 'lib'

const isInvalid = (index, images) =>
  !isANumber(index) || index < 0 || index > images.length


function useImageKey ({ images = [], index, keyField, keyFallback, skip }) {
  const [image, setImage] = useState(null)
  const [imageKey, setImageKey] = useState('')

  const isIndexInvalid = isInvalid(index, images)

  useEffect(() => {
    if (isIndexInvalid || skip) return
    setImage(images[index])
  }, [images, index, isIndexInvalid, skip])

  useEffect(() => {
    if (isIndexInvalid || skip) return
    if (!image) return

    if (image[keyField]) {
      setImageKey(image[keyField])
      return
    }

    if (image[keyFallback]) {
      setImageKey(image[keyFallback])
    }
  }, [image, setImageKey, keyField, keyFallback, isIndexInvalid, skip])

  return {
    image,
    imageKey
  }
}

export default useImageKey
