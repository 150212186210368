import { useMutation } from '@apollo/client'

import {
  mutation,
  input,
} from 'infra/inspection/reassignInspection.mutation'

function useReassignInspection ({ onCompleted, onError }) {
  const [reassignInspection, { loading }] = useMutation(mutation, {
    onCompleted (response) {
      onCompleted?.(response)
    },
    onError (error) {
      console.error(error)
      onError?.()
    }
  })

  return {
    loading,
    launch: (input) => {
      reassignInspection ({
        variables: { input }
      })
    }
  }
}

export default useReassignInspection
export { input as ReassignInspectionInput }
