import PropTypes from 'prop-types'
import SkeletonTable from 'ui/elements/Table/SkeletonTable/SkeletonTable'
import Inspection from '../Inspection/Inspection'
import './Inspections.scss'

const handlerCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text)
}

const Inspections = ({ inspections, path, isLoading }) => (
  <table className="inspections-list table-simplified">
    <thead className="inspections-list__header">
      <tr>
        <th className="inspections-list__header-date text-left nowrap">Fecha / Hora</th>
        <th className="inspections-list__header-title text-left">Título y Descripción</th>
        <th>Asignado a</th>
        <th className="text-center">Análisis de IA</th>
        <th className="text-center">Inspección</th>
      </tr>
    </thead>
    <tbody>
      { inspections.map((inspection, index) => (
        <Inspection
          key={ `inspection-${index}-${inspection.id}` }
          path={ path }
          inspection={ inspection }
          totalDetections={ inspection?.detections?.totalCount }
          onClick={ handlerCopyToClipboard }
        />
      ))}

      { isLoading && <SkeletonTable numberOfCells={ 5 } /> }
    </tbody>
  </table>
)

Inspections.propTypes = {
  inspections: PropTypes.array,
  path: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default Inspections
