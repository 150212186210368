import PropTypes from 'prop-types'
import Button from 'ui/elements/Button/Button'
import { FILES, filterFiles } from './Files'

const DownloadButton = ({ href, text, isLegacy }) => {
  if (!href) return null

  return (
    <Button
      className={ isLegacy ? '': 'button-clean --regular' }
      variant="secondary"
      href={ href }
      target="_blank"
      role="download"
      rel="noreferrer"
    >{ text }</Button>
  )
}

DownloadButton.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
}

const DownloadFiles = ({ files, isLegacy = true }) => (<>
  { FILES.map(({ key, text }) => {
    const [file] = filterFiles(files, key)

    return (
      <DownloadButton
        key={ key }
        text={ text }
        href={ file?.value }
        isLegacy={ isLegacy }
      />
    )}
  )}
</>)

DownloadFiles.propTypes = {
  files: PropTypes.array,
  isLegacy: PropTypes.bool,
}

export default DownloadFiles
