import PropTypes from 'prop-types'
import { useContext } from 'react'

import SplitPanelContext, { PANEL_STATUS } from 'adapter/context/SplitPanelContext'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'

import Button from 'ui/elements/Button/Button'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import Dot, { getStatusLabel } from 'ui/elements/Dot/Dot'

import { addNameField } from 'infra'
import Dropdowns from '../Dropdowns/Dropdowns'
import { Header } from '.'
import { TYPES } from 'domain/detection'

const ViewDetectionForm = ({ detection, onClose, children }) => {
  const { setCropStatus } = useContext(CropContext)
  const { setActivePanel } = useContext(SplitPanelContext)
  const { anomaly, type } = detection
  const isAnomaly = type === TYPES.ANOMALY
  const parentCategory = isAnomaly ? anomaly?.parentCategory : detection?.parentCategory
  const asset = detection?.asset || detection?.validation?.asset
  const severity = isAnomaly ? addNameField(detection?.severity || detection?.validation?.severity, ({ value }) => value) || null : null
  const category = anomaly?.category

  const inputPrecharged = { anomaly: { name: anomaly.name }, category, severity }
  const validationStatus = detection?.validation?.status

  const returnToView = () => {
    setCropStatus(CROP_STATUS.LOADED)
    setActivePanel(PANEL_STATUS.SPLIT_VIEW)
  }

  const handleCancel = () => {
    returnToView()
    onClose()
  }

  return (<>
    <form className="form-detection">
      <Header type={type} />
      { children }
      { parentCategory && (
        <Dropdown
          label="Tipo de objeto"
          itemSelected={ parentCategory }
          selectable={ false }
        />
      )}
      { asset && (
        <Dropdown
          label="Matrícula"
          itemSelected={ asset }
          selectable={ false }
          uppercase
        />
      )}
      <Dropdowns inputPrecharged={ inputPrecharged } isAnomaly={isAnomaly} />
      <fieldset className="anomaly-form__block">
        <label>Estado</label>
        <p><Dot statusId={ validationStatus }/>{ getStatusLabel(validationStatus, { capitalize: true }) }</p>
      </fieldset>
    </form>
    <Button
      variant="none"
      className="anomaly-form__cancel button-clean --failure"
      onClick={ handleCancel }
    >Cerrar defecto</Button>
  </>)
}

ViewDetectionForm.propTypes = {
  detection: PropTypes.object,
  onClose: PropTypes.func,
  children: PropTypes.node,
}
export default ViewDetectionForm
