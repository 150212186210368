import { useQuery } from '@apollo/client'
import {
  CATEGORIES_QUERY_ONLY_HIERARCHY,
  field
} from 'infra/categories/categories.query'

function useGetCategoryFilteredByName ({ categoryName }) {
  const { data, loading, error, refetch } = useQuery(CATEGORIES_QUERY_ONLY_HIERARCHY, {
    variables: { hierarchy: true }
  })
  const categories = data?.[field] || []
  const category = categories.find(({ name }) =>
    name.localeCompare(categoryName) === 0)

  return {
    data: category || null,
    loading,
    error,
    refetch
  }
}

export default useGetCategoryFilteredByName
