import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { getErrorMessage } from 'lib/types'
import useAddClasses from 'hooks/dom/useAddClasses'
import {
  VALIDATION_STATUS as STATUS,
  VALIDATION_TEXTS as TEXTS
} from 'domain/detection'
import './Dot.scss'

export const DOT_TYPES = Object.freeze({
  [STATUS.PENDING]: { id: STATUS.PENDING, label: TEXTS.PENDING },
  [STATUS.CORRECT]: { id: STATUS.CORRECT, label: TEXTS.CORRECT },
  [STATUS.INCORRECT]: { id: STATUS.INCORRECT, label: TEXTS.INCORRECT }
})

const DOT_TYPES_VALUES = Object.values(DOT_TYPES).map(({ id }) => id)

export const isStatusInRange = (statusId) => {
  return (!statusId || DOT_TYPES_VALUES.includes(statusId))
}

export const getStatusLabel = (statusId, { capitalize } = { capitalize: false }) => {
  if (!isStatusInRange(statusId)) return null
  let label = DOT_TYPES[statusId]?.label || DOT_TYPES.PENDING.label
  if (capitalize) {
    label = label[0].toUpperCase() + label.substring(1)
  }
  return label
}

const Dot = ({
  statusId,
  withBorder = false,
  onMouseEnter,
  onMouseLeave,
  onClick = null,
  isBig = false,
  children,
  className = ''
}) => {
  const [status, setStatus] = useState(statusId)

  useEffect(() => {
    setStatus(statusId)
  }, [statusId])

  const classNames = useAddClasses(['dot', className], [
    withBorder && 'with-border',
    isBig && 'is-big',
    status ? status.toLowerCase() : DOT_TYPES.PENDING.id.toLowerCase(),
    onClick !== null && 'clickable'
  ])

  if (!isStatusInRange(status)) return null

  return (
    <span
      className={ classNames }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
      onClick={ onClick }
    >{ children }</span>
  )
}

Dot.propTypes = {
  statusId: function (props, propName, componentName) {
    const isValid = isStatusInRange(props[propName])
    if (!isValid) return new Error(getErrorMessage(propName, componentName))
    return null
  },
  withBorder: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  isBig: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Dot
