import { SKELETON_TEXT } from 'lib'
import { VALIDATION_STATUS as STATUS } from 'domain/detection'

const getStatusLabel = (val) => (
  val === STATUS.CORRECT ? "Correcto" :
    val === STATUS.INCORRECT ? "Incorrecto" :
      val === STATUS.DOUBT ? "En duda" : SKELETON_TEXT
)

const ValidationTooltip = (users, status) => {
  if (!users || users.length === 0) return (
    <p>Nadie ha calificado
      <br />el <b>conjunto</b> como
      <br /><i className={ status }>{ getStatusLabel(status) }</i>
    </p>
  )

  return (
    <>
      <p>Han calificado el <b>conjunto</b>
        <br />como <i className={ status }>{ getStatusLabel(status) }</i>:
      </p>
      <ul>
        { users.map((user, index) => <li key={`user-${index}`}>{ user }</li>) }
      </ul>
    </>
  )
}

export default ValidationTooltip
