import { SphericalUtil } from "node-geometry-library"
import {
  convertMapToArray,
  arraysAreSymmetric,
  sortArrayByFields
} from "lib/types/arrays"

const DISTANCE_THRESHOLD_IN_METERS = 3

const computeDistanceBetween = SphericalUtil.computeDistanceBetween

const parseMarker = (marker) => {
  const { latitude, longitude } = marker
  return { lat: latitude, lng: longitude }
}

const calculateDistanceBetween = (marker1, marker2) =>
  computeDistanceBetween(parseMarker(marker1), parseMarker(marker2))

const markersAreClosed = (marker1, marker2, distanceThreeshold) => {
  const distanceBetween = calculateDistanceBetween(marker1, marker2)
  return distanceBetween < distanceThreeshold
}

const removeGroupsDuplicated = (groups, identifier) => {
  const groupsCleaned = new Map()

  groups.forEach((group, i) => {
    const previousGroup = groups[(i + groups.length - 1)%groups.length]
    const previousSorted = sortArrayByFields(previousGroup, [identifier])
    const currentSorted = sortArrayByFields(group, [identifier])
    const areEqual = arraysAreSymmetric(previousSorted, currentSorted)
    if (!areEqual) return
    if (groupsCleaned.has(currentSorted)) return
    groupsCleaned.set(currentSorted[0][identifier], group)
  })
  return convertMapToArray(groupsCleaned)
}

const findNearbyMarkersInGroups = ({
  markers,
  distanceThreeshold = DISTANCE_THRESHOLD_IN_METERS,
  groupBy = ''
} = { markers: [] }) => {
  const closestGroups = []
  if (!markers?.length > 0) return closestGroups

  markers.forEach((marker, i) => {
    const group = [marker]

    markers.forEach((siblingMarker, j) => {
      if (i === j) return
      if (!markersAreClosed(marker, siblingMarker, distanceThreeshold)) return
      group.push(siblingMarker)
    })
    if (group.length > 1) {
      closestGroups.push(group)
    }
  })

  return removeGroupsDuplicated(closestGroups, groupBy)
}

export default findNearbyMarkersInGroups
