import PropTypes from 'prop-types'
import './DetectionsEmpty.scss'

const DetectionsEmpty = ({ numberOfCells }) => (
  <tr className="detections-empty">
    <td colSpan={ numberOfCells }>
      <h1>Sin defectos detectados</h1>
      <p>Por el momento no se han registrado defectos en esta inspección</p>
    </td>
  </tr>
)

DetectionsEmpty.propTypes = {
  numberOfCells: PropTypes.number.isRequired,
}

export default DetectionsEmpty
