import { NavLink } from 'react-router-dom'
import Button from 'ui/elements/Button/Button'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import NewInspectionButton from 'ui/pages/NewInspection/elements/Button/NewInspectionButton'
import { PATH as dashboardPath } from 'ui/pages/Dashboard/Dashboard'
import { PATH as collectionPath } from 'ui/pages/Collection/Collection'
import useLogout from 'hooks/auth/useLogout'
import './Menu.scss'

export const Actions = ({ username, isMultipleCamera }) => {
  const logout = useLogout()

  return (
    <div className="actions">

      { isMultipleCamera && (
        <NewInspectionButton
          className="actions__new-inspection"
        >Nueva inspección</NewInspectionButton>
      )}

      <Tooltip label={ username } direction="down">
        <img
          className="actions__user"
          src='/assets/images/mis/icon-user.svg'
          alt="usuario"
        />
      </Tooltip>

      <Button
        className="actions__item"
        onClick={ logout }
        variant="menu"
      >
        Salir
      </Button>
    </div>
  )
}

export const CollectionLink = ({ className }) => (
  <NavLink
    className={ className }
    activeClassName="active"
    to={ `/${collectionPath}` }
  >
    Revisiones
  </NavLink>
)

export const HomeLink = ({ className }) => (
  <NavLink
    className={ className }
    activeClassName="active"
    to={ `/${dashboardPath}` }
  >
    Dashboard
  </NavLink>
)
