import { gql } from '@apollo/client'

export const field = 'reviewDetection'

export const REVIEW_DETECTION_MUTATION = gql`
  mutation reviewDetection($input: ReviewDetectionInput!) {
    reviewDetection(input: $input) {
      id
      detectionId
      categoryStatus
      anomalyTypeStatus
      observations
      reviewedAt
      userId
    }
  }
`
