const messages = {
  debug: 'Subida de un vídeo.',
  addFile: 'Añadir video',
  empty: {
    title: 'Sin archivo de vídeo',
    description: `Para realizar una inspección es necesario incluir
      un video en cualquiera de estos formatos: mp4, avi, mkv, wmv, mpg o mpeg.
    `,
  },
  noMinFiles: 'Incluye un vídeo en cualquiera de estos formatos: mp4, avi, mkv, wmv, mpg o mpeg.',
  noMaxFiles: 'Inserta un sólo vídeo.',
}

const getMessage = (files) => {
  if (files.length === 0) { return messages.noMinFiles }
  if (files.length > 1) { return messages.noMaxFiles }
  return ''
}

export {
  messages,
  getMessage
}
