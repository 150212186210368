import Uppy from '@uppy/core'
import { useUppy } from '@uppy/react'
import TusUnusuals from 'ui/containers/Uploader/TusUnusuals'
import UPPY_CONFIG from 'ui/pages/NewInspection/vendor/Uppy/UppyConfig'
import TUS_CONFIG from 'ui/pages/NewInspection/vendor/Tus'
import getHeaders from '../Headers/getHeaders'

const { REACT_APP_API_URL, REACT_APP_TUS_PATH } = process.env
const endpoint = `${REACT_APP_API_URL}${REACT_APP_TUS_PATH}`

const useUploader = ({
  authToken: token,
  fileTypesSupported,
  allowMultipleUploads = true,
  maxNumberOfFiles,
}) => (
  useUppy(() => (
    new Uppy({
      ...UPPY_CONFIG,
      allowMultipleUploads,
      restrictions: {
        allowedFileTypes: fileTypesSupported,
        minNumberOfFiles: 1,
        maxNumberOfFiles,
        minFileSize: 0,
      }
    }).use(TusUnusuals, {
      endpoint,
      headers: getHeaders ({ token }),
      ...TUS_CONFIG
    })
  ))
)

export default useUploader
