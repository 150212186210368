const ERROR = Object.freeze({
  NOT_GEOLOCATED: 'notGeolocated',
  FAR_FROM_ASSET: 'farFromAsset',
  CORRUPT: 'corrupted'
})

const ERROR_TEXTS = Object.freeze({
  [ERROR.NOT_GEOLOCATED]: 'Sin geolocalización',
  [ERROR.FAR_FROM_ASSET]: 'Ubicación demasiada alejada de los activos',
  [ERROR.CORRUPT]: 'Dañada'
})

function getErrorMessageWithFile (status) {
  if (Object.values(ERROR).includes(status)) return ERROR_TEXTS[status]
  return ''
}

export {
  ERROR_TEXTS as FILE_ERROR_TEXTS,
  getErrorMessageWithFile,
}
