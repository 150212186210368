import PropTypes from 'prop-types'
import { memo, useMemo, useState, useEffect } from 'react'
import SimpleModal from 'ui/components/SimpleModal/SimpleModal'
import useConfirmButton from 'hooks/dom/useConfirmButton'
import { showUploadCancelled } from '../Messages/Messages'
import { isContinueable } from 'domain/inspection/errors/Errors'
import ErrorPanel from './Errors/ErrorPanel/ErrorPanel'
import './CheckGeolocationFile.scss'

const PROCESSING_FILES = `Estamos comprobando que los datos están geolocalizados
    correctamente. Este proceso puede tardar unos segundos. Se paciente.`

const CheckGeolocationFile = ({ files, loading, checkErrors, errorWithQuery, isOpen, onContinue, onCancel }) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const isStepContinueable = useMemo(() => isContinueable(checkErrors), [checkErrors])

  const {
    handleConfirm: handleCancel,
    confirmText: confirmTextOfCancel
  } = useConfirmButton({
    label: isStepContinueable ? 'Cancelar' : 'Cancelar nueva inspección',
    callbackToAction: () => {
      onCancel(files)
      showUploadCancelled()
    },
    secondsToConfirm: 10
  })

  const {
    handleConfirm: handleContinue,
    confirmText: confirmTextOfContinue
  } = useConfirmButton({
    label: 'Continuar',
    callbackToAction: () => {
      setIsCompleted(true)
      onContinue()
    },
    secondsToConfirm: 10
  })

  const isReadyToBeCompleted = useMemo(() =>
    checkErrors?.length === 0 && !loading && !errorWithQuery
  , [checkErrors, loading, errorWithQuery])

  useEffect(() => {
    if (isCompleted) return
    if (!isReadyToBeCompleted) return
    setIsCompleted(true)
    // onContinue()
  }, [isReadyToBeCompleted, onContinue, isCompleted, setIsCompleted])

  if (errorWithQuery) {
    console.error(errorWithQuery)
    return null
  }

  return (<>
    { !isCompleted && (
      <SimpleModal
        isOpen={ isOpen && !isCompleted }
        className="check-geolocation-modal"
        actions={
          <section className="upload-modal__actions">

            <button
              className={ `button-clean --failure ${!isStepContinueable && '--centered' }` }
              onClick={ handleCancel }
            >{ confirmTextOfCancel }</button>

            { isStepContinueable && (
              <button
                className="button-clean --success"
                onClick={ handleContinue }
              >{ confirmTextOfContinue }</button>
            )}
          </section>
        }
      >
        { (loading && !isCompleted) && <p>{ PROCESSING_FILES }</p> }
        { (checkErrors?.length > 0) && <ErrorPanel errors={ checkErrors } />}
      </SimpleModal>
    )}
    { isCompleted && <></> }
  </>)
}

CheckGeolocationFile.propTypes = {
  files: PropTypes.array,
  loading: PropTypes.bool,
  checkErrors: PropTypes.array,
  errorWithQuery: PropTypes.object,
  isOpen: PropTypes.bool,
  onContinue: PropTypes.func,
  onCancel: PropTypes.func
}

export default memo(CheckGeolocationFile)
