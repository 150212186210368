import PropTypes from 'prop-types'
import { print, trimUnderscore, SKELETON_TEXT } from 'lib'
import Dates from 'ui/elements/Dates/Dates'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Dot, { getStatusLabel } from 'ui/elements/Dot/Dot'
import Thumbnail from 'ui/components/Thumbnail/Thumbnail'
import './Detection.scss'

function Detection ({
  order,
  date,
  onClick,
  detection: {
    anomaly,
    severity,
    validation,
    crop,
  },
  isMultipleLevelCatalog,
}) {
  return (
    <tr className="detection">
      <td className="index text-center" data-testid="index">{ order }</td>

      <td>
        { (validation?.validateAt || date) ? (
          <Dates
            className="text-left"
            isoDate={ validation?.validatedAt || date }
          />
        ) : SKELETON_TEXT }
      </td>

      { isMultipleLevelCatalog && (
        <td className={ anomaly?.parentCategory?.name ? '': 'text-center' }
        >{ print(anomaly?.parentCategory?.name) }</td>
      )}

      <td className="white-space-normal">
        <dl>
          <dt>{ trimUnderscore(print(anomaly?.category?.name)) }</dt>
          { isMultipleLevelCatalog && (
            <dd>{ anomaly?.category?.externalReference || SKELETON_TEXT }</dd>
          )}
        </dl>
      </td>

      <td className="white-space-normal">{ print(anomaly?.name) }</td>

      { isMultipleLevelCatalog && (
        <td className="text-center white-space-normal">{ print(severity?.value) }</td>
      )}

      <td className="validation text-center">
        <Tooltip label={ getStatusLabel(validation?.status) }>
          <Dot statusId={ validation?.status } isBig />
        </Tooltip>
      </td>

      <td className="screenshot text-center">
        <Thumbnail
          className="screenshot__thumbnail"
          urlKey={ crop?.smallKey }
          type={ anomaly?.type?.name }
          withHover
          withFallback
          onClick={ onClick }
        />
      </td>
    </tr>
  )
}

Detection.propTypes = {
  index: PropTypes.number,
  date: PropTypes.string,
  onClick: PropTypes.func,
  detection: PropTypes.object,
  isMultipleLevelCatalog: PropTypes.bool,
}

export default Detection
