import { isString, isArray, isObject } from '../types'
import { capitalize } from '../text'

const validators = {
  string: isString,
  array: isArray,
  object: isObject
}

const required = 'isRequired'
const separator = '.'
const requiredField = separator.concat(required)

const hasError = (output) => {
  const error = output instanceof Error
  if (error) return output
  return false
}

const is = (type, value) => {
  const getErrorMessage = (type, value) =>
    new Error(`['is'] Invalid value "${value}" for the type: ${capitalize(type)}.`)

  if (!type || !value) return false

  const typeParsed = type.replace(requiredField, '')
  const hasValidator = typeParsed in validators
  if (!hasValidator) return getErrorMessage(typeParsed, value)

  const isValidType = validators[typeParsed](value)
  if (!isValidType) return getErrorMessage(typeParsed, value)

  return true
}

const isRequired = ([key, value], inputValue) => {
  if (!value.includes(requiredField)) return true
  if (!inputValue) return new Error(`[checkRequired] The field "${key}" is required.`)
  return true
}

const all = (collection, callback) => {
  const areValid = Object.entries(collection).every((item) => {
    const output = callback(item)
    if (hasError(output)) throw output
    return true
  })
  return areValid
}

const checkRequired = (shape, input) =>
  all(shape, (item) => {
    const [key] = item
    return isRequired(item, input[key])
  })

const checkParams = (shape, input) => {
  const getErrorMessage = (param) => new Error(`[CreateMutationInput] The ${param} must be an object.`)
  if (!isObject(shape)) throw getErrorMessage('shape')
  if (!isObject(input)) throw getErrorMessage('input')
}

const checkTypes = (shape, input) => {
  all(shape, (item) => {
    const [key, type] = item
    const value = input[key]
    return is(type, value)
  })
}

const checkInput = (shape, input) => {
  try {
    [ checkParams,
      checkTypes,
      checkRequired
    ].forEach((check) => check(shape, input))
    return true
  } catch (error) {
    console.error(error)
    return null
  }
}

const CreateMutationInput = (shape) => ({
  create: (input) => (checkInput(shape, input) && input) || null
})

export default CreateMutationInput

