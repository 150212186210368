import PropTypes from 'prop-types'
import { useState } from 'react'
import { formatBytes, SKELETON_TEXT } from 'lib'
import Button from 'ui/elements/Button/Button'
import SimpleModal from 'ui/components/SimpleModal/SimpleModal'
import { isImageFile, isVideoFile, TYPE_VIDEO } from 'domain/files/types/types'
import ImageViewer from './viewers/ImageViewer'
import VideoViewer from './viewers/VideoViewer'
import './FileModal.scss'

const FileModal = ({ file, isOpen, onClose }) => {
  const isImage = isImageFile(file)
  const isVideo = isVideoFile(file, { field: TYPE_VIDEO.alternative })
  const [isSkeleton, setIsSkeleton] = useState(true)

  const name = file?.name || SKELETON_TEXT
  const size = formatBytes(file?.size) || SKELETON_TEXT

  const handleClose = () => {
    setIsSkeleton(true)
    onClose()
  }

  return (
    <SimpleModal
      className="file-modal"
      isOpen={ isOpen }
      onRequestClose={ handleClose }
      actions={
        <button
          className="simple-modal__close button-clean --regular"
          variant="secondary"
          onClick={ handleClose }
        >Cerrar</button>
      }
    >
      <header className="file-modal__header">
        <section>
          <p className="file-modal__title">{ name }</p>
          <p className="file-modal__size">{ size }</p>
        </section>
        <Button
          href={ file?.url }
          variant="secondary"
          className="button-clean --regular"
        >Descargar</Button>
      </header>
      <section className="file-modal__content">
        { isImage && (
          <ImageViewer
            src={ file?.url }
            skeleton={{ isSkeleton, setIsSkeleton }}
          />
        )}

        { isVideo && <VideoViewer src={ file?.url } /> }
      </section>
    </SimpleModal>
  )
}

FileModal.propTypes = {
  file: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default FileModal
