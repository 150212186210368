const isUpperCase = (string) => /^[A-Z]*$/.test(string)

const split = (string) => {
  return (string && string.split(/(?=[A-Z])/)) || []
}

export default function useAddClasses (initial = []) {
  const secondArgument = arguments[1]
  if (!secondArgument) return initial
  if (!Array.isArray(secondArgument)) {
    console.error('[`useAddClasses` hook]: Second argument must be an array.')
    return initial
  }
  const classes = [...initial]
  secondArgument.forEach(className => {
    if (!className) return
    const splitted = isUpperCase(className) ? split(className) : [className]
    let classToAdd = ''
    if (splitted.length < 2) {
      classToAdd = `--${splitted[0]}`
    } else {
      classToAdd = `--${splitted[0]}-${splitted[1].toLowerCase()}`
    }
    classes.push(classToAdd)
  })
  return classes.join(' ')
}
