import PropTypes from 'prop-types'
import { useState, useRef, useContext, useEffect, useCallback } from 'react'
import Spinner from 'ui/elements/Spinner/Spinner'
import Draw from 'ui/components/Draw/Draw'
import useDrawLabel from 'ui/components/Draw/DrawHooks/useDrawLabel/useDrawLabel'
import SettingsContext from 'adapter/context/SettingsContext'
import SplitPanelContext, { PANEL_STATUS } from 'adapter/context/SplitPanelContext'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import { filterDetections, filterDetectionsWithoutRoi } from 'domain/detection'
import ModalDetectionFilterModal from 'ui/pages/GeoDetail/PhotoViewer/Modal/DetectionFilterModal.js'
import useImageKeys,{ IMAGE_KEY }  from './Hooks/imageKeys/useImageKeys'
import FORM_TYPES from '../AnomalyViewer/Form/types'
import getDetectionForm from '../AnomalyViewer/Form/types/getDetectionForm/getDetectionForm'
import RoisLayer from './RoisLayer/RoisLayer'
import PhotoActions from './Actions/PhotoActions'
import './PhotoViewer.scss'
import DetectionsContext from 'adapter/context/DetectionsContext'
import DetectionsWithoutRoiTooltip from './DetectionsWithoutRoiTooltip/DetectionsWithoutRoiTooltip'

const PhotoViewer = ({
  images,
  detections = [],
  activePointIndex,
  isPressed,
  username,
  isAssignedUser,
  loadingRefetch,
  refetchInspection,
  onLaunchForm,
}) => {
  const { setActivePanel } = useContext(SplitPanelContext)
  const { typeDetection, categoriesSelected } = useContext(DetectionsContext)
  const { cropStatus, setCropStatus, isAllowedToDraw } = useContext(CropContext)
  const settings = useContext(SettingsContext)

  const { multipleLevelCatalog } = settings || {}

  const [isLoading, setIsLoading] = useState(true)
  const [isModalDetectionFilter, setModalDetectionFilter] = useState(false)
  const [isProgressiveLoaded, setIsProgressiveLoaded] = useState(false)
  const [isOriginalLoaded, setIsOriginalLoaded] = useState(false)

  const container = useRef(null)

  const {
    image,
    originalImageKey,
    progressiveImageKey,
  } = useImageKeys({ images, activePointIndex, skip: isPressed })

  const debugLabel = useDrawLabel ({
    isLoading,
    isProgressiveLoaded,
    isOriginalLoaded
  })

  useEffect(() => {
    setIsLoading(true)
    setIsOriginalLoaded(false)
    setActivePanel(PANEL_STATUS.SPLIT_VIEW)

    return (() => { setIsLoading(false) })
  }, [
    originalImageKey,
    setIsLoading,
    loadingRefetch,
    setActivePanel,
    activePointIndex
  ])

  useEffect(() => {
    return () => {
      setActivePanel(PANEL_STATUS.SPLIT_VIEW)
    }
  }, [setActivePanel])

  const detectionsFiltered = filterDetections(detections, typeDetection, categoriesSelected)
  const detectionsWithoutRoi = filterDetectionsWithoutRoi(detectionsFiltered);

  const handleOpenDetection = useCallback((detection) => {
    const detectionForm = getDetectionForm({
      isAssignedUser,
      currentDetection: detection,
      detectionsToSearch: detectionsFiltered,
    }, IMAGE_KEY.ORIGINAL)

    onLaunchForm(detectionForm)
  }, [isAssignedUser, detectionsFiltered, onLaunchForm])

  const handleCropDrawn = useCallback((url) => {
    const typeForm = multipleLevelCatalog ? FORM_TYPES.CREATE_DETECTION : FORM_TYPES.CREATE_DETECTION_LIGHT
    const detectionForm = {
      url,
      typeForm
    }
    onLaunchForm(detectionForm)
  }, [onLaunchForm, multipleLevelCatalog])

  const areRoisVisibles = !isLoading && !isAllowedToDraw

  const areDetectionsWithoutRoi = !!detectionsWithoutRoi.length

  return (
    <article className="photo-viewer">

      <button
        className="filter__btn --eye"
        disabled={ isLoading }
        onClick={ () => setModalDetectionFilter(true) }
      >
        <span className="icon--eye"></span>
      </button>
      {
        areDetectionsWithoutRoi && (
          <button
            className="filter__btn --alert"
            disabled={ isLoading }
          >
            <span className="icon--alert"></span>

            <DetectionsWithoutRoiTooltip
              detections = { detectionsWithoutRoi }
            />
          </button>

        )
      }

      <section className="photo-viewer__inner">
        { isLoading && <Spinner className="loading" /> }

        { cropStatus !== CROP_STATUS.CROPPED && (
          <PhotoActions
            activePointIndex={ activePointIndex }
            image={ image }
            detections={ detectionsFiltered }
            isLoading={ isLoading }
            isOriginalLoaded={ isOriginalLoaded }
            username={ username }
            isAssignedUser={ isAssignedUser }
            label={ debugLabel }
            refetchInspection={ refetchInspection }
          />
        )}
        <Draw
          activePointIndex={ activePointIndex }
          originalImageKey={ originalImageKey }
          progressiveImageKey={ progressiveImageKey }
          container={ container }
          isLoading={ isLoading }
          isAssignedUser={ isAssignedUser }
          onCrop={ handleCropDrawn }
          refetchInspection={ refetchInspection }
          onProgressiveLoad={ () => {
            setCropStatus(CROP_STATUS.LOADED)
            setIsProgressiveLoaded(true)
            setIsLoading(false)
          }}
          onOriginalLoad={ () => {
            setIsOriginalLoaded(true)
            setIsLoading(false)
          }}
        >
          { areRoisVisibles && (
            <RoisLayer
              detections={ detectionsFiltered }
              width={ image?.width }
              height={ image?.height }
              onOpenRoi={ handleOpenDetection }
            />
          )}
        </Draw>
      </section>
      <ModalDetectionFilterModal
        isOpen={ isModalDetectionFilter }
        setIsOpen={ setModalDetectionFilter }
      />
    </article>
  )
}

PhotoViewer.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  detections: PropTypes.arrayOf(PropTypes.object),
  activePointIndex: PropTypes.number,
  isPressed: PropTypes.bool,
  username: PropTypes.string,
  isAssignedUser: PropTypes.bool,
  loadingRefetch: PropTypes.bool,
  refetchInspection: PropTypes.func,
  onLaunchForm: PropTypes.func,
}

export default PhotoViewer
