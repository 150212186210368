import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Radial from 'ui/elements/Radial/Radial'
import { INSPECTION_STATUS } from 'domain/inspection'

const textTooltipProgress = (progress) => (
  <p>
    Análisis al <b>{ progress || 0 }%</b>.
  </p>
)

const textTooltipFail = () => (
  <p>
    La inspección <b>no</b> ha podido <br />
    realizarse con <b>éxito</b>.
  </p>
)

const textTooltipPending = () => (
  <p>
    Creando <b>entorno</b> para <br />
    realizar el <b>análisis</b>.
  </p>
)

const RadialStatus = ({ status, progress }) => {
  return (
    <Tooltip
      label={
        status === INSPECTION_STATUS.ERROR
          ? textTooltipFail()
          : status === INSPECTION_STATUS.PENDING
            ? textTooltipPending()
            : textTooltipProgress(progress)
      }
    >
      <Radial
        progress={ progress }
        status={ status }
        disabled={ status === INSPECTION_STATUS.ERROR }
      />
    </Tooltip>
  )
}

export default RadialStatus
