import { gql } from '@apollo/client'

export const VALIDATION = gql`
  fragment ValidationFragment on Detection {
    validation {
      id
      detectionId
      status
      categoryStatus
      anomalyTypeStatus
      observations
      validatedAt
      user  {
        id
        username
      }
      severity {
        id
        value
      }
      asset {
        id
        externalReference
        name
      }
    }
  }
`
