import PropTypes from 'prop-types'
import { handler, DATE_FIELDS } from './datesOptions'
import './Dates.scss'

const Dates = ({ isoDate, className }) => {
  if (!isoDate) return null
  const date = new Date(isoDate)
  const dateProxied = new Proxy(date, handler)

  return (
    <section className={ `date ${className}` }>
      <strong className="date__value">{ dateProxied[DATE_FIELDS.DATE] }</strong>
      <span className="date__info">
        { dateProxied[DATE_FIELDS.TIME] } ({ dateProxied[DATE_FIELDS.ZONE] })
      </span>
    </section>
  )
}

Dates.propTypes = {
  isoDate: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Dates
