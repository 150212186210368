import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import Button from 'ui/elements/Button/Button'
import Wrapper from 'ui/elements/Wrapper/Wrapper'
import InspectionsList from 'ui/components/InspectionsList/InspectionsList'
import Pagination from 'ui/components/Pagination/Pagination'

import SettingsContext from 'adapter/context/SettingsContext'
import useCreateInspection from 'hooks/models/inspection/useCreateInspection'
import useGetInspections from 'hooks/models/inspections/useGetInspections'

import NewInspectionButton from 'ui/pages/NewInspection/elements/Button/NewInspectionButton'

import NewInspectionButtonLegacy, {
  launch as launchNewInspectionModalLegacy
} from '../NewInspectionButton/NewInspectionButton'
import './Catalog.scss'

const ErrorMessage = () => (
  <Wrapper className="catalog">
    <p className="catalog__error">
      Ha ocurrido un error recuperando las inspecciones.
    </p>
  </Wrapper>
)

const EmptyMessage = ({ buttonComponent: ButtonComponent, ...rest }) => (
  <p className="catalog__empty">Incluye una
    <ButtonComponent {...rest }>nueva inspección</ButtonComponent>
    con el fin de realizar un análisis.
  </p>
)

const Catalog = ({ refetchInspections = false, refetched }) => {
  const settings = useContext(SettingsContext)
  const { cleanLayout } = settings || {}

  const {
    data, loading, error,
    fetchMore, refetch
  } = useGetInspections()

  useEffect(() => {
    refetchInspections && refetch()
    refetched?.()
  }, [refetchInspections, refetch, refetched])

  const { launch: createInspection } = useCreateInspection({
    onCompleted: refetch,
  })

  if (error) {
    console.error(error)
    return <ErrorMessage />
  }
  if (!data?.edges && !loading) {
    return <ErrorMessage />
  }

  const inspections = data?.edges.map((edge) => edge.node)
  const pageInfo = data?.pageInfo
  const areThereInspections = inspections?.length > 0

  return (
    <Wrapper className={ `catalog ${cleanLayout ? '--is-simplified': '' }` }>

      <InspectionsList
        inspections={ inspections || [] }
        isLoading={ loading }
      />

      <Pagination
        isSimplified
        disabled={ pageInfo?.hasNextPage ? false : true }
        buttonClassName={ (cleanLayout && 'button-clean --regular') || '' }
        onLoadMore={ () => {
          fetchMore({
            variables: {
              cursor: pageInfo.endCursor
            }
          })
        }}
      />

      { (!areThereInspections && !loading) && (<>

          { !cleanLayout ?
            <EmptyMessage buttonComponent={ NewInspectionButton } />:
            <EmptyMessage
              buttonComponent={ Button }
              variant="link"
              onClick={ launchNewInspectionModalLegacy }
            />
          }
        </>
      )}

      { /* Positionated in header magically */ }
      { !cleanLayout && (
        <NewInspectionButtonLegacy
          onDone={ createInspection }
          wrapperSelector=".header__actions"
          label="Nueva inspección"
          variant="primary"
        />
      )}

    </Wrapper>
  )
}

Catalog.propTypes = {
  refetchInspections: PropTypes.bool,
  refetched: PropTypes.func
}

export default Catalog
