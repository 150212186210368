import PropTypes from 'prop-types'
import { useState } from 'react'
import { getErrorMessage } from 'lib/types'
import Rois from 'ui/components/Rois/Rois'
import PhotoTooltip from '../Tooltip/Tooltip'

const RoisLayer = ({ width, height, detections, onOpenRoi }) => {
  const [roiEntered, setRoiEntered] = useState(null)

  if (!detections || detections.length === 0) return null

  return (<>
    { detections.map((detection, index) => {
      const rois = detection?.rois
      if (!rois || !rois[0]) return null
      const [roi] = rois

      return (
        <Rois
          key={`roi-${index}`}
          index={ index }
          coordinates={ roi }
          width={ width }
          height={ height }
          type={ detection?.type }
          validationStatus={ detection?.validation?.status }
          className={ roiEntered !== null && roiEntered !== index ? '--hidden' : '' }
          onMouseEnter={ () => { setRoiEntered(index) }}
          onMouseLeave={ () => { setRoiEntered(null) }}
          onClick={ () => { onOpenRoi?.(roi) }}
        >
          <PhotoTooltip
            detection = { detection }
            anomaly={ detection?.anomaly }
            asset={ detection?.asset }
            severity={ detection?.severity }
            validation= { detection?.validation }
          />
        </Rois>
      )
    }) }
  </>)
}

RoisLayer.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  detections: ({ detections }, propName, componentName) => {
    const isValid = detections.every(detection => (
      'rois' in detection && 'anomaly' in detection)
    )
    if (isValid) return
    return new Error(getErrorMessage(propName, componentName))
  },
  onOpenRoi: PropTypes.func,
}

RoisLayer.defaultProps = {
  detections: []
}

export default RoisLayer
