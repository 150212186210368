import { useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import useAuthToken from './useAuthToken'

function useLogout () {
  const [, , removeAuthToken, , , removeAuthUser] = useAuthToken()
  const apolloClient = useApolloClient()
  const history = useHistory()

  const logout = () => {
    removeAuthToken()
    removeAuthUser()

    setTimeout(async () => {
      await apolloClient.clearStore()
      history.replace('/login')
    }, 250)
  }

  return logout
}

export default useLogout
