import * as Toaster from 'ui/elements/Toast/Toast'
import GLOBAL_MESSAGES from './messages.global'

export const showErrors = (failed) => {
  if (!failed || failed?.length === 0) return
  console.error('Errors:')
  failed.forEach((file) => {
    console.error(file.error)
  })
}

export const showOnlineNotification = ({ isOnline }) => {
  if (isOnline) return
  Toaster.showError(GLOBAL_MESSAGES.OFFLINE)
}

export const showRemovedFileMessage = (type) => {
  const fileType = type.includes('image') ? 'La imagen' : 'El archivo'
  Toaster.showSuccess(GLOBAL_MESSAGES.FILE_REMOVED(fileType))
}

export const showSuccess = () => {
  Toaster.showSuccess(GLOBAL_MESSAGES.SUCCESS)
}

export const showError = () => {
  Toaster.showError(GLOBAL_MESSAGES.ERROR)
}

export const showUploadCancelled = () => {
  Toaster.showCancel(GLOBAL_MESSAGES.CANCEL)
}
