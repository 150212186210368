import * as imageMessages from 'ui/pages/NewInspection/Uploader/UploadTypes/images/images.messages'
import * as imageWithoutTextMessages from 'ui/pages/NewInspection/Uploader/UploadTypes/images/images.withoutText.messages'
import * as videoMessages from 'ui/pages/NewInspection/Uploader/UploadTypes/video/video.messages'

import GLOBAL_MESSAGES from './messages.global'
export * from './Messages.notification'

const getMessageOfRules = {
  IMAGES: { ...imageMessages },
  IMAGES_WITHOUT_TEXT: { ...imageWithoutTextMessages },
  VIDEO: { ...videoMessages },
}

export const calculateWarningMessage = (files, user, title, uploadType) => {
  if (!title) return GLOBAL_MESSAGES.NO_TITLE
  if (!user) return GLOBAL_MESSAGES.NO_USER
  if (!getMessageOfRules[uploadType.id]) { return null }
  return getMessageOfRules[uploadType.id].getMessage(files, uploadType)
}

export const getMessagesByUploadType = (uploadType) => {
  if (!getMessageOfRules[uploadType.id]) { return null }
  return getMessageOfRules[uploadType.id].messages
}
