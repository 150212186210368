import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import ConditionalWrapper from 'ui/elements/ConditionalWrapper/ConditionalWrapper'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import './SidebarOption.scss'

const Link = ({ to, disabled, children, ...rest }) => {
  if (!to) { return children }
  return (
    <NavLink to={ to }
      className="sidebar-option__link"
      activeClassName={ disabled ? '': 'selected' }
      { ...rest}
    >{ children }</NavLink>
  )
}

const SidebarOption = ({ to, icon, label, testid, disabled, isInside, onClick, children }) => {
  const content = (<>
    { children }
    { !children && icon && (<span className={`icon icon--${icon}`}></span>)}
    { !children && !icon && label && (<span>{ label }</span>)}
  </>)

  return (
    <li className={ `sidebar-option ${isInside && '--is-inside'}` }>
      <ConditionalWrapper
        condition={ label }
        wrapper={ (children) => (
          <Tooltip
            label={ label }
            direction="right"
          >{ children }</Tooltip>
        )}
      >
      { !onClick && (
        <Link
          to={ to }
          disabled={ disabled }
          data-testid={ testid }
        >{ content }</Link>)}
      { onClick && (
        <button className="sidebar-option__link"
          onClick={ onClick }
          data-testid={ testid }
        >{ content }</button>) }
      </ConditionalWrapper>
    </li>
  )
}

SidebarOption.propTypes = {
  to: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  testid: PropTypes.string,
  disabled: PropTypes.bool,
  isInside: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
}

export default SidebarOption
