import PropTypes from 'prop-types'
import { capitalize } from 'lib'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import './DetectionsWithoutRoiTooltip.scss'

function textTooltip (
  detections,
) {
  return (
    <dl>
      <h5>Defectos a nivel apoyo</h5>
      {detections.map(({anomaly, category}) => (
        <>
          <dd>{capitalize(anomaly?.name || category?.name)}</dd>
        </>
      ))}
    </dl>
  )
}

const DetectionsWithoutRoiTooltip = ({
  detections,
}) => {
  if (!detections || !detections.length) {
    return null;
  }
  return (
    <Tooltip
      className="detections-tooltip"
      label={
        textTooltip(detections)
      }
      scroll={ false }
    />
  )
}

DetectionsWithoutRoiTooltip.propTypes = {
  detections: PropTypes.array,
}

export default DetectionsWithoutRoiTooltip
