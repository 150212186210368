import { ApolloClient, InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import useAuthToken from './useAuthToken'

const { REACT_APP_API_URL, REACT_APP_GQL_PATH } = process.env
const uri = `${REACT_APP_API_URL}${REACT_APP_GQL_PATH}`

function useApolloClient () {
  const [authToken] = useAuthToken()

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          inspections: relayStylePagination(),
          detectionsWithValidations: relayStylePagination(),
          detections: relayStylePagination(),
        },
      },
    },
  })

  const client = new ApolloClient({
    uri,
    cache,
    headers: {
      authorization: authToken,
    }
  })

  if (!client) return null
  return client
}

export default useApolloClient
