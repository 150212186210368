import { useCallback } from 'react'
import useDeleteInspection from "./useDeleteInspection"

function forEachPromise(items, fn) {
  return items.reduce(function (promise, item) {
    return promise.then(function () {
      return fn(item)
    })
  }, Promise.resolve())
}

function useDeleteInspectionQueue ({
  queueById,
  onCompleted,
  onError,
  onAllCompleted,
}) {
  const { launch: deleteInspection } = useDeleteInspection({
    onCompleted,
    onError,
  })

  const deleteInspections = useCallback(() => {
    if (queueById.length === 0) {
      onCompleted([])
      return
    }

    forEachPromise(queueById, (inspectionId) =>
      deleteInspection({ inspectionId })).then(onAllCompleted)
  }, [queueById, deleteInspection, onCompleted, onAllCompleted])

  return {
    launchQueue: deleteInspections,
  }
}

export default useDeleteInspectionQueue
