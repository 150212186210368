import PropTypes from 'prop-types'
import NewInspectionHeader from 'ui/pages/NewInspection/Files/Header/NewInspectionHeader'
import ListOfFiles from 'ui/pages/NewInspection/Files/ListOfFiles/ListOfFiles'

const NewInspectionFiles = ({
  isUploadButtonEnabled,
  areSomeFilesAdded,
  onUpload,
  onRemoveFile,
  onCancel,
  ...rest
}) => {
  return (<>
    <NewInspectionHeader
      { ...rest }
      isUploadButtonEnabled={ isUploadButtonEnabled }
      onUpload={ onUpload }
      onCancel={ onCancel }
    />

    <ListOfFiles
      { ...rest }
      onRemoveFile={ onRemoveFile }
      areSomeFilesAdded={ areSomeFilesAdded }
    />
  </>)
}

NewInspectionFiles.propTypes = {
  isUploadButtonEnabled: PropTypes.bool,
  areSomeFilesAdded: PropTypes.bool,
  onUpload: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onCancel: PropTypes.func,
}

export default NewInspectionFiles
