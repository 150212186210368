import { createContext } from 'react'
import useSettings from 'hooks/settings/useSettings'
import useAuthToken from 'hooks/auth/useAuthToken'

const Context = createContext({})

export function SettingsContextProvider ({ children }) {
  const [authToken] = useAuthToken()
  const { data: settings, error } = useSettings({ authToken })
  if (error) return <p>Error</p>

  return (
    <Context.Provider value={ settings }
    >{ children }</Context.Provider>
  )
}

export default Context
