import { SKELETON_TEXT } from "lib"
const COORDINATE_BY_DEFAULT = '0'

const useImageInfo = ({ image }) => {
  if (!image) return
  const { id, file, position, metadata } = image

  const name = file?.name || SKELETON_TEXT

  let latitude = COORDINATE_BY_DEFAULT
  let longitude = COORDINATE_BY_DEFAULT

  if (position) {
    latitude = position.latitude
    longitude = position.longitude
  } else if (metadata) {
    latitude = metadata?.[0]?.value || COORDINATE_BY_DEFAULT
    longitude = metadata?.[1]?.value || COORDINATE_BY_DEFAULT
  }

  return {
    name,
    latitude,
    longitude,
    id,
  }
}

export default useImageInfo
