import React, { useState, useEffect, useContext, useCallback } from 'react'
import useUrlOfKey from 'hooks/query/useUrlOfKey'
import { parse as parseCoordinate } from 'domain/coordinate'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import SplitPanelContext from 'adapter/context/SplitPanelContext'
import useDrawShortcuts from '../DrawHooks/useDrawShortcuts/useDrawShortcuts'
import useSyncZoomAndPosition from '../DrawHooks/useSyncZoomAndPosition'
import DrawZoom from '../DrawZoom/DrawZoom'
import { DRAG_MODE, CROPPERS } from '../DrawVendor/DrawVendorConfig'
import DrawVendor from '../DrawVendor/DrawVendor'
import './DrawCropper.scss'

export { CROPPERS }

const DrawCropper = React.forwardRef(({
  progressiveRef,
  container,
  activePointIndex,
  originalImageKey,
  progressiveImageKey,
  getElement,
  scale,
  isLoading,
  isAssignedUser,
  onProgressiveReady,
  onOriginalReady
}, originalRef) => {
  const { cropStatus, setCropStatus, setCoordinates, isAllowedToDraw } = useContext(CropContext)
  const { isMapMinified } = useContext(SplitPanelContext)

  const [isProgressiveReady, setIsProgressiveReady] = useState(false)
  const [isOriginalReady, setIsOriginalReady] = useState(false)

  const {
    updateZoom,
    syncPosition,
    syncZoom,
    resetSync,
  } = useSyncZoomAndPosition({ getElement, scale })

  const { data: progressiveUrl } = useUrlOfKey({ key: progressiveImageKey })
  const { data: originalUrl } = useUrlOfKey({ key: originalImageKey })
  const progressiveCropper = progressiveRef?.current?.cropper
  const originalCropper = originalRef?.current?.cropper

  const handleReset = () => {
    isProgressiveReady && progressiveCropper.reset()
    isOriginalReady && originalCropper.reset()
    isOriginalReady && originalCropper.clear()
    setCropStatus(isAllowedToDraw ? CROP_STATUS.READY_TO_DRAW : CROP_STATUS.LOADED)
  }

  useDrawShortcuts({ isOriginalReady, originalCropper, isAssignedUser, onReset: handleReset })

  const resizeTheCropper = useCallback(() => {
    // Simulate the resize to the cropper re-render the canvas:
    if (!isMapMinified) return
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
      setIsOriginalReady(true)
    }, 300)
  }, [isMapMinified])

  useEffect(() => {
    if (!isOriginalReady) return
    updateZoom()
    syncPosition()
  }, [isOriginalReady, originalImageKey, progressiveImageKey, updateZoom, syncPosition])

  useEffect(() => {
    if (!originalCropper) return

    // Change drag mode of original cropper when it's ready:
    if (cropStatus === CROP_STATUS.READY_TO_DRAW) {
      originalCropper.setDragMode(DRAG_MODE.CROP)
    }
    // Return to default drag mode when it's not ready:
    if (cropStatus === CROP_STATUS.LOADED) {
      originalCropper.setDragMode(DRAG_MODE.MOVE)
    }
  }, [cropStatus, isOriginalReady, originalCropper])

  // Reset the state when the keys changes:
  useEffect(() => {
    setIsProgressiveReady(false)
    setIsOriginalReady(false)
    resetSync()
  }, [activePointIndex, originalImageKey, progressiveImageKey, setCropStatus, resetSync])

  useEffect(() => {
    resizeTheCropper()
    resetSync()
  }, [isMapMinified, resizeTheCropper, resetSync])

  const handleDrawing = useCallback(() => {
    if (cropStatus === CROP_STATUS.LOADED) return
    setCropStatus(CROP_STATUS.DRAWING)
  }, [cropStatus, setCropStatus])

  const handleCropEnd = () => {
    const originalCropper = getElement({ isProgressive: false })
    const { cropped } = originalCropper
    if (!cropped) return
    const data = originalCropper.getData(true)
    const coordinate = parseCoordinate(data)
    setCoordinates(coordinate)
    setCropStatus(CROP_STATUS.DRAWN)
  }

  return (<>
    { /* HD IMAGE */ }
    <DrawVendor
      ref={ originalRef }
      src={ originalUrl }
      className='hd'
      style={{ zIndex: !isOriginalReady ? -1 : 2 }}
      isLoading={ isLoading }
      handleDrawing={ handleDrawing }
      handleCropEnd={ handleCropEnd }
      ready={ (event) => {
        setIsOriginalReady(true)
        onOriginalReady(event)
      }}
    >
      { (originalCropper) && (
        <DrawZoom
          className="draw__zoom"
          containerRef={ container }
          element={ originalCropper }
          onReset={ handleReset }
        />
      )}
    </DrawVendor>

    { /* SD IMAGE */ }
    <DrawVendor
      ref={ progressiveRef }
      src={ progressiveUrl }
      handleDrawing={ syncPosition }
      handleZoom={ syncZoom }
      className={ ((isOriginalReady && !isLoading) || isMapMinified) ? 'invisible': '' }
      isLoading={ isLoading }
      ready={ (event) => {
        setIsProgressiveReady(true)
        onProgressiveReady(event)
      }}
    >
      { (progressiveCropper && !isOriginalReady) && (
        <DrawZoom
          className="draw__zoom"
          containerRef={ container }
          element={ progressiveCropper }
          isLoading={ !isProgressiveReady }
          onReset={ handleReset }
        />
      )}
    </DrawVendor>

  </>)
})

export default DrawCropper
// (1): The getElement function is used to get the cropper instance,
// cached in Draw (parent) component. cropStatus in these handlers
// are not correctly updated.
