const convertInSeconds = (date) => date / 1000

const HEADER = {
  AUTHORIZATION: 'authorization',
  FILE_LAST_MODIFIED: 'File-Last-Modified'
}

const getHeaders = ({ token }) => (file) => {
  const timestampInSeconds = convertInSeconds(file.data.lastModified)

  return {
    [HEADER.AUTHORIZATION]: token,
    [HEADER.FILE_LAST_MODIFIED]: timestampInSeconds
  }
}

export default getHeaders
