import PropTypes from 'prop-types'
import FilesTable from './FilesTable/FilesTable'
import './ListOfFiles.scss'

const ListOfFilesEmpty = () => (<>
  <FilesTable />
  <section className="data-source-empty">
    <h3 className="data-source__title">Sin archivos</h3>
    <p>Esta inspección no tiene archivos.</p>
  </section>
</>)

const ListOFilesError = () => (<>
  <FilesTable />
  <section className="data-source-empty">
    <h3 className="data-source__title">Error al cargar los archivos</h3>
    <p>Por favor, inténtelo de nuevo más tarde.</p>
  </section>
</>)

const ListOfFiles = ({ files = [], isLoading, error, ...rest }) => {
  if (error) return <ListOFilesError />
  if (files.length === 0 && !isLoading) return <ListOfFilesEmpty />
  return (
    <FilesTable
      files={ files }
      isLoading={ isLoading }
      { ...rest }
    />
  )
}

ListOfFiles.propTypes = {
  files: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  areVideoFiles: PropTypes.bool,
  skeleton: PropTypes.node,
}

export default ListOfFiles
