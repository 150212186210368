import PropTypes from 'prop-types'
import Sidebar from 'ui/components/Sidebar/Sidebar'
import './Layout.scss'

const Layout = ({
  inspectionId,
  aside,
  isInspectionWithError,
  children,
  className
}) => (
  <div className={ `layout-with-aside ${className || '' }`}>
    <Sidebar
      className="layout-with-aside__sidebar"
      inspectionId={ inspectionId }
      isInspectionWithError={ isInspectionWithError }
    />
    <article className="layout-with-aside__inner">
      <section className="layout-with-aside__aside">
        { aside }
      </section>

      <section className="layout-with-aside__content">
        { children }
      </section>
    </article>
  </div>
)

Layout.propTypes = {
  inspectionId: PropTypes.string,
  aside: PropTypes.node,
  isInspectionWithError: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

export default Layout
