import { SKELETON_TEXT } from 'lib'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Dot, { DOT_TYPES } from 'ui/elements/Dot/Dot'
import { FILE_STATUS, FILE_TEXTS } from 'domain/files'
import { getErrorMessageWithFile } from 'domain/files/errors'
import './FileRowTooltip.scss'

const SharedLabel = ({ status, dotType, children }) => (<>
  <p className="title">Estado:</p>
  <ul>
    { status && <Dot statusId={ dotType } /> }
    { (status && FILE_TEXTS[status]) || children }
  </ul>
</>)

const Label = {
  [FILE_STATUS.CORRECT]: () => (
    <SharedLabel
      status={ FILE_STATUS.CORRECT }
      dotType={ DOT_TYPES.CORRECT.id }
    />
  ),
  [FILE_STATUS.NOT_CHECKED]: () => (
    <SharedLabel
      status={ FILE_STATUS.NOT_CHECKED }
      dotType={ DOT_TYPES.PENDING.id }
    />
  ),
}

const ErrorLabel = ({ errors }) => (
  <SharedLabel>
    { errors.map((error, index) => (
      <li key={ `error-${index}` }>
        <Dot statusId={ DOT_TYPES.INCORRECT.id } />
        { getErrorMessageWithFile(error.code) }
      </li>
    )) }
  </SharedLabel>
)

const FileRowTooltip = ({ status, errors = [] }) => {
  if (!status) return SKELETON_TEXT

  const modifierClassName = `--${FILE_STATUS[status].toLowerCase()}`

  const statusLabel = (errors?.length > 0) ?
    <ErrorLabel errors={ errors } />:
    Label[status]()

  return (
    <Tooltip
      className="error-with-file"
      label={ statusLabel }
      scroll={ false }
    >
      <span className={ `cell-status ${modifierClassName}` }>{ FILE_TEXTS[status] }</span>
    </Tooltip>
  )
}

export default FileRowTooltip
