import FilesTable from '../FilesTable/FilesTable'
import './ListOfFilesEmpty.scss'

const ListOfFilesEmpty = ({ title, description }) => (<>
  <FilesTable />
  <section className="new-inspection-empty">
    <h3 className="new-inspection-empty__title">{ title }</h3>
    <p className="new-inspection-empty__description">{ description }</p>
  </section>
</>)

export default ListOfFilesEmpty
