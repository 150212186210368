import PropTypes from 'prop-types'
import { SKELETON_TEXT } from 'lib'
import Time from 'ui/components/Time/Time'
import './InspectionSummary.scss'

const InspectionSummary = ({ name, description, createdAt, children }) => (
  <header className="inspection-summary">
    <h1>{ name || SKELETON_TEXT }</h1>
    <p>{ description || SKELETON_TEXT }</p>
    <dl>
      <dt>Fecha de inspección</dt>
      <dd>{ createdAt ? (
        <Time date={ createdAt } />
      ) : SKELETON_TEXT }</dd>
    </dl>
    { children }
  </header>
)

InspectionSummary.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  createdAt: PropTypes.string,
  children: PropTypes.node
}

export default InspectionSummary
