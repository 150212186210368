const VIDEO = {
  id: 'VIDEO',
  byFeatureFlag: ['videos'],
  fileTypesSupported: ['video/*'],
  allowMultipleUploads: false,
  maxNumberOfFiles: 1,
  withCheckGeolocation: false,
}

export default VIDEO
