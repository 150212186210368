import PropTypes from 'prop-types'
import AnomalyForm from './Form/AnomalyForm'
import Crop from './Crop/Crop'

const AnomalyViewer = ({
  from,
  inspectionId,
  detectionForm,
  isVerticalCrop,
  refetchInspection,
  isAssignedUser,
  onRefetch,
  onClose
}) => (<>
  <AnomalyForm
    from={ from }
    inspectionId={ inspectionId }
    detection={ detectionForm?.detection }
    type={ detectionForm?.typeForm }
    refetchInspection={ refetchInspection }
    isAssignedUser={ isAssignedUser }
    onSuccess={ onRefetch }
    onClose={ onClose }
  />
  <Crop
    url={ detectionForm?.url }
    urlKey={ detectionForm?.key }
    isVerticalOriginalImage={ isVerticalCrop }
  />
</>)

AnomalyViewer.propTypes = {
  from: PropTypes.string,
  inspectionId: PropTypes.string,
  detectionForm: PropTypes.object,
  isVerticalCrop: PropTypes.bool,
  refetchInspection: PropTypes.func,
  isAssignedUser: PropTypes.bool,
  onRefetch: PropTypes.func,
  onClose: PropTypes.func,
}

export default AnomalyViewer
