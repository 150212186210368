import React from 'react'
import "./Polygon.scss"

const mod = 'polygon'

function Polygon({ points, width, height }) {
  return (
   <svg
      className={`${mod}`}
      height={height}
      width={width}
      preserveAspectRatio="xMinYMin meet"
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <polygon points={points} />
    </svg>
  )
}

export default Polygon
