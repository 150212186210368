import PropTypes from 'prop-types'
import { memo, useRef, useEffect, useCallback } from 'react'
import { EVENTS } from '../vendor/Uppy/UppyAPI'
import parseFiles from './Parser/parseFiles'

const handleUploadError = (error) => { console.error(error) }

const Uploader = ({
  uploader,
  isUploading,
  setFilesToBeUploaded,
  onProgress,
  onUploadCompleted,
  onOnline,
  onOffline,
}) => {
  const { current: uploaderRef } = useRef(uploader)

  const handleComplete = useCallback(({ successful, failed }) => {
    if (!isUploading) return
    if (successful.length > 0) {
      const fileIds = parseFiles(successful)
      onUploadCompleted?.(fileIds)
    } else if (failed.length > 0) {
      console.error(failed)
    }
  }, [isUploading, onUploadCompleted])

  useEffect(() => {
    if (!uploaderRef) return

    uploaderRef.on(EVENTS.PROGRESS, onProgress)
    uploaderRef.on(EVENTS.FILES_ADDED, setFilesToBeUploaded)
    uploaderRef.on(EVENTS.UPLOAD_ERROR, handleUploadError)
    uploaderRef.on(EVENTS.COMPLETE, handleComplete)
    uploaderRef.on(EVENTS.IS_OFFLINE, onOffline)
    uploaderRef.on(EVENTS.BACK_ONLINE, onOnline)

    return () => {
      uploaderRef.off(EVENTS.PROGRESS, onProgress)
      uploaderRef.off(EVENTS.FILES_ADDED, setFilesToBeUploaded)
      uploaderRef.off(EVENTS.UPLOAD_ERROR, handleUploadError)
      uploaderRef.off(EVENTS.COMPLETE, handleComplete)
      uploaderRef.off(EVENTS.IS_OFFLINE, onOffline)
      uploaderRef.off(EVENTS.BACK_ONLINE, onOnline)
      uploaderRef.logout()
    }

  }, [
    uploaderRef,
    setFilesToBeUploaded,
    handleComplete,
    onProgress,
    onOnline,
    onOffline,
  ])

  return (<></>)
}

Uploader.propTypes = {
  uploader: PropTypes.object,
  isUploading: PropTypes.bool,
  setFilesToBeUploaded: PropTypes.func,
  onUploadCompleted: PropTypes.func,
  onProgress: PropTypes.func,
  onOnline: PropTypes.func,
  onOffline: PropTypes.func,
}

export default memo(Uploader)
