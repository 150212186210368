import PropTypes from 'prop-types'
import { memo, useContext } from 'react'
import { isProd } from 'lib/env'
import Button from 'ui/elements/Button/Button'
import Sidebar from 'ui/components/Sidebar/Sidebar'
import CropContext from 'adapter/context/CropContext'
import SplitView from '../SplitView/SplitView'
import Map from '../../Map/Map'
import PhotoViewer from '../../PhotoViewer/PhotoViewer'

const Wrapper = ({
  inspectionId,
  asidePanel,
  isEmpty,
  isAsideVisible,
  isDebug = false,
  children
}) => {
  const { cropStatus } = useContext(CropContext)

  return (
    <section className='geo-detail'>
      <Sidebar
        inspectionId={ inspectionId }
        isEmpty={ isEmpty }
      />
      <article className="geo-detail__inner">
        { (isDebug && !isProd) && (<p className="geo-detail__crop-status">{ cropStatus }</p>) }
        <SplitView>{ children }</SplitView>
        { isAsideVisible && (
          <aside className={`geo-detail__form ${isAsideVisible && '--is-enabled'}`}>
            { asidePanel }
          </aside>
        )}
      </article>
    </section>
  )
}

Wrapper.propTypes = {
  inspectionId: PropTypes.string,
  asidePanel: PropTypes.element,
  isEmpty: PropTypes.bool,
  isAsideVisible: PropTypes.bool,
  isDebug: PropTypes.bool,
  children: PropTypes.node,
}

export default Wrapper

const LoadingWrapper = memo(({ inspectionId }) => {
  return (
    <Wrapper inspectionId={ inspectionId }>
      <Map isLoading={ true } />
      <PhotoViewer />
    </Wrapper>
  )
})

LoadingWrapper.propTypes = {
  inspectionId: PropTypes.string
}

const ErrorFallback = (
  <section className="error-fallback">
    <p>Parece que ha ocurrido un error con esta inspección.</p>
    <Button className="button-clean --failure" to="/">Volver al listado de inspecciones</Button>
  </section>
)

const NotFound = ({ inspectionId }) => (
  <Wrapper inspectionId={ inspectionId } isEmpty>
    <Map />
    <PhotoViewer errorFallback={ ErrorFallback } />
  </Wrapper>
)

NotFound.propTypes = {
  inspectionId: PropTypes.string,
}

export const edgeCases = ({ inspectionId, loading, data, error }) => {
  if (loading) return <LoadingWrapper inspectionId={ inspectionId } />
  if (!data) return <NotFound inspectionId={ inspectionId } />
  if (error) {
    console.error(error)
    return <p>Error</p>
  }
}
