import { useCookies } from 'react-cookie'

const TOKEN_NAME = 'authToken'
const USER_NAME = 'authUser'

function useAuthToken () {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME, USER_NAME])
  const setAuthToken = (authToken) => { setCookie(TOKEN_NAME, authToken, { path: '/' }) }
  const setAuthUser = (authUser) => { setCookie(USER_NAME, authUser, { path: '/' }) }
  const removeAuthToken = () => { removeCookie(TOKEN_NAME, { path: '/' }) }
  const removeAuthUser = () => { removeCookie(USER_NAME, { path: '/' }) }

  return [
    cookies[TOKEN_NAME],
    setAuthToken,
    removeAuthToken,
    cookies[USER_NAME],
    setAuthUser,
    removeAuthUser
  ]
}

export default useAuthToken
