import { createContext, useEffect, useState } from 'react'

export const PANEL_STATUS = Object.freeze({
  MAP_OPENED: 0,
  SPLIT_VIEW: 50,
  VIEWER_OPENED: 50.001,
  MAP_MINIFY: 100,
})

const Context = createContext({})

export function SplitPanelContextProvider({ children }) {
  const [activePanel, setActivePanel] = useState(PANEL_STATUS.SPLIT_VIEW)
  const [isPhotoViewerOpened, setIsPhotoViewerOpened] = useState(false)
  const [isSplitViewOpened, setIsSplitViewOpened] = useState(true)
  const [isMapMinified, setIsMapMinified] = useState(false)

  useEffect(() => {
    setIsPhotoViewerOpened(activePanel === PANEL_STATUS.VIEWER_OPENED)
    setIsSplitViewOpened(activePanel === PANEL_STATUS.SPLIT_VIEW)
    setIsMapMinified(activePanel === PANEL_STATUS.MAP_MINIFY)
  }, [activePanel])

  return <Context.Provider value={{
    activePanel,
    setActivePanel,
    isPhotoViewerOpened,
    isSplitViewOpened,
    isMapMinified,
  }}>
    { children }
  </Context.Provider>
}

export default Context
