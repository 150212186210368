import { gql } from '@apollo/client'

export const VIDEOS = gql`
  fragment VideosFragment on Inspection {
    videos {
      id
      file {
        id
        name
        url
        contentType
        metadata {
          key
          value
        }
      }
    }
  }
`
