import imagesRules from './images/images'
import imagesRulesWithoutTextFile from './images/imagesWithoutText'
import videoRules from './video/video'

const RULES_TO_CREATE_A_INSPECTION = {
  IMAGES: imagesRules,
  VIDEO: videoRules,
  IMAGES_WITHOUT_TEXT: imagesRulesWithoutTextFile
}

export default RULES_TO_CREATE_A_INSPECTION
