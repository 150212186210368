const FieldTextarea = ({
  id,
  className,
  required,
  placeholder,
  onChange,
  label,
  invalid,
  error,
  onKeyPress,
}) => {
  return (
    <p className={`field field--textarea
      ${className}
      ${invalid && 'invalid'}
      ${required && 'required'}
    `}
    >
      <textarea
        id={ id }
        className={`field ${className}`}
        placeholder={ placeholder }
        required={ required }
        rows="5"
        onChange={ onChange }
        onKeyPress={ onKeyPress }
      ></textarea>
      <label htmlFor={ id }>{ invalid ? error : label }</label>
    </p>
  )
}

export default FieldTextarea
