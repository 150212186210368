import PropTypes from 'prop-types'
import { calculateWarningMessage } from 'ui/pages/NewInspection/Messages/Messages'
import './Notifier.scss'

const Notifier = ({ files, user, title, areSomeFilesAdded, uploadConfig }) => {
  const warningMessage = areSomeFilesAdded && calculateWarningMessage(files, user, title, uploadConfig)
  if (!warningMessage) return null

  return (
    <tr className="notifier fixed">
      <td colSpan="3">{ warningMessage }</td>
    </tr>
  )
}

Notifier.propTypes = {
  files: PropTypes.array,
  user: PropTypes.object,
  title: PropTypes.string,
  areSomeFilesAdded: PropTypes.bool,
  uploadConfig: PropTypes.object,
}

export default Notifier
