export const DRAG_MODE = {
  MOVE: 'move',
  CROP: 'crop'
}

export const CROPPER_CONFIG = {
  autoCrop: false,
  guides: false,
  responsive: true,
  minCropBoxWidth: 0,
  minCropBoxHeight: 0,
  viewMode: 2,
  restore: true,
  background: false,
  toggleDragModeOnDblclick: false,
  checkCrossOrigin: false,
  dragMode: DRAG_MODE.MOVE,
  checkOrientation: false,
  cropBoxMovable: true,
}

export const CROPPERS = {
  LOADING: {
    label: '...'
  },
  ORIGINAL: {
    label: 'HD',
    timeoutInMiliseconds: 24000
  },
  PROGRESSIVE: {
    label: 'SD',
    timeoutInMiliseconds: 17000
  }
}
