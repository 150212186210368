import { gql } from '@apollo/client'
import { DETECTIONS } from './detections.fragment'
import { ANOMALIES } from '../anomalies/anomalies.fragment'
import { VALIDATION } from '../detection/validation.fragment'
import { ROIS } from '../partials/rois.fragment'
import { ASSET } from '../assets/asset.fragment'

export const field = 'detections'

export const DETECTIONS_OF_INSPECTION_QUERY = gql`
  ${ DETECTIONS }
  query detections($cursor: String, $limit: Int, $id: ID!) {
    detections(first: $limit, after: $cursor, inspectionId: $id) {
      edges {
        cursor
        node {
          ...DetectionsFragment
          images {
            order
            position {
              latitude,
              longitude
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      totalPending
      totalCorrect
      totalIncorrect
      totalDoubt
    }
  }
`
export const fieldDetectionsOfImage = 'detectionsOfImage'

export const DETECTIONS_OF_IMAGE_QUERY = gql`
  ${ ANOMALIES }
  ${ VALIDATION }
  ${ ROIS }
  ${ ASSET }
  query detectionsOfImage($imageId: ID!) {
    detectionsOfImage(imageId: $imageId) {
      id
      type
      ...AnomalyFragment
      ...ValidationFragment
      ...RoisFragment
      ...AssetFragment
      category {
        id
        name
        externalReference
      }
      parentCategory {
        id
        externalReference
        name
      }
      severity {
        id
        value
      }
      polygons {
        points
      }
      crop {
        key
      }
      metadata {
        key
        value
      }
    }
  }
`
