const {
  REACT_APP_GOOGLE_MAP_APIKEY,
} = process.env

export const GoogleMapsConfig = {
  apiKey: REACT_APP_GOOGLE_MAP_APIKEY,
  mapOptions: {
    mapTypeId: 'satellite',
    fullscreenControl: false,
    tilt: 0,
    rotateControl: false
  }
}

const config = {
  thumbnailUrlField: 'smallKey',
  defaultZoom: 19,
  defaultCenter: {
    latitude: 40.4131462,
    longitude: -3.6925846
  }
}

export default config
