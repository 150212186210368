import { createContext, useState, useEffect } from 'react'

export function isVerticalRatio(width, height) {
  return (width < height)
}

export const CROP_STATUS = Object.freeze({
  NONE: 'NONE',
  LOADED: 'LOADED',
  READY_TO_DRAW: 'READY_TO_DRAW',
  DRAWING: 'DRAWING',
  DRAWN: 'DRAWN',
  CROPPING: 'CROPPING',
  CROPPED: 'CROPPED',
  FILL_FORM: 'FILL_FORM'
})

const Context = createContext({})

export function CropContextProvider({ children }) {
  const [cropStatus, setCropStatus] = useState(CROP_STATUS.NONE)
  const [coordinates, setCoordinates] = useState({})
  const [isVerticalCrop, setIsVerticalCrop] = useState(false)
  const [isAllowedToDraw, setIsAllowedToDraw] = useState(false)

  useEffect(() => {
    if (!coordinates.width) return
    const isVertical = isVerticalRatio(coordinates.width, coordinates.height)
    setIsVerticalCrop(isVertical)
  }, [coordinates, setIsVerticalCrop])

  useEffect(() => {
    setIsAllowedToDraw([
      CROP_STATUS.DRAWING,
      CROP_STATUS.DRAWN,
      CROP_STATUS.READY_TO_DRAW
    ].includes(cropStatus))
  }, [cropStatus, setIsAllowedToDraw])

  return <Context.Provider value={{
    cropStatus,
    setCropStatus,
    coordinates,
    setCoordinates,
    isVerticalCrop,
    isAllowedToDraw,
    setIsAllowedToDraw,
    canSeeTheCrop: [CROP_STATUS.CROPPED, CROP_STATUS.DRAWN].includes(cropStatus)
  }}>
    { children }
  </Context.Provider>
}

export default Context
