import Marker from '../Marker/Marker'
import MarkerTooltip from '../MarkerTooltip/MarkerTooltip'

const drawMarkers = ({
  markers,
  activePointIndex,
  onClick,
  handleHover,
  urlKey
}) => {
  const markersSorted = markers.sort((a, b) => (a.order - b.order))

  return markersSorted?.map((marker, i) => {
    if (!marker?.latitude) return null
    return (
      <Marker
        key={ `marker-${i + 1}` }
        lat={ marker.latitude }
        lng={ marker.longitude }
        text={ i + 1 }
        active={ activePointIndex === i }
        onClick={ () => onClick(i) }
        handleHoverCallback={ (isHovered) => handleHover(i, isHovered) }
      ><MarkerTooltip urlKey={ urlKey } />
      </Marker>
    )
  })
}

export default drawMarkers
