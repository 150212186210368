const addField = (field, item, callback) => ({
  ...item,
  [field]: callback(item)
})

export const addNameField = (collection, callback) => {
  if (!collection) return null
  if (!Array.isArray(collection)) { return addField('name', collection, callback) }
  return collection.map(item => addField('name', item, callback) )
}
