import PropTypes from 'prop-types'
import Button from 'ui/elements/Button/Button'
import './Pagination.scss'

const Pagination = ({
  className,
  onLoadMore,
  disabled,
  isSimplified,
  buttonClassName = '',
}) => (
  <section className={ `pagination ${className} ${isSimplified ? "" : "legacy"}`}>
    <Button
      key="pagination-button"
      variant="secondary"
      className={ `pagination__link ${buttonClassName}` }
      disabled={ disabled }
      onClick={ onLoadMore }
    >
      { isSimplified ? `Más resultados` : 'Mostrar más'}
    </Button>
  </section>
)

Pagination.propTypes = {
  className: PropTypes.string,
  onLoadMore: PropTypes.func,
  disabled: PropTypes.bool,
  isSimplified: PropTypes.bool,
  buttonClassName: PropTypes.string
}

export default Pagination
