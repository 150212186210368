import { useState, useEffect, useContext } from 'react'
import Uppy from '@uppy/core'
import { Dashboard, useUppy } from '@uppy/react'
//import ReactGA from 'react-ga'
import Button from 'ui/elements/Button/Button'
import { FieldTextarea, FieldText, FieldFile } from 'ui/elements/Field'
import Progress from 'ui/elements/Progress/Progress'
import Connection from 'ui/components/Connection/Connection'
import useAuthToken from 'hooks/auth/useAuthToken'
import SettingsContext from 'adapter/context/SettingsContext'

import Locales from './Uploader.json'
import TusUnusuals from './TusUnusuals'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './Uploader.scss'

const { REACT_APP_API_URL, REACT_APP_TUS_PATH } =
  process.env

const calculateChunkSizeInBytes = (sizeInMegabytes) => 1024 * 1024 * sizeInMegabytes
const CHUNK_SIZES_IN_MB = {
  SMALL: 50,
  BIG: 500
}

function Uploader({ onCancel, onDone }) {
  const [namedFiles, setNamedFiles] = useState(undefined)
  const [detailedFiles, setDetailedFiles] = useState(undefined)
  const [addedFiles, setAddedFiles] = useState(undefined)
  const [uploadProgress, setUploadProgress] = useState(undefined)
  const [uploadError, setUploadError] = useState(undefined)
  const [filled, setFilled] = useState(undefined)
  const [connection, setConnection] = useState(true)
  const [authToken] = useAuthToken()

  const settings = useContext(SettingsContext)
  const { multipleFileUpload, bigChunk = false } = settings || {}
  const chunkSize = calculateChunkSizeInBytes(bigChunk ? CHUNK_SIZES_IN_MB.BIG : CHUNK_SIZES_IN_MB.SMALL)

  let fileType = ['video/*', '.wmv']
  if (multipleFileUpload) fileType.push('image/*', '.txt', '.csv')

  let propMaxFiles = multipleFileUpload ? {maxNumberOfFiles: null} : {maxNumberOfFiles: 1}

  const headers = (file) => {
    const timestampInSeconds = file.data.lastModified / 1000
    return {
      'authorization': authToken,
      'File-Last-Modified': timestampInSeconds
    }
  }

  // Uppy
  const uppy = useUppy(() => {
    return new Uppy({
      id: 'video',
      autoProceed: false,
      allowMultipleUploads: multipleFileUpload,
      restrictions: {
        allowedFileTypes: fileType,
        ...propMaxFiles
      },
    }).use(TusUnusuals, {
      endpoint: `${REACT_APP_API_URL}${REACT_APP_TUS_PATH}`,
      headers,
      limit: 1,
      resume: true,
      chunkSize,
      removeFingerprintOnSuccess: true,
    })
  })

  const uppyProps = {
    locale: {
      strings: Locales.uppy,
    },
    showProgressDetails: false,
    proudlyDisplayPoweredByUppy: false,
    hideProgressAfterFinish: true,
    hideUploadButton: true,
    hideCancelButton: true,
  }

  /*const debugGA = (name, transport) => {
    if (isProd) {
      let d = new Date()
      let t = transport || d
      let s = typeof t === String ? t : t.toString()

      ReactGA.event({
        category: 'Uploader',
        action: `uploader_${name}`,
        transport: s,
      })
    }
  }*/

  const onReset = () => {
    let form = document.querySelector('#uploader-form')
    form && form.reset()
    setNamedFiles(undefined)
    setDetailedFiles(undefined)
    setAddedFiles(undefined)
    setUploadProgress(undefined)
    setUploadError(undefined)
    setFilled(undefined)
    uppy.cancelAll()
    uppy.reset()
  }

  const onClose = () => {
    onCancel()
    setTimeout(() => onReset(), 1000)
  }

  const onSubmit = () => {
    //debugGA('submit')
    setFilled(true)
    uppy.upload()
  }

  const onRetry = () => {
    //debugGA('retry')
    setUploadError(false)
    uppy.upload()
  }

  const uppyBtnID =
    '.uppy-StatusBar-actions button.uppy-u-reset.uppy-StatusBar-actionCircleBtn'

  const onOffline = () => {
    let uppyBtn = document.querySelector(uppyBtnID)
    if (uppyBtn) {
      if (uppyBtn.getAttribute('title') === 'Pausar') uppyBtn.click()
      uppyBtn.setAttribute('disabled', 'true')
    }
  }

  const onOnline = () => {
    let uppyBtn = document.querySelector(uppyBtnID)
    if (uppyBtn) {
      uppyBtn.removeAttribute('disabled')
      if (uppyBtn.getAttribute('title') === 'Retomar') uppyBtn.click()
    }
  }

  let numberOfFiles = 0

  useEffect(() => {
    onReset()
  }, [uppy])

  useEffect(() => {
    uppy.on('file-added', () => {
      numberOfFiles++
      setAddedFiles(true)
    })

    uppy.on('file-removed', () => {
      numberOfFiles--
      setAddedFiles(numberOfFiles > 0)
    })

    uppy.on('progress', (progress) => {
      setUploadProgress(progress)
    })

    uppy.on('upload-error', (error) => {
      //debugGA('error', error)
      setUploadError(true)
    })

    uppy.on('back-online', () => {
      //debugGA('recover')
      setUploadError(false)
      setConnection(true)
      onOnline()
    })

    uppy.on('is-offline', () => {
      //debugGA('lost')
      setConnection(false)
      onOffline()
    })

    uppy.on('upload-retry', (fileID) => {
      //debugGA('retry', fileID)
    })

    return () => {
      onReset()
    }
  }, [uppy])

  let success

  useEffect(() => {
    if (filled) {
      uppy.on('complete', (result) => {
        if (result.successful.length > 0 && namedFiles) {
          let urlIDs = []
          result.successful.map((r, i) => (
            urlIDs.push(r.uploadURL.split('/').pop())
          ))
          let propID = { fileIds: urlIDs }

          //debugGA('success', result.successful[0].uploadURL)
          success = setTimeout(() => {
            onDone({
              name: namedFiles,
              description: detailedFiles || '',
              ...propID
            })

            onClose()
          }, 1000)
        } else if (result.failed.length > 0) {
          //debugGA('fail', result.failed[0].error)
        }
      })
      return () => {
        clearTimeout(success)
      }
    } else {
      uppy.off('complete')
    }
  }, [filled])

  return (
    <>
      <div
        className="uploader"
        id="newInspection"
        data-multiple={ multipleFileUpload }
        data-status={
          !filled
            ? 'initial'
            : uploadProgress < 100 && uploadError !== true
              ? 'progress'
              : uploadProgress === 100 && uploadError !== true
                ? 'success'
                : uploadError === true
                  ? 'error'
                  : 'end'
        }
      >
        { !filled ? (
          <form id="uploader-form" className="uploader__form" autoComplete="off">
            <h2 className="uploader__title">Nueva inspección</h2>
            <p className="uploader__intro">
              Por favor, incluya los datos para{' '}
              <strong>una nueva inspección</strong>.
            </p>
            <div className="uploader__fieldset">
              <FieldText
                id="field-title-01"
                className="uploader__field--text"
                type="text"
                placeholder=""
                label="Título"
                onChange={ (e) => setNamedFiles(e.target.value) }
                required
              />
              <FieldTextarea
                id="field-descrption-01"
                className="uploader__field--textarea"
                placeholder=""
                label="Descripción"
                onChange={ (e) => setDetailedFiles(e.target.value) }
              />
            </div>
          </form>
        ) : uploadProgress < 100 && uploadError !== true ? (
          <>
            <h2 className="uploader__title">Subiendo</h2>
            <p className="uploader__intro">
              Espere hasta que se complete la subida.
            </p>
            <Progress className="uploader__progress" filled={ uploadProgress } />
          </>
        ) : uploadProgress === 100 && uploadError !== true ? (
          <>
            <h2 className="uploader__title">Archivo subido</h2>
            <p className="uploader__intro">
              El archivo se ha subido correctamente a la plataforma.
            </p>
          </>
        ) : uploadError === true ? (
          <>
            <h2 className="uploader__title">Algo fue mal</h2>
            <p className="uploader__intro">
              No hemos podido realizar la{' '}
              <strong>transferencia de datos completa</strong>.
              <br />
              Si el problema perdura escríbenos a{' '}
              <a href="mailto:cau@unusuals.ia" target="_blank" rel="noreferrer">
                cau@unusuals.ia
              </a>
            </p>
          </>
        ) : (
          ''
        )}

        <FieldFile className="uploader__field--file" label="Archivo" required>
          <Dashboard uppy={ uppy } {...uppyProps} />
        </FieldFile>

        <div className="uploader__buttons">
          <Button
            className="uploader__cancel"
            variant="cancel"
            onClick={ onClose }
            role="exit-modal"
          >
            Cancelar
          </Button>

          {(!filled || uploadError) && (
            <Button
              className="uploader__submit"
              onClick={ uploadError ? onRetry : onSubmit }
              disabled={
                !connection ||
                !addedFiles ||
                !namedFiles ||
                (filled && !uploadError)
              }
            >
              { uploadError ? 'Reintentar' : 'Nueva inspección' }
            </Button>
          )}
        </div>
      </div>

      <Connection connection={ connection } />
    </>
  )
}

export default Uploader
