import { Link } from 'react-router-dom'
import './Button.scss'

const Button = ({
  children,
  type = 'button',
  onClick = undefined,
  className,
  variant,
  disabled = false,
  role,
  href,
  to,
  target,
  rel,
  htmlFor
}) => (<>
  { href ? (
    <a
      className={`button--${variant || 'primary'} ${className}`}
      href={ href }
      onClick={ onClick }
      disabled={ disabled }
      data-role={ role }
      target={ target }
      rel={ rel }
    >
      { children }
    </a>
  ) : to ? (
    <Link
      className={`button--${variant || 'primary'} ${className}`}
      to={ to }
      onClick={ onClick }
      disabled={ disabled }
      data-role={ role }
    >
      { children }
    </Link>
  ) : (
    <button
      className={`button--${variant || 'primary'} ${className}`}
      type={ type }
      onClick={ onClick }
      disabled={ disabled }
      data-role={ role }
      htmlFor={ htmlFor }
    >
      { children }
    </button>
  )}
</>)

export default Button
