import PropTypes from 'prop-types'
import { FileInput } from '@uppy/react'
import Button from 'ui/elements/Button/Button'
import './AddFilesButton.scss'

const ERROR_MESSAGE = 'Ha habido un problema con el subidor de ficheros.'

const AddFilesButton = ({ uploader, label, disabled }) => {
  if (!uploader) return (
    <p className="add-files-error">{ ERROR_MESSAGE }</p>
  )
  return (
    <Button
      variant="secondary"
      className="button-clean --regular add-files"
      disabled={ disabled }
    >
      <FileInput
        uppy={ uploader }
        locale={{
          strings: {
            chooseFiles: label,
          }
        }}
      />
    </Button>
  )
}

AddFilesButton.propTypes = {
  uploader: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

export default AddFilesButton
