import { useEffect } from 'react'
import Modal, { openModal } from 'ui/elements/Modal/Modal'
import './Mobile.scss'

const mobileID = 'modalMobile'

function Mobile () {
  useEffect(() => {
    openModal(mobileID)
  }, [])

  return (
    <Modal
      id={ mobileID }
      className="mobile-banner"
      trigger={ false }
    >
      <h2>Lo sentimos</h2>
      <p>
        Nuestra experiencia móvil todavía no está preparada. Te pedimos
        disculpas! Estamos en ello. Mientras tanto, por favor accede desde un
        ordenador.
      </p>
    </Modal>
  )
}

export default Mobile
