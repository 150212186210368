import { useQuery } from '@apollo/client'
import INSPECTIONS_QUERY from 'infra/inspections/inspections.query'

const field = 'inspections'

const useGetInspections = () => {
  const {
    data, loading, error,
    refetch, fetchMore,
  } = useQuery(INSPECTIONS_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const inspections = data?.[field]

  return {
    data: inspections,
    loading,
    error,
    refetch,
    fetchMore,
  }
}

export default useGetInspections
