import useEventListener from "./useEventListener"

const EVENT_NAME = 'keydown'

const useKeydown = ({ isEnabled = true, keyCode: keyCodeInput, onAction }) => {
  useEventListener(EVENT_NAME, ({ keyCode }) => {
    if (!isEnabled) return
    const keycode = keyCode.toString()
    if (keyCodeInput !== keycode) return
    onAction?.()
  })
}

export default useKeydown
