import { round } from 'lib/types/number'

const parse = ({ x, y, width, height }) => ({
  x1: round(x, 0),
  y1: round(y, 0),
  x2: round(x + width, 0),
  y2: round(y + height, 0),
  width: round(width, 0),
  height: round(height, 0)
})

const get = ({ x1, y1, x2, y2 }) => ({ x1, y1, x2, y2 })

export {
  parse,
  get,
}
