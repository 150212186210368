import { gql } from '@apollo/client'
export const field = 'inspections'

const LIMIT = 20

export default gql`
  query inspections($cursor: String) {
    inspections(first: ${LIMIT}, after: $cursor) {
      edges {
        cursor
        node {
          id
          name
          description
          createdAt
          status
          progress
          accountId
          assignedUser {
            username
          }
          isAssignedUser
          metadata {
            key
            value
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      totalCompleted
    }
  }
`
