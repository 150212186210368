import { gql } from '@apollo/client'

export const ANOMALIES = gql`
  fragment AnomalyFragment on Detection {
    anomaly {
      id
      externalReference
      name
      parentCategory {
        id
        externalReference
        name
      }
      category {
        id
        externalReference
        name
      }
      type {
        id
        externalReference
        name
      }
    }
  }
`
