const FILES = {
  KMZ: {
    key: 'kmzUrl',
    text: 'KMZ',
  },
  XLS: {
    key: 'excelUrl',
    text: 'XLS',
  },
  CSV: {
    key: 'csvUrl',
    text: 'CSV',
  }
}

const filter = (collection, key) =>
  collection?.filter(item => item?.key === key) || []

const filesValues = Object.values(FILES)

export {
  filesValues as FILES,
  filter as filterFiles
}
