import Brand from 'ui/elements/Brand/Brand'
import useAuthToken from 'hooks/auth/useAuthToken'
import { Actions, HomeLink, CollectionLink } from '../Menu/Menu'
import './Header.scss'

const Header = () => {
  const [authToken, , , authUser] = useAuthToken()

  return (
    <header className="header">
      <div className="header__container">
        <Brand />

        { authToken && (
          <>
            <HomeLink className="header__option" />
            <CollectionLink className="header__option" />

            <div className="header__actions">
              <Actions
                username={ authUser }
                isMultipleCamera={ false }
              />
            </div>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
