import PropTypes from 'prop-types'
import { isAFunction } from 'lib/types'
import { getErrorByCode, calculateNumberOfErrors } from 'domain/inspection/errors/Errors'
import './ErrorList.scss'

const getPluralLiteral = (errorCount) => {
  const isPlural = errorCount > 1
  const prefix = isPlural ? 'han': 'ha'
  const suffix = isPlural ? 'es': ''
  return { prefix, suffix }
}

const Item = ({ children }) => (
  <li className="error-list__item">{ children }</li>
)

const ErrorInLines = ({ associatedItems = [], message }) =>
  (associatedItems.map((item) => (
    <Item key={ `error-in-line-${item}` }>{ message(item) }</Item>)
  ))

const ErrorList = ({ errors, className }) => {
  const errorCount = calculateNumberOfErrors(errors)
  const { prefix, suffix } = getPluralLiteral(errorCount)

  return (
    <section className={ `error-list ${className}` }>
      <p className='error-list__title'>{ `Se ${ prefix } encontrado ${ errorCount } error${suffix}` }</p>
      <ul className='error-list__body'>
        { errors.map((error, index) => {
            const { message } = getErrorByCode(error.code)
            if (!isAFunction(message)) {
              return <Item key={ `error-${index}` }>{ message }</Item>
            }
            return (
              <ErrorInLines
                associatedItems={ error.associatedItems }
                message={ message }
                key={ `error-${index}` }
              />
            )
          })
        }
      </ul>
    </section>
  )
}

ErrorList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
}

export default ErrorList
