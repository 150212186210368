import Asset from '../Asset/Asset'
import AssetTooltip from '../AssetTooltip/AssetTooltip'

const drawAssets = ({ assets, handleHover, currentAssetHovered }) => (
  assets?.map((asset, i) => {
    if (!asset?.position?.latitude) return null
    return (
      <Asset
        key={ `asset-${i + 1}` }
        lat={ asset.position.latitude }
        lng={ asset.position.longitude }
        reference={ asset?.externalReference }
        handleHoverCallback={ (isHovered) => { isHovered && handleHover(asset) } }
      ><AssetTooltip asset={ currentAssetHovered }/>
      </Asset>
    )
  })
)

export default drawAssets
