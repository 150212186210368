import PropTypes from 'prop-types'
import './Tooltip.scss'

const Tooltip = ({
  direction = 'top',
  label = '',
  scroll = true,
  children,
  className,
}) => (
  <section
    className={`tooltip ${className}`}
    data-dir={ direction }
  >
    <div className="tooltip__box">
      <div className="tooltip__content">
        <div
          className={ scroll ? 'tooltip__scroll': '' }
          data-testid="tooltip-scroll"
        >{ label }
        </div>
      </div>
    </div>
    { children }
  </section>
)

Tooltip.propTypes = {
  direction: PropTypes.oneOf(['down', 'right', 'top', 'down-left']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  scroll: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Tooltip
