import { useState, useEffect, useRef } from 'react'

const LIMIT = 1
const INTERVAL_IN_MILISECONDS = 1000

const useCountdown = ({
  timeout,
  onTick,
  onEnd,
}) => {
  const timer = useRef(null)
  const [count, setCount] = useState(timeout || null)
  const isRunning = useRef(false)

  const clear = () => {
    if (!timer.current) return
    clearInterval(timer.current)
    timer.current = null
  }

  useEffect(() => {
    if (!timeout || Number.isInteger(timeout) === false) return
    if (!isRunning.current) return

    timer.current = setInterval(() => {
      setCount(oldCount => {
        if (oldCount <= LIMIT) {
          onEnd?.()
          clear()
          isRunning.current = false
          return timeout
        }

        const newCount = oldCount - 1
        onTick?.(newCount)
        return newCount
      })
    }, INTERVAL_IN_MILISECONDS)

    return () => clear()
  }, [timeout, setCount, onEnd, onTick])

  return {
    count,
    start: () => {
      isRunning.current = true
    },
    clear,
  }
}

export default useCountdown
