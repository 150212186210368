import { getTextFile, getNumberOfTextFiles } from 'domain/files'

const messages = {
  debug: 'Subida de imágenes + fichero .txt.',
  addFile: 'Añadir ficheros',
  empty: {
    title: 'Sin archivos',
    description: `
      Para realizar una inspección es necesario incluir al menos una imagen
      en formato jpg o jpeg y un archivo en formato txt de geolocalización de dichas imágenes.
    `
  },
  noMinFiles: 'Incluye el archivo de geolocalización y al menos una imagen.',
  noTextFile: 'Incluye el archivo de geolocalización.',
  emptyTextFile: 'El archivo de geolocalización está vacío.',
  moreThanOneTextFile: (numberOfTextFiles) =>
    `Incluye un único archivo de geolocalización.
    Se han encontrado ${numberOfTextFiles} archivos de texto.`,
}

const getMessage = (files) => {
  const numberOfTextFiles = getNumberOfTextFiles(files)
  const textFileIsNotEmpty = getTextFile(files)?.size > 0
  if (numberOfTextFiles === 1 && textFileIsNotEmpty && files.length > 1) return null
  if (numberOfTextFiles === 0) { return messages.noTextFile }
  if (!textFileIsNotEmpty) { return messages.emptyTextFile }
  if (numberOfTextFiles > 1) { return messages.moreThanOneTextFile(numberOfTextFiles) }
  return messages.noMinFiles
}

export {
  messages,
  getMessage
}
