import PropTypes from 'prop-types'
import ErrorList from '../ErrorList/ErrorList'
import './ErrorPanel.scss'

const ErrorPanel = ({ errors }) => (
  <article className="error-panel">
    <section className="error-panel__info">
      <h2 className="error-panel__title">Problemas con el archivo txt</h2>
      <p>Hemos encontrado los siguientes errores en el archivo txt adjunto en esta inspección.</p>
      <p>Cancela y añade un nuevo archivo que solucione estos errores o, pulsa el botón continuar. Las imágenes que presenten problemas no se analizarán en esta inspección.</p>
    </section>

    <ErrorList
      errors={ errors }
      className="error-panel__list"
    />
  </article>
)

ErrorPanel.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
  })).isRequired,
}

export default ErrorPanel
