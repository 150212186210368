import PropTypes from 'prop-types'
import { useState, useContext, useEffect } from 'react'
import CropContext from 'adapter/context/CropContext'
import ImagesContext from 'adapter/context/ImagesContext'
import useInspectionDetail from 'hooks/models/inspection/useInspectionDetail'
import useRedirectToFiles from 'hooks/models/inspection/useRedirectToFiles'
import useDetectionsOfImage from 'hooks/models/detection/useDetectionsOfImage'
import useLayout from 'hooks/misc/useLayout'
import filterByLocation from 'domain/image'
import Wrapper, { edgeCases } from './layout/Wrapper/Wrapper'
import Map from './Map/Map'
import PhotoViewer from './PhotoViewer/PhotoViewer'
import AnomalyViewer from './AnomalyViewer/AnomalyViewer'
import './GeoDetail.scss'

export const PATH = 'inspection-geo'
export const PARAM = 'inspectionId'
export const pageName = 'Detalle de inspección'

const GeoDetail = ({ match, handlePathname }) => {
  const { params } = match
  const inspectionId = params[PARAM]

  useLayout({ path: PATH, handlePathname })

  const { isVerticalCrop } = useContext(CropContext)
  const {
    image,
    activePointIndex,
    setActivePointIndex,
    setImages,
    images
  } = useContext(ImagesContext)
  const [isPressed, setIsPressed] = useState(false)

  const { data: inspection, loading, error, refetch } = useInspectionDetail({ inspectionId })

  useRedirectToFiles({ status: inspection?.status, inspectionId })

  const { data: detections } = useDetectionsOfImage({ imageId: image?.id, skip: isPressed })
  const [isAsideVisible, setAsideIsVisible] = useState(false)
  const [detectionForm, setDetectionForm] = useState(null)
  const [loadingRefetch, setLoadingRefetch] = useState(false)

  useEffect(() => {
    const imagesFiltered = filterByLocation(inspection?.images)
    setImages(imagesFiltered)
    setLoadingRefetch(false)
  }, [inspection, setImages])

  const handleRefetch = (response) => {
    refetch()
    setLoadingRefetch(true)
  }

  const edgeResult = edgeCases({ inspectionId, data: inspection, error, loading })
  if (edgeResult) return edgeResult

  return (
    <Wrapper
      inspectionId={ inspectionId }
      isAsideVisible={ isAsideVisible }
      asidePanel={
        <AnomalyViewer
          inspectionId={ inspectionId }
          detectionForm={ detectionForm }
          isVerticalCrop={ isVerticalCrop }
          refetchInspection={ refetch }
          isAssignedUser={ inspection?.isAssignedUser }
          onRefetch={ handleRefetch }
          onClose={ () => setAsideIsVisible(false) }
        />
      }
    >
      <Map
        inspectionId={ inspectionId }
        inspectionName={ inspection?.name }
        images={ images }
        setActivePointIndex={ setActivePointIndex }
        onClick={ setActivePointIndex }
        onPress={ setIsPressed }
      />
      <PhotoViewer
        images={ images }
        detections={ detections }
        activePointIndex={ activePointIndex }
        username={ inspection?.assignedUser?.username }
        isAssignedUser={ inspection?.isAssignedUser }
        isPressed={ isPressed }
        loadingRefetch={ loadingRefetch }
        refetchInspection={ refetch }
        onLaunchForm={ (detectionForm) => {
          setDetectionForm(detectionForm)
          const isVisible = detectionForm?.url !== null || detectionForm?.currentDetection !== null
          setAsideIsVisible(isVisible)
        }}
      />
    </Wrapper>
  )
}

GeoDetail.propTypes = {
  match: PropTypes.object.isRequired,
  handlePathname: PropTypes.func.isRequired
}

export default GeoDetail
