import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Dates from 'ui/elements/Dates/Dates'
import Button from 'ui/elements/Button/Button'
import ProgressWithDot from 'ui/components/ProgressWithDot/ProgressWithDot'
import InspectionTitle from './InspectionTitle/InspectionTitle'
import './Inspection.scss'
import useRedirectToFiles from 'hooks/models/inspection/useRedirectToFiles'

const Inspection = ({
  path,
  inspection: {
    id,
    name,
    description,
    assignedUser,
    createdAt,
    progress,
    status,
  },
  totalDetections,
  onClick,
}) => {
  const history = useHistory()
  const testid = `-${status.toLowerCase()}` || ''
  const pathFiles = useRedirectToFiles({ status, inspectionId: id, onlyPath: true })

  const handleOpenDetail = () => {
    const detailPath = pathFiles || `/${path}/${id}`
    history.push(detailPath)
  }

  return (
    <tr
      id={ `inspection-item-${id}` }
      data-testid={ `inspection-item${testid}` }
      onClick={ () => { onClick(id)} }
      className='inspection-item'
    >
      <td className="inspection-item__date">
        <Dates className="text-left"
          isoDate={ createdAt } />
      </td>

      <td className="text-left">
        <InspectionTitle
          name={ name }
          description={ description }
        />
      </td>

      <td>{ assignedUser?.username }</td>

      <td className="inspection-item__progress">
        <ProgressWithDot
          progress={ progress }
          status={ status }
          totalDetections={ totalDetections || 0 }
        />
      </td>

      <td className="inspection-item__detail text-center">
        <Button
          variant="secondary"
          className="button-clean --regular"
          onClick={ handleOpenDetail }
        >Ir al detalle</Button>
      </td>
    </tr>
  )
}

Inspection.propTypes = {
  path: PropTypes.string,
  inspection: PropTypes.object,
  totalDetections: PropTypes.number,
  onClick: PropTypes.func,
}

export default Inspection
