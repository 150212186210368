import React from 'react'

function Image({ src, alt, variant, className, width, testid}) {
  return (
    <img
      className={`image ${variant && 'image--' + variant} ${className}`}
      src={src}
      alt={alt}
      width={width}
      data-testid={testid}
    />
  )
}

export default Image
