import { gql } from '@apollo/client'
import createInput from 'lib/mutations'

export const field = 'createInspection'

const INPUT = {
  name: 'string.isRequired',
  description: 'string',
  filesUploaded: 'array',
  userId: 'string.isRequired',
}

export const input = createInput(INPUT)

export const mutation = gql`
  mutation createInspection($input: CreateInspectionInput!) {
    createInspection(input: $input) {
      id
      name
      description
      createdAt
      progress
      accountId
      files {
        id
        name
        url
        contentType
        metadata {
          key
          value
        }
      }
      assignedUser {
        id
        username
      }
    }
  }
`
