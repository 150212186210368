import { useState, useCallback, useEffect } from 'react'
import { ACTIONS } from 'ui/pages/NewInspection/vendor/Uppy/UppyAPI'
import getGeolocationOfImages from 'domain/inspection/geolocation/getGeolocation'

function useFilesToBeUpload ({
  withoutTextFile,
  uploaderRef,
  isUploading,
  onRemoveFile,
  onCancel,
}) {
  const [filesToBeUploaded, setFilesToBeUploaded] = useState([])
  const [areSomeFilesAdded, setSomeFilesAdded] = useState(false)

  useEffect(() => {
    setSomeFilesAdded(filesToBeUploaded.length !== 0)
  }, [filesToBeUploaded])

  const handleRemoveFile = useCallback((file) => {
    if (isUploading) return
    uploaderRef[ACTIONS.REMOVE_FILE](file.id)
    setFilesToBeUploaded(filesToBeUploaded.filter(({ id }) => id !== file.id))
    onRemoveFile(file.type)
  }, [uploaderRef, filesToBeUploaded, setFilesToBeUploaded, onRemoveFile, isUploading])

  const handleAddingFilesToUpload = useCallback(async (files) => {
    if (isUploading) return
    let parsedFiles = [...files]
    if (withoutTextFile) {
      const imagesWithLocation = await getGeolocationOfImages(files)
      parsedFiles = imagesWithLocation
      setSomeFilesAdded(true)
      setFilesToBeUploaded((existingFiles) => [...parsedFiles, ...existingFiles])
      return
    }
    setSomeFilesAdded(true)
    setFilesToBeUploaded((existingFiles) => [...parsedFiles, ...existingFiles])
  }, [withoutTextFile, setFilesToBeUploaded, setSomeFilesAdded, isUploading])

  const handleCancel = useCallback(async (filesCancelled, { withReset } = { withReset: true }) => {
    setFilesToBeUploaded([])
    await uploaderRef[ACTIONS.CANCEL]()
    filesCancelled.forEach(uploaderRef[ACTIONS.ADD_FILE])
    if (!withReset) { return }
    setFilesToBeUploaded(filesCancelled)
    onCancel()
  }, [uploaderRef, setFilesToBeUploaded, onCancel])

  return {
    files: {
      toBeUpload: filesToBeUploaded,
      areSomeAdded: areSomeFilesAdded,
    },
    isUploading,
    actions: {
      removeFile: handleRemoveFile,
      addFile: handleAddingFilesToUpload,
      cancelUpload: handleCancel,
    },
  }
}

export default useFilesToBeUpload

