import React from 'react'
import { NavLink } from 'react-router-dom'
import './Brand.scss'

function Brand() {
  return (
    <div className="brand">
      <NavLink to="/" className="brand__link">
        <img
          className="brand__logo"
          src="/assets/images/unusuals-logo.svg"
          alt="unusuals logo"
        />
        <h1 className="brand__name">Unusuals</h1>
        <h2 className="brand__slogan">Data Driven Industrial</h2>
      </NavLink>
    </div>
  )
}

export default Brand
