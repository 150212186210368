import PropTypes from 'prop-types'
import { useEffect } from 'react'
import './Marker.scss'

const Marker = ({
  text,
  active,
  onClick,
  children,
  $hover,
  handleHoverCallback
}) => {
  const hoverClassName = $hover ? '--hover': ''

  useEffect(() => {
    handleHoverCallback($hover)
  }, [$hover, handleHoverCallback])

  return (<>
    <div
      className={ `marker ${hoverClassName}` }
      data-active={ active }
      onClick={ onClick }
    >{ text }</div>
    { $hover && children }
  </>)
}

Marker.propTypes = {
  text: PropTypes.number,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  $hover: PropTypes.bool,
  handleHoverCallback: PropTypes.func
}

export default Marker
