import PropTypes from 'prop-types'
import useAddClasses from 'hooks/dom/useAddClasses'
import './ImageViewer.scss'

export const Skeleton = ({ isInactive }) => {
  const className = useAddClasses(['file-modal__skeleton'],
    [isInactive && 'is-inactive'])
  return <span className={ className }></span>
}

const ImageViewer = ({ src, skeleton }) => {
  const className = useAddClasses(['image-viewer'],
    [skeleton.isSkeleton && 'is-inactive'])
  return (<>
    <Skeleton isInactive={ !skeleton.isSkeleton } />
    <img src={ src }
      className={ className }
      alt="Imagen"
      width="600"
      height="400"
      onLoad={ () => { skeleton.setIsSkeleton(false) } }
    />
  </>)
}

ImageViewer.propTypes = {
  src: PropTypes.string,
  skeleton: PropTypes.shape({
    isSkeleton: PropTypes.bool,
    setIsSkeleton: PropTypes.func
  })
}

export default ImageViewer
