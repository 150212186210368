import { gql } from '@apollo/client'

export const IMAGES = gql`
  fragment ImagesFragment on Inspection {
    images {
      id
      key
      mediumKey
      compressedKey
      smallKey
      width
      height
      file {
        id
        name
        url
        contentType
        metadata {
          key
          value
        }
      }
      metadata {
        key
        value
      }
      position {
        latitude
        longitude
      }
    }
  }
`
