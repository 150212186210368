import PropTypes from 'prop-types'
import { useEffect, useState, useContext, useCallback } from 'react'
import SkeletonTable from 'ui/elements/Table/SkeletonTable/SkeletonTable'
import DownloadFiles from 'ui/components/DownloadFiles/DownloadFiles'
import SettingsContext from 'adapter/context/SettingsContext'
import { belongsToGeolocatedImage } from 'domain/detection'
import getDetectionForm from '../../GeoDetail/AnomalyViewer/Form/types/getDetectionForm/getDetectionForm'
import DetectionsEmpty from './Empty/DetectionsEmpty'
import ModalImageWithoutLocation from './Modal/DetectionModal'
import Detection from './Detection/Detection'
import './Detections.scss'

const getOrder = (detection, index) => {
  const order = detection?.images[0]?.order
  const isNumber = Number.isInteger(order)
  if (isNumber) { return Number(order + 1) }
  return index + 1
}

const Detections = ({
  detections,
  date,
  filesToDownload,
  isAssignedUser,
  loading,
  onLaunchForm
}) => {
  const [isLoading, setIsLoading] = useState(loading)
  const [isModalWithoutLocationOpen, setModalWithoutLocationOpen] = useState(false)
  const settings = useContext(SettingsContext)
  const multipleLevelCatalog = settings?.multipleLevelCatalog || false
  const isEmpty = !detections || detections.length === 0
  const haveFilesToDownload = filesToDownload?.length > 0

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  const handleOpenDetection = useCallback((detection) => {
    const detectionForm = getDetectionForm({
      isAssignedUser,
      currentDetection: detection,
      detectionsToSearch: detections,
      callbackToFind: (detectionItem, detectionToCompare) =>
        detectionItem.rois[0].id === detectionToCompare.rois[0].id
    }, 'key')

    if (belongsToGeolocatedImage(detection)) {
      onLaunchForm(detectionForm)
    } else {
      setModalWithoutLocationOpen(true)
    }
  }, [isAssignedUser, detections, onLaunchForm, setModalWithoutLocationOpen])

  const numberOfCells = multipleLevelCatalog ? 8 : 6

  return (<>
    { haveFilesToDownload && (
      <section className='detections-table__headline fixed'>
        <DownloadFiles
          files={ filesToDownload }
          isLegacy={ false }
        />
      </section>
    ) }
    <table className='detections-table table table-simplified'>
      <thead className={ `fixed ${!haveFilesToDownload && "--is-single"}` }>
        <tr>
          <th width="5%">Id</th>
          <th className="text-left nowrap" width="8%">Fecha/Hora</th>
          { multipleLevelCatalog && <th className="nowrap">Tipo de objeto</th> }
          <th className="text-left">Conjunto</th>
          <th className="text-left">Operación</th>
          { multipleLevelCatalog && <th className="text-center nowrap">Valor PM/Grado repercusión</th> }
          <th className="text-center">Validación</th>
          <th>Imagen</th>
        </tr>
      </thead>

      <tbody>

        { (isEmpty && !isLoading) && <DetectionsEmpty numberOfCells={ numberOfCells } /> }

        { detections.map((detection, index) => (
          <Detection
            key={ `detection-${index}-${detection.id}` }
            order={ getOrder(detection, index) }
            date={ date }
            detection={ detection }
            isMultipleLevelCatalog={ multipleLevelCatalog }
            onClick={ () => handleOpenDetection(detection) }
          />
        ))}
        { loading && (<SkeletonTable
          numberOfCells={ numberOfCells }
          numberOfRows={ 5 }
        />) }
      </tbody>
    </table>

    <ModalImageWithoutLocation
      isOpen={ isModalWithoutLocationOpen }
      setIsOpen={ setModalWithoutLocationOpen }
    />
  </>)
}

Detections.propTypes = {
  detections: PropTypes.array,
  date: PropTypes.string,
  filesToDownload: PropTypes.array,
  isAssignedUser: PropTypes.bool,
  loading: PropTypes.bool,
  onLaunchForm: PropTypes.func,
}

export default Detections
