import { useState, useEffect } from 'react'
import { isANumber } from 'lib/types'

const INITIAL_SCALE = 1

function getCropperWidth (cropper) {
  if (!cropper) return INITIAL_SCALE
  const imageData = cropper?.initialImageData
  if (!imageData?.naturalWidth) return 'error'
  return imageData.naturalWidth
}

const useCalculateScale = ({
  progressiveCropper,
  originalCropper,
  isLoaded,
}) => {
  const [scale, setScale] = useState(INITIAL_SCALE)

  useEffect(() => {
    if (!originalCropper || !progressiveCropper) {
      setScale(INITIAL_SCALE)
      return
    }
    const originalWidth = getCropperWidth (originalCropper)
    const progressiveWidth = getCropperWidth (progressiveCropper)
    if (!isANumber(originalWidth) || !isANumber(progressiveWidth)) {
      setScale(INITIAL_SCALE)
      return
    }
    setScale(originalWidth / progressiveWidth)
  }, [isLoaded, originalCropper, progressiveCropper])

  return scale
}

export default useCalculateScale
