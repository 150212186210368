import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import './DrawZoom.scss'

export const ZOOM_MIN = 0.046
export const ZOOM_MAX = 20
export const ZOOM_INTERVAL = 0.1

const DrawZoom = ({
  containerRef,
  element,
  onReset,
  isLoading,
  className
}) => {
  if (!containerRef || !element) return null
  return (
    ReactDOM.createPortal(
      <section className={`zoom ${className}`}>
        <nav className="zoom__nav">
          <button
            className="zoom__btn --reload"
            disabled={ isLoading }
            onClick={ onReset }
          >
            <span className="icon--reload"></span>
          </button>
          <section className="zoom__controls">
            <button
              className="zoom__btn --in"
              onClick={ () => { element.zoom(ZOOM_INTERVAL) }}
              disabled={ isLoading }
            ><span className="icon--plus"></span>
            </button>
            <button
              className="zoom__btn --out"
              onClick={ () => { element.zoom(-ZOOM_INTERVAL) }}
              disabled={ isLoading }
            ><span className="icon--minus"></span>
            </button>
          </section>
        </nav>
      </section>,
      containerRef.current
    )
  )
}

DrawZoom.propTypes = {
  containerRef: PropTypes.object,
  element: PropTypes.object,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
}

export default DrawZoom
