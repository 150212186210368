import PropTypes from 'prop-types'
import { useMemo } from 'react'
import Button from 'ui/elements/Button/Button'
import { showSuccess, showError } from 'ui/elements/Toast/Toast'
import useDeleteInspectionQueue from 'hooks/models/inspection/useDeleteInspectionQueue'
import './DeleteInspections.scss'

function parseQueueById (value) {
  if (!value || value === '') return []
  const splited = value.split(',')
  if (splited.length === 1) return [splited[0]]
  const splitedTrimmed = splited.map(item =>
    item.trim().replace(/'|"/g, ''))
  return splitedTrimmed.filter(item => item !== '')
}

function DeleteInspections ({ inspectionIds, setInspectionIds, onCompleted }) {
  const queueById = useMemo(() => parseQueueById(inspectionIds), [inspectionIds])

  const { launchQueue } = useDeleteInspectionQueue({
    queueById,
    onCompleted: (result) => {
      console.info('Removed: ', result)
    },
    onError: (error) => {
      showError(error.toString())
      console.error('Error removing: ', error)
    },
    onAllCompleted: () => {
      setInspectionIds('')
      showSuccess('Inspecciones eliminadas')
      onCompleted()
    }
  })

  const handlerChange = (event) => {
    setInspectionIds(event.target.value)
  }

  return (
    <section className="delete-inspections">
      <textarea
        value={ inspectionIds }
        rows={ 1 }
        cols={ 50 }
        placeholder="Inserte los ids de las inspecciones a eliminar, separados por comas"
        onChange={ handlerChange }
      />
      <Button
        variant="none"
        className="button--secondary button-clean --failure"
        disabled={ !queueById || queueById.length === 0 }
        onClick={ launchQueue }
      >Borrar inspecciones</Button>
    </section>
  )
}

DeleteInspections.propTypes = {
  inspectionIds: PropTypes.string.isRequired,
  setInspectionIds: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired
}

export default DeleteInspections
