import { useState } from 'react'
import Modal, { closeModal, openModal } from 'ui/elements/Modal/Modal'
import Button from 'ui/elements/Button/Button'
import ReviewHeader from 'ui/pages/Collection/Review/ReviewHeader/ReviewHeader'
import Polygon from '../Polygon/Polygon'
import Rois from '../Rois/Rois'
import Time from '../Time/Time'
import './Validator.scss'

const mod = "validator"

function Validator({ index, item, validate, entries }) {
  const [categoryStatus, setCategoryStatus] = useState(item.validation ? item.validation.categoryStatus : undefined)
  const [typeStatus, setTypeStatus] = useState(item.validation ? item.validation.anomalyTypeStatus : undefined)
  const [detectionObservations, setDetectionObservations] = useState(item.validation ? item.validation.observations : undefined)
  const [doneSubmit, setDoneSubmit] = useState(false)
  const [hasChange, setHasChange] = useState(false)

  let preDate = item.validation ? item.validation.validatedAt : ""
  const setCategoryStatusHandler = (e) => {
    setCategoryStatus(e.target.value)
    setDoneSubmit(false)
    setHasChange(true)
  }
  const setTypeStatusHandler = (e) => {
    setTypeStatus(e.target.value)
    setDoneSubmit(false)
    setHasChange(true)
  }
  const setDetectionObservationsHandler = (e) => {
    setDetectionObservations(e.target.value)
    setDoneSubmit(false)
    setHasChange(true)
  }

  // Next
  const range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx)

  const findNext = (n) => {
    let i = Number(n) + 1;
    let nextAnomaly = `${mod}-${i}`

    if (document.querySelector("#" + nextAnomaly)) openModal(nextAnomaly)
  }

  const openNext = (ent, i) => {
    let l = ent.length - 1;
    let ary = [...range(i + 1, l), ...Array(i).keys()]

    for (let a of ary) {
      if (ent[a].validation && ent[a].validation.status) { } else {
        findNext(a)
        return
      }
    }
  }

  // Submit
  const onSubmit = (e, id, modalID) => {
    setDoneSubmit(true)
    validate({
      detectionId: id,
      categoryStatus: categoryStatus,
      anomalyTypeStatus: typeStatus,
      observations: detectionObservations,
    })

    setTimeout(() => {
      closeModal(modalID)
      setDoneSubmit(undefined)
      setHasChange(undefined)
      openNext(entries, index)
    }, 800)
  }

  const isPolygon = item.frames[0].polygons.length > 0
  const isRoi = item.frames[0].rois.length > 0

  return (
    <Modal
      id={`${mod}-${index + 1}`}
      className={`${mod}`}
      trigger={false}
    >
      <div className={`${mod}__container`}>
        <div className={`${mod}__col-1`}>
          <ReviewHeader
            identifier={ item?.id || '' }
            anomaly={ item.anomaly }
            mod={ mod }
          />
          <div className={`${mod}__image`}>
            <img
              src={item.frames[0].mediumUrl}
              width="740"
              image="Imagen a revisar"
            />

            { (isRoi) ? item.frames[0].rois.map((roi, i) => (
              <Rois
                key={`roi-${index}-${i}`}
                coordinates={ roi }
                width={ item.frames[0].width }
                height={ item.frames[0].height }
              />
            )) : ((isPolygon) ? item.frames[0].polygons.map((polygon, i) => (
              <Polygon
                id={`${index}-${i}`}
                key={`polygon-${index}-${i}`}
                height={item.frames[0].height}
                width={item.frames[0].width}
                points={polygon.points}
              />
            )) : (""))}
          </div>
        </div>
        <div className={`${mod}__col-2`}>
          <form className={`${mod}__form`}>
            <p>¿Es correcto el <b>conjunto</b> detectado?</p>
            <p className={`${mod}__fieldset`} id={`${mod}__form__category__${item.id}`} onChange={setCategoryStatusHandler} >

              <input
                type="radio"
                name={`${mod}__form__category__${item.id}`}
                id={`${mod}__form__category__correct__${item.id}`}
                value="CORRECT"
                defaultChecked={categoryStatus === "CORRECT"}
              />
              <label
                htmlFor={`${mod}__form__category__correct__${item.id}`}
              >Sí
              </label>

              <input
                type="radio"
                name={`${mod}__form__category__${item.id}`}
                id={`${mod}__form__category__incorrect__${item.id}`}
                value="INCORRECT"
                defaultChecked={categoryStatus === "INCORRECT"}
              />
              <label
                htmlFor={`${mod}__form__category__incorrect__${item.id}`}
              >No
              </label>
              <input
                type="radio"
                name={`${mod}__form__category__${item.id}`}
                id={`${mod}__form__category__doubt__${item.id}`}
                value="DOUBT"
                defaultChecked={categoryStatus === "DOUBT"}
              />
              <label htmlFor={`${mod}__form__category__doubt__${item.id}`}>
                No lo sé
              </label>
            </p>

            <p>¿Es correcto el <b>defecto</b> detectado en el conjunto?</p>
            <p className={`${mod}__fieldset`}
              id={`${mod}__form__type__${item.id}`}
              onChange={setTypeStatusHandler} >

              <input
                type="radio"
                name={`${mod}__form__type__${item.id}`}
                id={`${mod}__form__type__correct__${item.id}`}
                value="CORRECT"
                defaultChecked={typeStatus === "CORRECT"}
              />
              <label
                htmlFor={`${mod}__form__type__correct__${item.id}`}
              >Sí
              </label>

              <input
                type="radio"
                name={`${mod}__form__type__${item.id}`}
                id={`${mod}__form__type__incorrect__${item.id}`}
                value="INCORRECT"
                defaultChecked={typeStatus === "INCORRECT"}
              />
              <label
                htmlFor={`${mod}__form__type__incorrect__${item.id}`}
              >No
              </label>
              <input
                type="radio"
                name={`${mod}__form__type__${item.id}`}
                id={`${mod}__form__type__doubt__${item.id}`}
                value="DOUBT"
                defaultChecked={typeStatus === "DOUBT"}
              />
              <label htmlFor={`${mod}__form__type__doubt__${item.id}`}>
                No lo sé
              </label>
            </p>


            <p>¿Algún comentario que nos ayude?</p>
            <textarea
              id={`${mod}__form__info__${item.id}`}
              name={`${mod}__form__info__${item.id}`}
              placeholder="Anotaciones"
              onChange={setDetectionObservationsHandler}
              defaultValue={detectionObservations}
            ></textarea>

            <Button
              type="button"
              disabled={!categoryStatus || !typeStatus || !hasChange || doneSubmit}
              onClick={(e) =>
                onSubmit(e, item.id, `${mod}-${index + 1}`)
              }
            >
              {doneSubmit ? '...' : 'Validar'}
            </Button>

            {preDate && (
              <p className="note">La anomalía fue validada el <Time date={preDate} /></p>)
            }
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default Validator
