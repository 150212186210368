import Video from 'ui/elements/Video/Video'
import Image from 'ui/elements/Image/Image'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Modal, { openModal } from 'ui/elements/Modal/Modal'
import "./DetailSource.scss"

const mod = "detail-source"

function DetailSource ({ inspection }) {

  const pauseVideo = (vidID) => {
    let vid = document.querySelector(vidID)
    if (vid) {
      vid.pause()
      vid.currentTime = 0
    }
  }

  return (
    <div className={`${mod}`}>
      <ul>
        { inspection?.images?.map((image, index) => {
          const file = image.file;
          if (isImage(file)) {
            return (
              <li key={`${mod}-entry-${index}`}>
                <Tooltip label={file.name} scroll={false} >
                  <figure
                    className={`${mod}__thumb`}
                    onClick={() => openModal(`${mod}-player-${file.id}`)}
                  >
                    <img data-testid={`thumbnail-${file.id}`}
                      className={`${mod}__img`}
                      src={image.mediumUrl}
                      alt={`${inspection.name} - ${file.name} - thumbnail`}
                    />
                  </figure>
                </Tooltip>
                <Modal
                  id={`${mod}-player-${file.id}`}
                  className={`${mod}__player`}
                  trigger={false}
                >
                  <h2 className={`${mod}__title`}>{ inspection.name }</h2>
                  <div className={`${mod}__content`}>
                    <i className={`${mod}__description`}>{ inspection.description }</i>
                  </div>
                  <Image src={image.mediumUrl} width="740" testid={`modal-image-${file.id}`} />
                </Modal>
              </li>
            )
          }
          return null
        })}
        {/* FIXME: Currently the backend respond with an empty collection for videos*/}
        { inspection?.files?.map((file, index) => {
          if (isVideo(file)) {
            return (
              <li key={`${mod}-entry-${index}`}>
                <Tooltip label={file.name} scroll={false}>
                  <p
                    className={`${mod}__thumb --video`}
                    onClick={() => openModal(`${mod}-player-${file.id}`)}
                    title={file.name}
                  >
                    <span className={`${mod}__img`}><i>{file.name}</i></span>
                  </p>
                </Tooltip>
                <Modal
                  id={`${mod}-player-${file.id}`}
                  className={`${mod}__player`}
                  trigger={false}
                  onExit={() => pauseVideo(`#${mod}-player-${file.id} video`)}
                >
                  <h2 className={`${mod}__title`}>{ inspection.name }</h2>
                  <div className={`${mod}__content`}>
                    <i className={`${mod}__description`}>{ inspection.description }</i>
                  </div>
                  <Video src={file.url} width="740" />
                </Modal>
              </li>
            )
          }

          if (isFile(file)) {
            return (
              <li key={`${mod}-entry-${index}`}>
                <Tooltip label={file.name} scroll={false}>
                  <a
                    className={`${mod}__thumb --file`}
                    href={file.url}
                  >
                    <span className={`${mod}__img`}><i>{file.name}</i></span>
                  </a>
                </Tooltip>
              </li>
            )
          }
          return null
        })}
      </ul>
    </div>
  )
}

function isVideo(file) {
  return file.contentType && file.contentType.toLowerCase().startsWith("video/")
}

function isImage(file) {
  return file.contentType && file.contentType.toLowerCase().startsWith("image/")
}

function isFile(file) {
  return file.contentType && file.contentType.toLowerCase().startsWith("text/")
}

export default DetailSource
