import { useQuery } from '@apollo/client'
import {
  DETECTIONS_OF_IMAGE_QUERY as query,
  fieldDetectionsOfImage as field
} from 'infra/inspection/detections.query'

export default function useDetectionsOfImage ({ imageId, skip }) {
  const { data, loading, error } = useQuery(query, {
    skip: !imageId || skip,
    variables: {
      imageId
    }
  })

  return {
    data: data?.[field],
    loading,
    error
  }
}
