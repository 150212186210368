import { useQuery } from '@apollo/client'
import { addNameField } from 'infra'
import USERS_QUERY, { field } from 'infra/users/users.query'

function useGetUsers () {
  const { data, loading, error } = useQuery(USERS_QUERY)

  const users = data?.[field]
  const parsedUsers = (users && addNameField(users, ({ username }) => username)) || []

  return {
    data: parsedUsers,
    loading,
    error
  }
}

export default useGetUsers
