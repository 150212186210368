import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import './Layout.scss'

const Layout = ({ isLoading, children }) => {
  const [current, setCurrent] = useState('home')
  const location = useLocation()

  useEffect(() => {
    setCurrent(location.pathname.split('/').pop())
  }, [location])

  return (
    <div className={`layout layout--${current}`}>
      { isLoading && <div className="layout__loading" /> }
      { !isLoading && children }
    </div>
  )
}

export default Layout
