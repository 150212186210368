import PropTypes from 'prop-types'
import './Progress.scss'

const Progress = ({ value }) => (
  <section className="inspection-progress">
    <div className="inspection-progress__bar">
      <span
        className="inspection-progress__value"
        style={{ width: `${value}%` }}
      >{ value }%</span>
    </div>
  </section>
)

Progress.propTypes = {
  value: PropTypes.number
}

export default Progress
