import { gql } from '@apollo/client'
import { CATEGORIES } from './categories.fragment'

export const field = 'categories'

export const CATEGORIES_QUERY = gql`
  ${ CATEGORIES }
  query getCategories {
    categories {
      ...CategoriesFragment
    }
  }
`
export const CATEGORIES_QUERY_WITH_HIERARCHY = gql`
  ${ CATEGORIES }
  query getCategoriesWithHierarchy($hierarchy: Boolean!) {
    categories(hierarchy: $hierarchy) {
      ...CategoriesFragment
      subcategories {
        id
        name
        externalReference
      }
    }
  }
`

export const CATEGORIES_QUERY_ONLY_HIERARCHY = gql`
  ${ CATEGORIES }
  query getCategoriesOnlyHierarchy($hierarchy: Boolean!) {
    categories(hierarchy: $hierarchy) {
      ...CategoriesFragment
    }
  }
`
