import Table from 'ui/elements/Table/Table'
import { openModal } from 'ui/elements/Modal/Modal'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import Validator from '../Validator/Validator'
import './Anomalies.scss'

//SET
const mod = 'anomalies'

const headers = [
  { label: 'Id', id: 'index' },
  { label: 'Conjunto', id: null },
  { label: 'Tipo de fallo',id: null },
  { label: 'Validación', id: null },
  { label: 'Imagen', id: null }
]

const txtTooltip = (category, type) => (
  <p>
    <span>Validación:</span>
    <br />Conjunto <i className={category}>{txtStatus(category)}</i>
    <br />Tipo de fallo <i className={type}>{txtStatus(type)}</i>
  </p>
)

const txtStatus = (val) => (
  val === "CORRECT" ? "Correcto" :
  val === "INCORRECT" ? "Incorrecto" :
  val === "DOUBT" ? "En duda" : '---'
)

function Anomalies ({ entries, className = '', validate }) {
  return (
    <Table
      className={`${mod} ${className}`}
      headers={ headers }
    >
      { entries.map((item, index) => {

        const preValidated = item.validation
        let categoryStatus = preValidated ? preValidated.categoryStatus : ""
        let typeStatus = preValidated ? preValidated.anomalyTypeStatus : ""
        let status = preValidated ? preValidated.status : ""

        return (
          <tr key={item.id} id={`${mod}-entry-${index + 1}`}>
            <td className={`${mod}__id`}>{ item?.id || '' }</td>

            <td className={`${mod}__category`}>
              <dl>
                <dt>{item.anomaly.category && item.anomaly.category.name}</dt>
                <dd>{item.anomaly.category && item.anomaly.category.externalReference}</dd>
              </dl>
            </td>
            <td className={`${mod}__type`}>
              <dl>
                <dt>{item.anomaly.type && item.anomaly.type.name}</dt>
                <dd>{item.anomaly.type && item.anomaly.type.externalReference}</dd>
              </dl>
            </td>
            <td className={`${mod}__status`}>
              <Tooltip label={txtTooltip(categoryStatus, typeStatus)}>
                <span className={status}>{txtStatus(status)}</span>
              </Tooltip>
            </td>
            <td className={`${mod}__detail`}>
              { item.crop?.smallUrl && (
                <figure
                  className={`${mod}__thumb`}
                  onClick={() => openModal(`validator-${index + 1}`)}
                >
                  <img
                    className={`${mod}__img`}
                    src={ item.crop?.smallUrl }
                    alt={`${item.type} - thumbnail`}
                  />
                </figure>
              )}
              <Validator validate={validate} mod={mod} index={index} item={item} entries={entries} />
            </td>
          </tr>
        )
      })}
    </Table>
  )
}

export default Anomalies
