import { useQuery } from '@apollo/client'
import { field, query } from 'infra/inspection/checkGeolocationFiles.query'
import checkErrorsLocally from 'domain/inspection/errors/checkErrorsLocally'

function useCheckGeolocationFile ({ isLocal, fileIds, files, isAllFilesUploaded }) {
  const notFileIds = !fileIds || fileIds?.length === 0 || !files

  const { data, loading, error } = useQuery(query, {
    skip: isLocal || notFileIds || !isAllFilesUploaded,
    variables: {
      fileIds: fileIds.map(({ id }) => id)
    }
  })

  const checkErrors = isLocal ? checkErrorsLocally(files) : data?.[field] || []
  const haveCheckErrors = checkErrors?.length > 0

  return {
    checkErrors,
    haveCheckErrors,
    loaded: isAllFilesUploaded && !loading && !error,
    loading,
    error
  }
}

export default useCheckGeolocationFile
