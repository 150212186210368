import './Tooltip.scss'

const Tooltip = ({ className, children }) => {
  const classes = ['map-tooltip', className].join(' ')
  return (<>
    <section
      className={ classes }
    >{ children }</section>
    <span className="tooltip-triangle" />
  </>
)}

export default Tooltip
