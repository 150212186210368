import { memo } from 'react'
import useGetCategories from 'hooks/models/categories/useGetCategories'
import "./Filter.scss"

const Filter = memo(({ selected, onChange, className }) => {
  const { data: dataCategories, loading, error } = useGetCategories()

  if (loading) return <p>Cargando filtro...</p>
  if (error) {
    console.error(error)
    return <p>Error</p>
  }
  if (!dataCategories) { return <p>Not found</p> }
  const categories = Object.values(dataCategories)
  const sortedCategories = categories.sort((a, b) => (a.externalReference > b.externalReference) ? 1 : -1)

  return (
    <div className={`filter ${className}`}>
      <label
        htmlFor="filter-category"
        className="filter__label"
      >Filtro de conjunto:</label>
      <select
        id="filter-category"
        className="filter__field"
        value={ selected }
        onChange={ onChange }
      >
        <option value="all">Todos</option>
        { sortedCategories.map((category, index) => (
            <option
              key={`filter-${index}`}
              value={ category.externalReference }
            >
              { category.externalReference} • {category.name}
            </option>
        ))}
      </select>
    </div>
  )
})

export default Filter
