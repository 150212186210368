const TOAST_MESSAGES = {
  ERROR: 'Se ha producido un error al intentar añadir el defecto. Pulse de nuevo aceptar.',
  CANCEL: 'Se ha descartado el defecto',
  SUCCESS: 'El defecto se ha incluido correctamente',
  CATEGORY_ERROR: 'Se ha producido un error al intentar añadir el conjunto. Pulse de nuevo aceptar.',
  CATEGORY_CANCEL: 'Se ha descartado el conjunto',
  CATEGORY_SUCCESS: 'El conjunto se ha incluido correctamente'
}

export default TOAST_MESSAGES
