import { useQuery, gql } from '@apollo/client'

const field = 'urlOfKey'

const query = gql`
  query urlOfKey($key: String!) {
    urlOfKey(key: $key) {
      url
    }
  }
`

function useUrlOfKey ({ key }) {
  const { data, loading, error } = useQuery(query, {
    skip: !key,
    variables: { key }
  })

  return {
    data: data?.[field].url,
    loading,
    error
  }
}

export default useUrlOfKey
