import SimpleModal from 'ui/components/SimpleModal/SimpleModal'

const ModalImageWithoutLocation = ({ isOpen, setIsOpen }) => (
  <SimpleModal
    isOpen={ isOpen }
    title="Foto sin geolocalización"
    onRequestClose={ () => setIsOpen(false) }
  >
    <p>Esta foto no dispone de geolocalización por lo que no es posible mostrar el detalle del defecto.</p>
  </SimpleModal>
)

export default ModalImageWithoutLocation
