const messages = {
  debug: 'Subida de imágenes (sin fichero .txt)',
  addFile: 'Añadir ficheros',
  empty: {
    title: 'Sin archivos',
    description: `
      Para realizar una inspección es necesario incluir al menos una imagen
      en formato jpg o jpeg.
    `
  },
  noMinFiles: 'Incluye al menos una imagen.',
}

const getMessage = (files) => {
  if (files.length >= 1) return null
  return messages.noMinFiles
}

export {
  messages,
  getMessage
}
