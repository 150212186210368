import useLayout from 'hooks/misc/useLayout'
import Sidebar from 'ui/components/Sidebar/Sidebar'
import './NotFound.scss'

export const PATH = 'not-found'
export const pageName = "Página no encontrada"

const NotFound = ({ handlePathname }) => {
  useLayout({ path: PATH, force: true, handlePathname })

  return (
    <section className="not-found">
      <Sidebar />
      <article className="not-found__container">
        <h1>Página no encontrada.</h1>
      </article>
    </section>
  )
}

export default NotFound
