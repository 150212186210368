export { default as checkShape, allOfType } from './shape/shape'
export * from './blobToBase64'

export const DEFAULT_IDENTIFIER = 'unknown'

export const getErrorMessage = (propName, componentName) => 'Invalid prop `' + propName + '` supplied to' +
  ' `' + componentName + '`. Validation failed.'

export const isString = (value) =>
  (typeof value === 'string' || value instanceof String)

export const isArray = (value) => Array.isArray(value)

export const isObject = (value) =>
  Object.prototype.toString.call(value) === '[object Object]'

export const isAFunction = (property) => (typeof property === 'function')

export const isANumber = (value) => (typeof value === 'number' || value instanceof Number)
