import PropTypes from 'prop-types'
import SimpleModal from 'ui/components/SimpleModal/SimpleModal'
import Progress from 'ui/pages/NewInspection/elements/Modal/Progress/Progress'

const CreateInspectionModal = ({ isOpen, progress }) => (
  <SimpleModal
    isOpen={ isOpen }
    title="Creando inspección"
    className="upload-modal"
    mandatory={ true }
  >
    <p>Son solo unos segundos...</p>
    <Progress value={ progress } />
  </SimpleModal>
)

CreateInspectionModal.propTypes = {
  isOpen: PropTypes.bool,
  progress: PropTypes.number,
}

export default CreateInspectionModal
