import { useEffect } from 'react'

const TITLE_BY_DEFAULT = 'Unusuals'

const useDocumentTitle = ({ title } = { title: TITLE_BY_DEFAULT }) => {
  useEffect(() => {
    document.title = title || TITLE_BY_DEFAULT
  }, [title])
}

export default useDocumentTitle
