import { ERRORS } from 'domain/inspection/errors/Errors'

const hasGeolocation = (file) => {
  if (!file?.geolocation) return false
  return true
}

const checkGeolocationFiles = (files) => {
  const associatedItems = []
  files.forEach((file) => {
    if (hasGeolocation(file)) return
    associatedItems.push(file?.name)
  })
  return associatedItems
}

const checkNoGeolocationInAllFiles = (files, associatedItems) => {
  const length = files.length
  if (associatedItems.length === 0) return []
  if (length !== associatedItems.length) return []
  return [{
    code: ERRORS.NO_GEOLOCATION_FILE.code,
    associatedItems: ['all'],
  }]
}

const checkErrorsLocally = (files) => {
  const associatedItems = checkGeolocationFiles(files)
  const errorInAllFiles = checkNoGeolocationInAllFiles(files, associatedItems)
  if (errorInAllFiles.length > 0) return errorInAllFiles
  if (associatedItems.length > 0) return [{
    code: ERRORS.NO_GEOLOCATION_IN_SOME_FILE.code,
    associatedItems,
  }]
  return []
}

export default checkErrorsLocally
