const UNIT = '%'

const parseCoordinates = ({ x1, y1, x2, y2 }, width, height) => {
  const left = (x1 * 100) / width
  const top = (y1 * 100) / height
  const widthParsed = (x2 * 100) / width - left
  const heightParsed = (y2 * 100) / height - top

  return {
    left: left + UNIT,
    top: top + UNIT,
    width: widthParsed + UNIT,
    height: heightParsed + UNIT,
  }
}

export default parseCoordinates
