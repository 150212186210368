import PropTypes from 'prop-types'
import { useState, useContext, useCallback } from 'react'
import Button from 'ui/elements/Button/Button'
import SplitPanelContext from 'adapter/context/SplitPanelContext'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import DetectionsContext from 'adapter/context/DetectionsContext'
import isStillAssignedUser from 'infra/users/isStillAssignedUser'
import Summary from '../Summary/Summary'
import Info from '../Info/Info'
import useImageInfo from '../Hooks/imageInfo/useImageInfo'
import AddDetectionButton from './AddDetectionButton/AddDetectionButton'
import './PhotoActions.scss'

const PhotoActions = ({
  activePointIndex,
  image,
  detections,
  label,
  username,
  isAssignedUser,
  isLoading,
  isOriginalLoaded,
  refetchInspection,
}) => {
  const [isStillAssignedUserLoading, setIsStillAssignedUserLoading] = useState(false)
  const imageInfo = useImageInfo({ image })
  const { isSplitViewOpened, isMapMinified } = useContext(SplitPanelContext)
  const { cropStatus, setCropStatus } = useContext(CropContext)
  const { typeDetection } = useContext(DetectionsContext)
  const handleStartDrawing = useCallback(async () => {
    setIsStillAssignedUserLoading(true)
    await isStillAssignedUser ({
      trigger: refetchInspection,
      onSuccess: () => { setCropStatus(CROP_STATUS.READY_TO_DRAW) },
      onFailure: () => { }
    })
    setIsStillAssignedUserLoading(false)
  }, [setCropStatus, refetchInspection, setIsStillAssignedUserLoading])

  const handleCancelDrawing = useCallback(() => {
    setCropStatus(CROP_STATUS.LOADED)
  }, [setCropStatus])

  const showCancelDrawing = [CROP_STATUS.READY_TO_DRAW].includes(cropStatus)
  const isPanelCorrectToAddDetection = (isSplitViewOpened || isMapMinified)
  const showAddDetection = isPanelCorrectToAddDetection && ![CROP_STATUS.DRAWN].includes(cropStatus) && !showCancelDrawing

  return (<>
    { (activePointIndex !== null && isPanelCorrectToAddDetection) && (<>
      <Summary
        className={ `photo-actions__summary ${isMapMinified ? '--map-minified': '' }` }
        index={ activePointIndex }
        detections={ detections }
        typeDetection={typeDetection}
      />
      { imageInfo && (
        <Info
          className={ `photo-actions__info ${isMapMinified ? '--map-minified': '' }` }
          label={ label }
          { ...imageInfo }
        />
      )}

    </>)}

    { showCancelDrawing && (
      <Button
        className="photo-actions__option button-clean --failure"
        variant="secondary"
        onClick={ handleCancelDrawing }
      >Cancelar&nbsp;(C)</Button>
    )}

    { showAddDetection && (
      <AddDetectionButton
        isOriginalLoaded={ isOriginalLoaded }
        isLoading={ isLoading || isStillAssignedUserLoading }
        username={ username }
        isAssignedUser={ isAssignedUser }
        onClick={ handleStartDrawing }
      />
    )}
  </>)
}

PhotoActions.propTypes = {
  activePointIndex: PropTypes.number,
  image: PropTypes.object,
  detections: PropTypes.array,
  label: PropTypes.string,
  username: PropTypes.string,
  isAssignedUser: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOriginalLoaded: PropTypes.bool,
  refetchInspection: PropTypes.func,
}

export default PhotoActions
