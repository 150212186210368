import { HomeLink } from '../Menu/Menu'
import './Crumbs.scss'

const Crumbs = ({ title }) => (
  <nav className="crumbs">
    <div className="crumbs__item">
      <HomeLink />
    </div>
    <span className="crumbs__item crumbs__item--current">{ title }</span>
  </nav>
)

export default Crumbs
