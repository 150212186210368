import { useMutation } from '@apollo/client'
import { mutation, input } from 'infra/inspection/createInspection.mutation'

function useCreateInspection ({
  onCompleted,
  onError,
}) {
  const [createInspection, { loading }] = useMutation(mutation, {
    onCompleted (response) {
      onCompleted?.(response)
    },
    onError (error) {
      console.error(error)
      onError?.(error)
    }
  })

  return {
    loading,
    launch: (input) => {
      createInspection({
        variables: { input }
      })
    }
  }
}

export default useCreateInspection
export { input as CreateInspectionInput }
