import { gql } from '@apollo/client'

export const field = 'severities'

export default gql`
  query getSeverities($anomalyId: ID!) {
    severities (anomalyId: $anomalyId) {
      id
      value
    }
  }
`
