import PropTypes from 'prop-types'
import Modal from 'react-modal'
import './SimpleModal.scss'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')

const SimpleModal = ({
  isOpen,
  afterOpenModal,
  onRequestClose,
  contentLabel,
  title,
  actions,
  mandatory = false,
  className,
  children
}) => {

  return (
    <Modal
      isOpen={ isOpen }
      onAfterOpen={ afterOpenModal }
      onRequestClose={ onRequestClose }
      className={ `simple-modal ${className}` }
      overlayClassName="simple-modal-overlay"
      contentLabel={ contentLabel }
      ariaHideApp={ false }
    >
      <article className="simple-modal__body">
        { title && <h1 className="simple-modal__title">{ title }</h1> }
        { children && <section className="simple-modal__content">{ children }</section>}
      </article>
      { (!actions && !mandatory) && (
        <button
          className="simple-modal__close button-clean --regular"
          variant="secondary"
          onClick={ onRequestClose }
        >Cerrar aviso</button>
      )}
      { actions }
    </Modal>
  )
}

SimpleModal.propTypes = {
  isOpen: PropTypes.bool,
  afterOpenModal: PropTypes.func,
  onRequestClose: PropTypes.func,
  contentLabel: PropTypes.string,
  title: PropTypes.string,
  actions: PropTypes.node,
  mandatory: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node
}

export default SimpleModal
