import 'react-toastify/dist/ReactToastify.css'
import { toast } from 'react-toastify'

const TOAST_CONFIG = {
  position: "bottom-left",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored"
}

export const TOAST_TYPES = Object.freeze({
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
})

export default function showToast(type, text) {
  if (!toast[type]) return
  return toast[type](text, TOAST_CONFIG)
}

export const showSuccess = (text) => {
  showToast(TOAST_TYPES.SUCCESS, text)
}

export const showError = (text) => {
  showToast(TOAST_TYPES.WARNING, text)
}

export const showCancel = (text) => {
  showToast(TOAST_TYPES.ERROR, text)
}
