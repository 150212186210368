import TagManager from 'react-gtm-module'
const { REACT_APP_GTM_APIKEY } = process.env

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_APIKEY,
  dataLayer: {
    event: 'Pageview',
    pagePath: window.location.pathname,
    pageTitle: document.title,
  }
}

export default function initAnalytics () {
  TagManager.initialize(tagManagerArgs)
}
