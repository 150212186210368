import PropTypes from 'prop-types'
import { useState, useCallback, useContext } from 'react'
import { checkShape } from 'lib/types'
import CropContext from 'adapter/context/CropContext'
import useLayout from 'hooks/misc/useLayout'
import useInspectionDetail from 'hooks/models/inspection/useInspectionDetail'
import useDetectionsOfInspection from 'hooks/models/inspection/useDetectionsOfInspection'
import useRedirectToFiles from 'hooks/models/inspection/useRedirectToFiles'
import { filterDetections } from 'domain/detection'
import Layout from '../NewInspection/elements/Layout/Layout'
import AnomalyViewer from '../GeoDetail/AnomalyViewer/AnomalyViewer'
import DetectionsList from './DetectionsList/DetectionsList'
import InspectionSummary from 'ui/components/InspectionSummary/InspectionSummary'
import './GeoDetections.scss'

export const PATH = 'inspection-summary'
export const PARAM = 'inspectionId'
export const pageName = 'Listado de defectos'

const LIMIT_OF_DETECTIONS = 10

const GeoDetections = ({ match, handlePathname }) => {
  useLayout({ path: PATH, handlePathname })
  const { params } = match
  const inspectionId = params[PARAM]

  const { isVerticalCrop } = useContext(CropContext)

  const [isAsideVisible, setAsideIsVisible] = useState(false)
  const [detectionForm, setDetectionForm] = useState({})
  const { data: inspection, error, refetch: refetchInspection } = useInspectionDetail({ inspectionId, isLight: true })

  useRedirectToFiles({ status: inspection?.status, inspectionId })

  const {
    data: dataDetections,
    error: detectionsError,
    loading,
    refetch: refetchDetections,
    fetchMore,
  } = useDetectionsOfInspection({
    inspectionId,
    limit: LIMIT_OF_DETECTIONS
  })

  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        cursor: dataDetections?.pageInfo.endCursor,
        id: inspectionId
      }
    })
  }, [dataDetections, fetchMore, inspectionId])

  if (error) return <p>Error con la inspección</p>

  const detections = dataDetections?.edges?.map((edge) => edge.node)
  const detectionsFiltered = filterDetections(detections)

  return (
    <Layout
      className="geo-detections geo-detail"
      inspectionId={ inspectionId }
      aside={
        <InspectionSummary
          name={ inspection?.name }
          description={ inspection?.description }
          createdAt={ inspection?.createdAt }
        />
      }
    >
      <aside className={`geo-detail__form ${isAsideVisible && '--is-enabled'}`}>
        { isAsideVisible && (
          <AnomalyViewer
            from={ PATH }
            inspectionId={ inspectionId }
            detectionForm={ detectionForm }
            isVerticalCrop={ isVerticalCrop }
            refetchInspection={ refetchInspection }
            isAssignedUser={ inspection?.isAssignedUser }
            onRefetch={ refetchDetections }
            onClose={ () => setAsideIsVisible(false) }
          />
        )}
      </aside>
      <article className="wrapper-scroll">
        <DetectionsList
          className="layout-with-summary"
          inspection={ inspection }
          detections={ detectionsFiltered }
          isAssignedUser={ inspection?.isAssignedUser === true }
          loading={ loading }
          error={ detectionsError }
          onLoadMore={ loadMore }
          info={{
            pageInfo: dataDetections?.pageInfo,
            hasNextPage: dataDetections?.pageInfo?.hasNextPage,
            totalCount: dataDetections?.totalCount
          }}
          onLaunchForm={ (detectionForm) => {
            setAsideIsVisible(true)
            setDetectionForm(detectionForm)
          }}
        />
      </article>
    </Layout>
  )
}

GeoDetections.propTypes = {
  match: function (props, propName, componentName) {
    const model = { inspectionId: 'string' }
    const prop = props[propName].params
    return checkShape(model, prop, propName, componentName)
  },
  handlePathname: PropTypes.func
}

export default GeoDetections
