import PropTypes from 'prop-types'
import useAuthToken from 'hooks/auth/useAuthToken'
import useLogout from 'hooks/auth/useLogout'
import { PATH as geoDetailPath } from 'ui/pages/GeoDetail/GeoDetail'
import { PATH as dashboardPath } from 'ui/pages/Dashboard/Dashboard'
import * as GeoDetections from 'ui/pages/GeoDetections/GeoDetections'
import { pageName as geoDashboardPageName } from 'ui/pages/GeoDashboard/GeoDashboard'
import * as GeoFiles from 'ui/pages/GeoFiles/GeoFiles'
import SidebarOption from './SidebarOption/SidebarOption'
import './Sidebar.scss'

const Sidebar = ({
  inspectionId = null,
  isEmpty = false,
  isInspectionWithError = false
}) => {
  const [, , , authUser] = useAuthToken()
  const logout = useLogout()

  return (
    <aside className="sidebar">
      <nav className="sidebar__navigation"
        role="navigation"
        aria-label="main"
      >
        <ul className="sidebar__list">

          { /* Dashboard */ }
          <SidebarOption
            to={ `/${dashboardPath}` }
            label={ geoDashboardPageName }
            icon="table"
            isInside={ inspectionId !== null }
            testid="dashboard"
          />

          { /* Sections of a inspection */ }
          { (inspectionId && !isEmpty) && (<>

             { (!isInspectionWithError) && (
              <SidebarOption
                to={ `/${geoDetailPath}/${inspectionId}` }
                label="Inspección"
                icon="compas"
                testid="inspection"
              />
            )}

            { (!isInspectionWithError) && (
              <SidebarOption
                to={ `/${GeoDetections.PATH}/${inspectionId}` }
                label={ GeoDetections.pageName }
                icon="list2"
                testid="detections"
              />
            )}
            <SidebarOption
              to={ `/${GeoFiles.PATH}/${inspectionId}` }
              label={ GeoFiles.pageName }
              icon="folder"
              testid="files"
            />
          </>)}

          { /* User & Logout options */ }
          <section className="sidebar__bottom">

            { authUser && (<SidebarOption
              icon="user2"
              label={ authUser }
            />)}

            { logout && (<SidebarOption
              icon="door2"
              onClick={ () => { logout() } }
              label="Salir"
              testid='Salir'
            />)}

          </section>

        </ul>
      </nav>
    </aside>
  )
}

Sidebar.propTypes = {
  inspectionId: PropTypes.string,
  isEmpty: PropTypes.bool,
  isInspectionWithError: PropTypes.bool,
}

export default Sidebar
