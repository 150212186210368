import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import useAuthToken from 'hooks/auth/useAuthToken'
import useDocumentTitle from 'hooks/misc/useDocumentTitle/useDocumentTitle'
import Login, * as LoginConfig from 'ui/pages/Login/Login'
import * as NotFoundConfig from 'ui/pages/NotFound/NotFound'
import Page from 'adapter/router/Page'
import * as GeoDashboardConfig from 'ui/pages/GeoDashboard/GeoDashboard'

const Redirected = ({ path }) => (
  <Redirect
    to={{
      pathname: '/',
      state: {
        prevLocation: path,
        error: 'Primero necesitas loguearte',
      }
    }}
  />
)

const Protected = ({ component: Component, path, ...rest }) => {
  const [authToken] = useAuthToken()

  return (
    <Page
      { ...rest }
      path={ path }
      render={ (props) => {
        if (authToken) { return <Component {...props} {...rest} />}
        return <Redirected path={ path } />
      }}
    />
  )
}

Protected.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
}

export default Protected

const RedirectProtected = ({ path, to, ...rest }) => {
  const [authToken] = useAuthToken()
  const title = authToken ? GeoDashboardConfig.pageName : LoginConfig.pageName

  return (
    <Page
      path={ path }
      title={ title }
      { ...rest }
      render={ () => {
        if (authToken) { return <Redirect to={ to } />}
        return <Login />
      }}
    />
  )
}

RedirectProtected.propTypes = {
  path: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

const NotFoundProtected = ({ component: Component, path, ...rest }) => {
  const [authToken] = useAuthToken()
  const title = authToken ? NotFoundConfig.pageName : LoginConfig.pageName
  useDocumentTitle({ title })

  return (
    <Page
      { ...rest }
      path={ path }
      title={ title }
      render={ (props) => {
        if (authToken) { return <Component {...props} {...rest} />}
        return <Redirect path={ `/${LoginConfig.PATH}` } />
      }}
    />
  )
}

export {
  RedirectProtected,
  NotFoundProtected,
}
