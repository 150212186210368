import { useQuery } from '@apollo/client'
import ASSETS_QUERY, { field } from 'infra/assets/assets.query'

function useGetAssets ({ inspectionId, categoryId, imageId }) {
  const { data, loading, error } = useQuery(
    ASSETS_QUERY,
    {
      skip: !categoryId,
      variables: {
        inspectionId,
        categoryId,
        imageId
      }
    }
  )
  const assets = data?.[field]

  return {
    data: assets,
    loading,
    error
  }
}

export default useGetAssets
