import PropTypes from 'prop-types'
import './Spinner.scss'

const Spinner = ({ dark, className = '', style }) => (
  <span className={ `lds-ring ${dark ? '--is-dark': ''} ${className}`} style={ style }>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </span>
)

Spinner.propTypes = {
  dark: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
}

export default Spinner
