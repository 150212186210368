import { useState } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'ui/elements/Spinner/Spinner'
import Rois from 'ui/components/Rois/Rois'
import Polygon from 'ui/components/Polygon/Polygon'
import useUrlOfKey from 'hooks/query/useUrlOfKey'
import './ReviewImage.scss'

const Skeleton = ({ children }) => (
  <p className="review-image__skeleton" data-testid="skeleton">
    { children ? children : <Spinner dark /> }
  </p>
)

const ErrorMessage = () => <Skeleton>La imagen no ha cargado correctamente</Skeleton>

const Layer = ({ collection = [], callback }) => {
  if (collection.length === 0) return null
  return collection.map((item, i) => (
    callback?.(item, i)
  ))
}

const ReviewImage = ({ identifier, frame }) => {
  const { mediumKey: key } = frame
  const [isLoaded, setIsLoaded] = useState(!key ? true : false)
  const { data: urlRequested, loading, error } = useUrlOfKey({ key })

  return (
    <figure className="review-image">
      { (error || !key) && <ErrorMessage /> }
      { (!isLoaded || loading) && <Skeleton /> }
      <img
        src={ urlRequested }
        width="740"
        onLoad={ () => { setIsLoaded(true) } }
        style={{ opacity: isLoaded ? 1: 0 }}
        alt="Imagen con deteccion"
      />
      { (isLoaded && key) && (<>
        <Layer
          collection={ frame?.rois }
          callback={ (roi, index) => {
            return (
              <Rois
                index={ index }
                key={ `roi-${identifier}-${index}` }
                coordinates={ roi }
                width={ frame.width }
                height={ frame.height }
              />
            )
          }}
        />
        <Layer
          collection={ frame?.polygons }
          callback={ (polygon, index) => (
          <Polygon
            id={`${identifier}-${index}`}
            key={ `polygon-${identifier}-${index}` }
            points={ polygon.points }
            width={ frame.width }
            height={ frame.height }
          />)}
        />
      </>
      )}
    </figure>
  )
}

ReviewImage.propTypes = {
  identifier: PropTypes.number.isRequired,
  frame: PropTypes.shape({
    mediumKey: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    rois: PropTypes.array,
    polygons: PropTypes.array,
  }).isRequired,
}

export default ReviewImage
