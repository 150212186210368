import { useHistory } from 'react-router-dom'
import { INSPECTION_STATUS } from 'domain/inspection'
import { PATH } from 'ui/pages/GeoFiles/GeoFiles'

function useRedirectToFiles ({ status, inspectionId, onlyPath = false }) {
  const history = useHistory()

  const route = {
    pathname: `/${PATH}/${inspectionId}`,
    state: { isInspectionWithError: true }
  }

  if (status !== INSPECTION_STATUS.ERROR) return null
  if (onlyPath) return route

  history.push(route)
}

export default useRedirectToFiles
