import React from 'react'
import ReactDOM from 'react-dom'
import { CookiesProvider } from 'react-cookie'

import initTools from './tools'
import App from 'adapter/App/App'
import 'ui/theme/skins/styles.scss'
import 'ui/theme/assets/utilities/utilities.scss'

initTools()

const rootElement = document.getElementById('root')

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  rootElement
)
