import PropTypes from 'prop-types'
import { useState } from 'react'
import Button from 'ui/elements/Button/Button'
import ButtonSuccess from 'ui/elements/Button/ButtonSuccess'
import useCopyStyle from 'hooks/dom/useCopyStyle'
import isStillAssignedUser from 'infra/users/isStillAssignedUser'
import { STYLES_TO_BE_COPIED } from '../DrawLayer/DrawLayer.constants'
import './DrawActions.scss'

const SELECTOR_TO_COPY = 'cropper-crop-box'

const DrawActions = ({ refetchInspection, handleCancel, handleAccept }) => {
  const [isClicked, setIsClicked] = useState(false)
  const { styleCopied } = useCopyStyle({
    selector: SELECTOR_TO_COPY,
    index: 0,
    trigger: null,
    properties: STYLES_TO_BE_COPIED
  })

  const handleConfirmAccept = async () => {
    await isStillAssignedUser({
      trigger: refetchInspection,
      onSuccess: handleAccept,
      onFailure: handleCancel
    })
    handleCancel()
  }

  return (
    <section className="draw-actions" style={ styleCopied }>
      { (!isClicked) && (
        <Button
          variant="none"
          className="button-clean --failure"
          onClick={ handleCancel }
        >Cancelar</Button>
      )}
      <ButtonSuccess
        isClicked={ isClicked }
        setIsClicked={ setIsClicked }
        label="Recortando"
        onAction={ handleConfirmAccept }
      >Aceptar</ButtonSuccess>
    </section>
  )
}

DrawActions.propTypes = {
  refetchInspection: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired
}

export default DrawActions
