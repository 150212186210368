import { useEffect } from 'react'
import Button from '../Button/Button'
import './Modal.scss'

const MODAL_CLASSNAME = 'modal-on'
const ATTRIBUTTE_TOGGLE = 'hidden'

export const closeModal = (modalID) => {
  document.documentElement.classList.remove(MODAL_CLASSNAME)
  setTimeout(() => {
    const modal = document.querySelector(`#${modalID}`)
    modal && modal.setAttribute(ATTRIBUTTE_TOGGLE, true)
  }, 200)
}

export const openModal = (modalID) => {
  document.documentElement.classList.add(MODAL_CLASSNAME)
  setTimeout(() => {
    const modal = document.querySelector(`#${modalID}`)
    modal && modal.removeAttribute(ATTRIBUTTE_TOGGLE)
  }, 200)
}

const Modal = ({
  children,
  label,
  className,
  buttonContainer,
  id,
  variant = 'primary',
  buttonClassName = '',
  trigger = true,
  onExit
}) => {
  const exitModal = (event) => {
    const cancelButton = event.target
      .closest('.modal')
      .querySelector('[data-role="exit-modal"]')

    if (cancelButton) {
      cancelButton.click(event, id)
      return false
    } else {
      closeModal(id)
      onExit?.()
    }
  }

  useEffect(() => {
    let child = document.querySelector(`.modal__trigger[for=${id}]`)
    let origin = document.querySelector(`#${id}`)
    let parent = document.querySelector(buttonContainer)

    if (parent && child) parent.prepend(child)

    return () => {
      if (origin && child) origin.prepend(child)
    }
  }, [buttonContainer])

  return (
    <div
      id={ id }
      hidden
      className={`modal ${className}`}
    >
      { trigger && (
        <Button
          className={ `modal__trigger ${buttonClassName}` }
          onClick={ () => openModal(id) }
          htmlFor={ id }
          variant={ variant }
        >
          { label }
        </Button>
      )}

      <div className="modal__box">
        <div className="modal__content">{ children }</div>
        <Button
          className="modal__close"
          variant="close"
          onClick={ exitModal }
        >
          close
        </Button>
      </div>
    </div>
  )
}

export default Modal
