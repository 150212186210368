import PropTypes from 'prop-types'
import { useState } from 'react'
import { sortImagesWithTextFile } from 'domain/files'
import FileModal from '../FileModal/FileModal'
import Counter from '../Counter/Counter'
import FileRow from '../FileRow/FileRow'
import './FilesTable.scss'

const FilesTable = ({
  files = [],
  isLoading,
  areVideoFiles,
  skeleton,
}) => {
  const [currentFile, setCurrentFile] = useState(null)
  const [isModalOpened, setModalOpened] = useState(false)
  const sortedFiles = areVideoFiles ? files : sortImagesWithTextFile(files)

  const handleOpenFile = (file) => {
    setCurrentFile(file)
    setModalOpened(true)
  }

  return (<>
    <FileModal
      isOpen={ isModalOpened }
      file={ currentFile }
      onClose={ () => {
        setModalOpened(false)
        setCurrentFile(null)
      }}
    />
    <Counter
      files={ files }
      areVideoFiles={ areVideoFiles }
    />
    <table className="table-simplified">
      <thead className="fixed">
        <tr>
          <th className="cell-large">Nombre del archivo</th>
          <th className="cell-partial">Estado</th>
          <th className="cell-partial">Tamaño</th>
          <th className="cell-partial">Archivo</th>
        </tr>
      </thead>
      <tbody>
        { isLoading && skeleton }
        { (isLoading && !skeleton) && <tr><td colSpan="4">Cargando ficheros...</td></tr> }

        { !isLoading && sortedFiles.map((file, index) => (
          <FileRow
            key={ `thumbnail-${index}` }
            file={ file }
            handleOpenFile={ handleOpenFile }
          />
        )) }
      </tbody>
    </table>
  </>)
}

FilesTable.propTypes = {
  files: PropTypes.array,
  isLoading: PropTypes.bool,
  areVideoFiles: PropTypes.bool,
  skeleton: PropTypes.node,
}

export default FilesTable
