import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

const parseGroup = (selected, select, collection, setCollection, loading = null) => ({
  selected,
  select,
  collection,
  setCollection,
  loading
})

export default function useDependentQueries ({
  main: { query: mainQuery, field: mainField, variables: mainVariables } = {},
  dependent: { query: dependentQuery, field: dependentField } = {},
  getVariables,
  cached = false,
  cachedSelected = null
}) {
  const [mainCollection, setMainCollection] = useState([])
  const [dependentCollection, setDependentCollection] = useState([])

  const [mainSelected, selectMain] = useState(null)
  const [dependentSelected, selectDependent] = useState(null)

  const [getMainQuery, mainQueryData] = useLazyQuery(mainQuery, { variables: { ...mainVariables } })
  const [getDependentQuery, dependentQueryData] = useLazyQuery(dependentQuery)

  useEffect(() => {
    if (cached) return
    getMainQuery()
  }, [cached, getMainQuery])

  useEffect(() => {
    if (cached) return
    if (!mainQueryData.data?.[mainField]) return
    setMainCollection(mainQueryData.data[mainField])
  }, [mainQueryData.data, cached, mainField])

  useEffect(() => {
    if (!dependentQueryData?.data?.[dependentField]) return
    setDependentCollection(dependentQueryData.data[dependentField])
  }, [dependentQueryData.data, dependentField])

  useEffect(() => {
    if (!mainSelected) return
    getDependentQuery({ variables: getVariables(mainSelected) })
  }, [mainSelected, getDependentQuery, getVariables])

  useEffect(() => {
    if (!cached) return
    setMainCollection(cached)
    selectMain(cachedSelected)
    setDependentCollection([])
    selectDependent(null)
  }, [cachedSelected, cached])

  return [
    parseGroup(mainSelected, selectMain, mainCollection, setMainCollection, mainQueryData.loading),
    parseGroup(dependentSelected, selectDependent, dependentCollection, setDependentCollection, dependentQueryData.loading),
  ]
}
