import PropTypes from 'prop-types'
import './Radial.scss'

const Radial = ({ progress, status, disabled }) => (
  <div
    className="radial"
    data-progress={ progress }
    data-status={ disabled ? 'disabled' : status.toLowerCase() }
  >
    <div className="mask full">
      <div className="fill"></div>
    </div>
    <div className="mask half">
      <div className="fill"></div>
      <div className="fill fix"></div>
    </div>
  </div>
)

Radial.propTypes = {
  progress: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}

export default Radial
