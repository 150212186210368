import { gql } from '@apollo/client'

const field = 'checkGeolocationFiles'

const query = gql`
  query checkGeolocationFiles($fileIds: [ID!]!) {
    checkGeolocationFiles(fileIds: $fileIds) {
      code
      value
      associatedItems
    }
  }
`

export {
  field,
  query
}
