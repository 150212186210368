const SKIN_ATTR = 'data-skin'
const SKIN_VALUE = 'dark'

function find (element) {
  return element || document.documentElement
}

function add (element) {
  find(element).setAttribute(SKIN_ATTR, SKIN_VALUE)
}

function remove (element) {
  find(element).removeAttribute(SKIN_ATTR)
}

export {
  add,
  remove
}
