import { useEffect } from 'react'
import { useQuery, NetworkStatus } from '@apollo/client'
import {
  DETECTIONS_OF_INSPECTION_QUERY as query,
  field
} from 'infra/inspection/detections.query'

function useDetectionsOfInspection ({ inspectionId, limit }) {
  const {
    data, loading, error,
    refetch, fetchMore,
    networkStatus
  } = useQuery(query, {
    variables: {
      id: inspectionId,
      limit
    },
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    refetch()
  }, [inspectionId, refetch])

  return {
    data: data?.[field],
    loading,
    error,
    fetchMore,
    refetch,
    refetching: networkStatus === NetworkStatus.refetch
  }
}

export default useDetectionsOfInspection
