import { useEffect, useState } from 'react'
import applySpiderfier from 'lib/spiderfier'

function createMarker (index, { id, ...image }) {
  const { position, metadata } = image
  if (!position && !metadata) return null

  let latitude, longitude = null
  if (position) {
    latitude = Number(position.latitude)
    longitude = Number(position.longitude)
  } else if (metadata) {
    latitude = Number(metadata[0].value)
    longitude = Number(metadata[1].value)
  }
  return {
    order: index,
    id,
    latitude,
    longitude
  }
}

function useMarkers ({ images } = { images: [] }) {
  const [markers, setMarkers] = useState([])

  useEffect(() => {
    setMarkers([])
    if (!images || images?.length === 0) return
    images.forEach((image, index) => {
      const newMarker = createMarker(index, image)
      newMarker && setMarkers(oldMarkers => [...oldMarkers, newMarker])
    })
    setMarkers(markers => applySpiderfier({ markers, groupBy: 'id' }))
  }, [images])

  return markers
}

export default useMarkers
