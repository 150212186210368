import { useRef, useState } from 'react'

const EyeIcon = ({ isVisible, onClick }) => (
  <i
    role="img"
    className="field__icon"
    aria-hidden="true"
    aria-label="Toggle password visibility"
    onClick={ onClick }
    style={{ display: isVisible ? 'block' : 'none' }}
  ></i>
)

export const TYPES = Object.freeze({
  TEXT: 'text',
  PASSWORD: 'password'
})

const FieldText = ({
  id,
  className,
  name,
  type = TYPES.TEXT,
  placeholder,
  label,
  required,
  invalid,
  error,
  onChange,
  autoComplete,

}) => {
  const [value, setValue ] = useState('')
  const [isVisible, setIsVisible] = useState(true)
  const inputRef = useRef(null)
  const hasValue = value.length > 0

  const revealPass = () => {
    setIsVisible((oldValue) => !oldValue)
    const type = isVisible ? TYPES.TEXT : TYPES.PASSWORD
    inputRef.current.setAttribute('type', type)
  }

  return (
    <p
      className={`field field--text
        ${className}
        ${invalid && 'invalid'}
        ${required && 'required'}
      `}
    >
      <input
        ref={ inputRef }
        id={ id }
        aria-label={ id }
        name={ name }
        placeholder={ placeholder }
        type={ type }
        value={ value }
        required={ required }
        autoComplete={ autoComplete }
        onChange={ (event) => {
          setValue(event.target.value);
          onChange?.(event)
        }}
      />
      <label htmlFor={ id }>{ invalid ? error : label }</label>
      { type === TYPES.PASSWORD && (
        <EyeIcon
          onClick={ revealPass }
          isVisible={ hasValue }
        />
      )}
    </p>
  )
}

export default FieldText
