import { createContext, useCallback, useState, useEffect } from 'react'

const Context = createContext({})

const IMAGE_URL_FIELD = 'url'

const INITIAL_POINT_INDEX = 0

export function ImagesContextProvider({ children }) {
  const [activePointIndex, setActivePointIndex] = useState(INITIAL_POINT_INDEX)
  const [images, setImages] = useState([])
  const [image, setImage] = useState(null)
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    if (!images || images.length === 0) return
    const activeImage = images[activePointIndex]
    setImage(activeImage)
    if (!activeImage || !activeImage[IMAGE_URL_FIELD]) return
    setImageUrl(activeImage[IMAGE_URL_FIELD])
  }, [images, activePointIndex])

  const resetActivePointIndex = useCallback(() => {
    setActivePointIndex(INITIAL_POINT_INDEX)
  }, [])

  return <Context.Provider value={{
    activePointIndex,
    setActivePointIndex,
    setImages,
    image,
    images,
    imageUrl,
    resetActivePointIndex,
  }}>
    { children }
  </Context.Provider>
}

export default Context
