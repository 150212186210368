import PropTypes from 'prop-types'
import { SKELETON_TEXT } from 'lib'
import './Info.scss'

function trimDecimales (value) {
  if (!value || value.length === 0 || value === 0) return null
  const [integer, decimals] = value.split('.')
  if (!decimals) return value
  return `${integer}.${decimals.slice(0, 5)}`
}

const Info = ({ label, name, id, latitude, longitude, className }) => (
  <article className={ `photo-info ${className}` }>
    <p className="photo-info__label">{ label }</p>
    <p className="photo-info__name">{ name }</p>
    <p className="photo-info__id">{ id }</p>
    <section className="photo-info__coordinates">
      <p>Latitud: <span>{ trimDecimales(latitude) || SKELETON_TEXT }º</span></p>
      <p>Longitud: <span>{ trimDecimales(longitude) || SKELETON_TEXT}º</span></p>
    </section>
  </article>
)

Info.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  className: PropTypes.string,
}

export default Info
