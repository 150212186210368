import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Vendor from 'react-cropper'
import useAddClasses from 'hooks/dom/useAddClasses'
import { CROPPER_CONFIG as config } from './DrawVendorConfig'
import "cropperjs/dist/cropper.css"

const DrawVendor = forwardRef(({
  isDragging,
  isLoading,
  src,
  ready,
  className,
  handleDrawing,
  handleCropEnd,
  handleZoom,
  ...rest
}, ref) => {

  const classNames = useAddClasses(
    ['draw-cropper', className], [
    isLoading && 'is-loading',
    isDragging && 'is-dragging',
  ])

  return (<>
    <Vendor
      ref={ ref }
      className={ classNames }
      src={ src }
      ready={ ready }
      cropmove={ handleDrawing }
      cropend={ handleCropEnd }
      zoom={ handleZoom }
      crossOrigin="enabled"
      { ...config }
      { ...rest }
    />
    { rest.children }
  </>)
})

DrawVendor.propTypes = {
  isDragging: PropTypes.bool,
  isLoading: PropTypes.bool,
  src: PropTypes.string,
  ready: PropTypes.func,
  className: PropTypes.string,
  handleDrawing: PropTypes.func,
  handleCropEnd: PropTypes.func,
  handleZoom: PropTypes.func,
}

export default DrawVendor
