import PropTypes from 'prop-types'
import './SkeletonTable.scss'

const numberByDefault = 4
const propsByDefault = {
  numberOfRows: numberByDefault,
  numberOfCells: numberByDefault,
}

const SkeletonTable = ({
  numberOfRows = numberByDefault,
  numberOfCells = numberByDefault
} = propsByDefault) => {
  const rows = new Array(numberOfRows).fill(0)
  const cells = new Array(numberOfCells).fill(0)

  return (<>
    { rows.map((_, index) => (
      <tr
        className="skeleton"
        key={ `skeleton-row-${index}` }
      >
        { cells.map((_, indexCell) => (
          <td key={ `skeleton-row-${index}-${indexCell}` }><span />
          </td>))
        }
      </tr>
    ))}
  </>)
}

SkeletonTable.propTypes = {
  numberOfRows: PropTypes.number,
  numberOfCells: PropTypes.number,
}

export default SkeletonTable
