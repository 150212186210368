import { gql } from '@apollo/client'
import INSPECTION_BASE from './inspection.fragment'
import { IMAGES, FILES, VIDEOS } from '../partials'

export const field = 'inspection'

export const INSPECTION_QUERY_LIGHT = gql`
  ${ INSPECTION_BASE }
  query inspection($id: ID!) {
    inspection(id: $id) {
      ...InspectionBaseFragment
    }
  }
`

export const INSPECTION_QUERY = gql`
  ${ INSPECTION_BASE }
  ${ IMAGES }
  ${ VIDEOS }
  query inspection($id: ID!) {
    inspection(id: $id) {
      ...InspectionBaseFragment
      ...ImagesFragment
      ...VideosFragment
    }
  }
`
export const INSPECTION_QUERY_WITH_FILES = gql`
  ${ INSPECTION_BASE }
  ${ IMAGES }
  ${ FILES}
  query inspection($id: ID!) {
    inspection(id: $id) {
      ...InspectionBaseFragment
      ...ImagesFragment
      ...FilesFragment
    }
  }
`
