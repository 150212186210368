import PropTypes from 'prop-types'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import useGetUsers from 'hooks/models/users/useGetUsers'
import './NewInspectionForm.scss'

const NewInspectionFormHeader = () => (
  <section className="anomaly-form__intro">
    <h3 className="anomaly-form__title">Nueva inspección</h3>
    <p>Incluye el título, descripción (opcional) y los archivos que quieres realizar la inspección.</p>
  </section>
)

const NewInspectionFormError = () => (
  <section className="anomaly-form__error">
    <p><strong>¡Ups!</strong>&nbsp;
      Parece que ha ocurrido un error recuperando los datos de los usuarios.
      Por favor, inténtalo de nuevo más tarde.
    </p>
  </section>
)

const NewInspectionForm = ({ userSelected, onChangeUser, onChangeTitle, onChangeDescription }) => {
  const { data: users, loading, error } = useGetUsers()

  return (
    <article className="new-inspection-form">
      <NewInspectionFormHeader />

      { error && <NewInspectionFormError /> }

      { !error && (<>
        <section className="new-inspection-form__fieldsets">
          <Dropdown
            label="Asignar usuario"
            itemSelected={ userSelected }
            items={ users }
            onSelect={ onChangeUser }
            isLoading={ loading }
          />

          <fieldset className="new-inspection-form__fieldset">
            <label className="new-inspection-form__label">Título</label>
            <input
              type="text"
              className="field-clean"
              placeholder=""
              onChange={ (event) => onChangeTitle(event.target.value) }
            />
          </fieldset>
        </section>

        <fieldset className="new-inspection-form__fieldset --textarea">
          <label className="new-inspection-form__label">Descripción (opcional)</label>
          <textarea
            className="field-clean"
            placeholder=""
            rows="5"
            onChange={ (event) => onChangeDescription(event.target.value) }
          />
        </fieldset>
      </>) }
    </article>
  )
}

NewInspectionForm.propTypes = {
  userSelected: PropTypes.object,
  onChangeUser: PropTypes.func,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired
}

export default NewInspectionForm
