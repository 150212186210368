import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { addNameField } from 'infra'
import Spinner from 'ui/elements/Spinner/Spinner'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import * as Toaster from 'ui/elements/Toast/Toast'
import useGetUsers from 'hooks/models/users/useGetUsers'
import Actions from './Actions/Actions'
import useReassignInspection, { ReassignInspectionInput } from 'hooks/models/inspection/useReassignInspection'

const MESSAGES = {
  SUCCESS: 'El usuario se ha asignado correctamente.',
  CANCEL: 'El usuario no se ha asignado correctamente.',
  ERROR: 'Ha ocurrido un error. No se ha podido reasignar la inspección.'
}

const ReassignUserForm = ({ inspectionId, assignedUser, refetch }) => {
  const [showConfirmUser, setShowConfirmUser] = useState(false)
  const [initialUser, setInitialUser] = useState(null)
  const [userSelected, selectUser] = useState(null)
  const { data: users, loading: isLoadingUsers, error: errorWithUsers } = useGetUsers()

  const {
    launch: reassignInspection,
    loading: isReassignationLoading
  } = useReassignInspection({
    onCompleted: async ({ reassignInspection }) => {
      setShowConfirmUser(false)
      setInitialUser(reassignInspection.assignedUser)
      Toaster.showSuccess(MESSAGES.SUCCESS)
      refetch()
    },
    onError: () => {
      setShowConfirmUser(false)
      Toaster.showError(MESSAGES.ERROR)
    }
  })

  const createReassignationInput = () =>
    ReassignInspectionInput.create({
      inspectionId,
      userId: userSelected.id
    })

  const handleChangeUser = (user) => {
    selectUser(user)
    const isTheSameUserThanInitialUser = user.id === initialUser.id
    setShowConfirmUser(!isTheSameUserThanInitialUser)
  }

  useEffect(() => {
    if (!assignedUser) return
    const initialUser = addNameField(assignedUser, ({ username }) => username)
    setInitialUser(initialUser)
    selectUser(initialUser)
  }, [assignedUser])

  return (<>
    { (!errorWithUsers && !isReassignationLoading) && (
        <Dropdown
        label="Inspección asignada"
        itemSelected={ userSelected }
        items={ users }
        onSelect={ handleChangeUser }
        isLoading={ isLoadingUsers }
      />
    )}
    { isReassignationLoading && <Spinner dark style={{ margin: '0 auto', display: 'block' }} /> }
    { errorWithUsers && <p>Error recuperando los usuarios disponibles.</p>}

    { showConfirmUser && (
      <Actions
        onCancel={ () => {
          Toaster.showCancel(MESSAGES.CANCEL)
          setShowConfirmUser(false)
        }}
        onSend={ () => {
          reassignInspection(createReassignationInput())
          setShowConfirmUser(false)
        }}
      />
    )}
  </>)
}

ReassignUserForm.propTypes = {
  inspectionId: PropTypes.string.isRequired,
  assignedUser: PropTypes.object
}

export default ReassignUserForm
