import PropTypes from 'prop-types'
import Button from 'ui/elements/Button/Button'
import useConfirmButton from 'hooks/dom/useConfirmButton'

const Actions = ({ onCancel, onSend }) => {
  const {
    handleConfirm: handleCancelConfirm,
    confirmText: cancelText
  } = useConfirmButton({
    label: 'Cancelar',
    callbackToAction: onCancel,
    secondsToConfirm: 10
  })

  const {
    handleConfirm,
    confirmText: reassignText
  } = useConfirmButton({
    label: 'Asignar',
    callbackToAction: onSend,
    secondsToConfirm: 10
  })

  return (
    <section className="anomaly-form__actions">
      <Button
        variant="none"
        className="button-clean --failure"
        onClick={ handleCancelConfirm }
      >{ cancelText }</Button>
      <Button
        variant="none"
        className="button-clean --success"
        onClick={ handleConfirm }
      >{ reassignText }</Button>
    </section>
  )
}

Actions.propTypes = {
  onSend: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default Actions
