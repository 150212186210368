const LOCATION = Object.freeze({
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  get coordinates () {
    return [this.LATITUDE, this.LONGITUDE]
  },
  hasCoordinates (image) {
    const keys = image.map(({ key }) => key)
    return this.coordinates.every(key => keys.includes(key))
  }
})

const hasLocation = (image) => {
  if (image?.position) {
    return image?.position[LOCATION.LATITUDE] && image?.position[LOCATION.LONGITUDE]
  }
  if (!image?.metadata) return false
  if (!Array.isArray(image.metadata)) return false
  return LOCATION.hasCoordinates(image.metadata)
}

export default function filterByLocation (images) {
  if (!images) return []
  return images.filter(image => hasLocation(image))
}
