const UPLOAD_STATUS = Object.freeze({
  IDDLE: 'IDDLE',
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
})

const initialState = {
  fileIds: [],
  uploadStatus: UPLOAD_STATUS.IDDLE,
  showCheckGeolocation: false,
  isOnline: true,
  progress: 0,
}

const reducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'START_THE_UPLOAD': return { ...state, uploadStatus: UPLOAD_STATUS.UPLOADING }
    case 'UPLOAD_SUCCESS': {
      console.info('Successful uploads:', payload)
      return { ...state, uploadStatus: UPLOAD_STATUS.SUCCESS }
    }
    case 'UPLOAD_ERROR': return { ...state, uploadStatus: UPLOAD_STATUS.ERROR }
    case 'UPLOAD_IDDLE': return { ...state, uploadStatus: UPLOAD_STATUS.IDDLE }
    case 'UPDATE_FILE_IDS': return { ...state, fileIds: payload }
    case 'SHOW_CHECK_GEOLOCATION': return { ...state, showCheckGeolocation: true }
    case 'UPDATE_PROGRESS': return { ...state, progress: payload }
    case 'IS_ONLINE': return { ...state, isOnline: payload }
    case 'CLEAR': return initialState
    default: return state
  }
}

export { initialState, reducer, UPLOAD_STATUS }
