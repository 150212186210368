import ExifReader from 'exifreader'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsDataURL(file)
  })

const getMetadata = async (file) => {
  const fileData = file.data
  const fileBase64 = await getBase64(fileData)
  return await ExifReader.load(fileBase64)
}

export default getMetadata
