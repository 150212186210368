const DEFAULT_FIELD = 'type'
const ALTERNATIVE_FIELD = 'contentType'

const TYPE_VIDEO = {
  value: 'video',
  alternative: ALTERNATIVE_FIELD
}

const TYPE_TEXT = {
  value: 'text',
  alternative: ALTERNATIVE_FIELD
}

const TYPE_IMAGE = {
  value: 'image',
  alternative: ALTERNATIVE_FIELD
}

const is = (file, field, { value }) => file?.[field]?.includes(value) || false

const isVideoFile = (file, { field = DEFAULT_FIELD } = { field: DEFAULT_FIELD }) =>
  is(file, field, TYPE_VIDEO)

const isImageFile = (file, { field = DEFAULT_FIELD } = { field: DEFAULT_FIELD }) =>
  is(file, field, TYPE_IMAGE)

const isTextFile = (file, { field = DEFAULT_FIELD } = { field: DEFAULT_FIELD }) =>
  is(file, field, TYPE_TEXT)

const areAllImages = (files, { field = DEFAULT_FIELD } = { field: DEFAULT_FIELD }) =>
  files.every(file => is(file, field, TYPE_IMAGE))

export {
  isVideoFile,
  isImageFile,
  isTextFile,
  areAllImages,
  TYPE_TEXT,
  TYPE_VIDEO,
}
