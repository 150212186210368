import { findImagesCorrupted } from 'domain/files'
import { TYPE_TEXT, isTextFile } from 'domain/files/types/types'

function relationBetweenImagesAndFiles (images, files, callback) {
  files.forEach(file => {
    images.forEach(image => {
      if (file.id  !== image.file.id) return
      callback(image, file)
    })
  })
}

function findTextFileInFiles (files) {
  const textFile = files.find(file =>
    isTextFile(file, { field: TYPE_TEXT.alternative }))
  return {
    ...textFile,
    type: textFile?.[TYPE_TEXT.alternative]
  }
}

function parseFiles (inspection) {
  if (!inspection) return []
  const { files, images } = inspection
  if (!files || !images) return []

  let parsedFiles = []
  const textFile = findTextFileInFiles(files)

  relationBetweenImagesAndFiles(images, files,
    ({ smallKey }, file) => {
    parsedFiles.push({
      thumbnailKey: smallKey,
      type: file[TYPE_TEXT.alternative],
      ...file
    })
  })
  const filesWithImagesCorrupted = findImagesCorrupted(images, files)
  filesWithImagesCorrupted.forEach(fileWithImageCorrupted => {
    parsedFiles.push({
      type: fileWithImageCorrupted[TYPE_TEXT.alternative],
      ...fileWithImageCorrupted
    })
  })
  parsedFiles.push(textFile)
  return parsedFiles
}

const useFormatFilesOfInspection = (inspection, { areVideoFiles }) => {
  if (!inspection) return []
  if (areVideoFiles) return inspection.files
  return parseFiles(inspection)
}

export default useFormatFilesOfInspection
