import { gql } from '@apollo/client'
export const field = 'detectionsWithValidations'

const LIMIT = 20

export const query = gql`
  query detectionsWithValidations(
    $cursor: String,
    $categoryExternalReference: ID!
  ) {
    detectionsWithValidations(
      first: ${LIMIT},
      after: $cursor,
      categoryExternalReference: $categoryExternalReference
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          anomaly {
            id
            externalReference
            name
            category {
              id
              externalReference
              name
            }
            type {
              id
              externalReference
              name
            }
          }
          frames {
            mediumKey
            thumbnailKey
            height
            width
            rois {
              id
              x1
              x2
              y1
              y2
            }
            polygons {
              points
            }
          }
          validations {
            id
            status
            categoryStatus
            anomalyTypeStatus
            observations
            validatedAt
            user {
              id
              username
            }
          }
          review {
            id
            detectionId
            categoryStatus
            anomalyTypeStatus
            observations
            reviewedAt
            userId
          }
        }
      }
    }
  }
`
