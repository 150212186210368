import PropTypes from 'prop-types'
import Spinner from 'ui/elements/Spinner/Spinner'
import Thumbnail from 'ui/components/Thumbnail/Thumbnail'
import Tooltip from '../../Tooltip/Tooltip'
import './MarkerTooltip.scss'

const Skeleton = ({ children }) => (
  <p className="marker-tooltip__loading">
    { children ? children : <Spinner dark /> }
  </p>
)

const ErrorMessage = () => <Skeleton>La imagen no ha cargado correctamente</Skeleton>

const MarkerTooltip = ({ urlKey }) => (
  <Tooltip
    className="marker-tooltip"
  >
    { urlKey && (
      <Thumbnail
        urlKey={ urlKey }
        width="220"
        className='marker-tooltip__figure'
        errorMessage={ <ErrorMessage /> }
        skeleton={ <Skeleton /> }
        isFull
      />
    )}
  </Tooltip>
)

MarkerTooltip.propTypes = {
  urlKey: PropTypes.string.isRequired,
}

export default MarkerTooltip
