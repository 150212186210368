import { gql } from '@apollo/client'

export const field = 'login'

export const mutation = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      accessToken
      user {
        id
        username
      }
    }
  }
`
