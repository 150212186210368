import PropTypes from 'prop-types'
import { useState, useContext } from 'react'

import SplitPanelContext, {
  PANEL_STATUS,
} from 'adapter/context/SplitPanelContext'
import CropContext, { CROP_STATUS } from 'adapter/context/CropContext'
import ImagesContext from 'adapter/context/ImagesContext'

import { PATH } from 'ui/pages/GeoDetections/GeoDetections'
import Spinner from 'ui/elements/Spinner/Spinner'

import { addNameField } from 'infra'
import {
  VALIDATION_STATUS,
  getValidationStatusVerb,
} from 'domain/detection'
import useValidateDetectionCategory, {
  field as validateResponseField,
  ValidateDetectionInput,
} from 'hooks/models/detection/useValidateDetectionCategory'
import useGetCategoriesWithHierarchy from 'hooks/models/categories/useGetCategoriesWithHierarchy'
import useGetAssets from 'hooks/models/assets/useGetAssets'
import isStillAssignedUser from 'infra/users/isStillAssignedUser'

import Button from 'ui/elements/Button/Button'
import Dot, { getStatusLabel } from 'ui/elements/Dot/Dot'
import Dropdown from 'ui/elements/Dropdown/Dropdown'
import Actions from '../Actions/Actions'
import Dropdowns from '../Dropdowns/Dropdowns'
import * as Toaster from 'ui/elements/Toast/Toast'
import MESSAGES from '../Messages'
import { Header } from '.'

const addName = (collection) =>
  collection && addNameField(collection, ({ value }) => value)

const ValidateDetectionCategoryForm = ({
  from,
  inspectionId,
  detection,
  refetchInspection,
  isAssignedUser,
  withCache = false,
  onClose,
  onSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isClickedActions, setIsClickedActions] = useState(false)

  const { image } = useContext(ImagesContext)
  const { setCropStatus } = useContext(CropContext)
  const { setActivePanel } = useContext(SplitPanelContext)

  const { category, parentCategory } = detection
  const validationStatus = detection?.validation?.status
  const asset = addName(detection?.asset)
  const severity = addName(detection?.severity)

  const [assetSelected, selectAsset] = useState(asset)

  const { data: parentCategoryId } = useGetCategoriesWithHierarchy({
    certainCategoryByName: parentCategory?.name,
  })

  const { data: assets, loading: assetsLoading } = useGetAssets({
    inspectionId,
    categoryId: parentCategoryId,
    imageId: image?.id,
  })
  const inputPrecharged = {
    category,
    severity,
  }
  const hasAssets =
    Boolean(assets?.length > 0 && assetSelected) ||
    assets?.length === 0 ||
    !assets
  const isAllSelected = Boolean(
    hasAssets && category && !isLoading && !assetsLoading
  )

  const returnToView = () => {
    setCropStatus(CROP_STATUS.LOADED)
    setActivePanel(PANEL_STATUS.SPLIT_VIEW)
    setIsLoading(false)
    onClose()
  }

  const { launch: validate, loading: isValidateProcessing } =
  useValidateDetectionCategory({
      withCache,
      allDetections: from === PATH,
      payload: { inspectionId, imageId: image?.id },
      onError: () => {
        Toaster.showError(MESSAGES.CATEGORY_ERROR)
      },
      onCompleted: ({ [validateResponseField]: response }) => {
        const verb = getValidationStatusVerb(response.status)

        const toastText = `El conjunto se ha ${verb} correctamente`

        Toaster.showSuccess(toastText)
        returnToView()
        !withCache && onSuccess?.(response)
      },
    })

  const createDetectionInput = ({ status }) =>
    ValidateDetectionInput.create({
      detectionId: detection.id,
      categoryStatus: status,
      assetId: assetSelected?.id,
    })

  const handleValidate = (status) => {
    if (isValidateProcessing) return
    const detection = createDetectionInput({ status })
    console.log('validate', {detection})
    validate(detection)
  }

  const handleSuccessfulValidation = async () => {
    setIsLoading(true)
    await isStillAssignedUser({
      trigger: refetchInspection,
      onSuccess: () => {
        handleValidate(VALIDATION_STATUS.CORRECT)
      },
      onFailure: () => {
        setIsClickedActions(false)
        setIsLoading(false)
      },
    })
  }

  const handleDiscardValidation = async () => {
    setIsLoading(true)
    await isStillAssignedUser({
      trigger: refetchInspection,
      onSuccess: () => {
        handleValidate(VALIDATION_STATUS.INCORRECT)
      },
      onFailure: () => {
        setIsClickedActions(false)
        setIsLoading(false)
      },
    })
  }

  return (
    <>
      <form className="form-detection">
        <Header type={detection?.type} />

        {isLoading && <Spinner className="anomaly-form__loading" dark />}

        {!isLoading && (
          <>
            {parentCategory && (
              <Dropdown
                label="Tipo de objeto"
                itemSelected={parentCategory}
                selectable={false}
              />
            )}
            {!detection?.asset && assets && (
              <Dropdown
                label="Matrícula"
                itemSelected={assetSelected}
                items={assets}
                onSelect={selectAsset}
                isLoading={assetsLoading}
                uppercase
                selectable={assets?.length > 0}
              />
            )}
            <Dropdowns inputPrecharged={inputPrecharged} isAnomaly={false} />
          </>
        )}

        {!isLoading && (
          <>
            <fieldset className="anomaly-form__block">
              <p>
                <Dot statusId={validationStatus} />
                {getStatusLabel(validationStatus, { capitalize: true })}
              </p>
            </fieldset>

            {isAssignedUser && (
              <Actions
                labelSuccess="Validar"
                labelCancel="Descartar"
                isEnabled={isAllSelected}
                isClicked={isClickedActions}
                setIsClicked={setIsClickedActions}
                onSend={handleSuccessfulValidation}
                onCancel={handleDiscardValidation}
              />
            )}
          </>
        )}
      </form>
      <Button
        variant="none"
        className="anomaly-form__cancel button-clean --failure"
        onClick={returnToView}
      >
        Cerrar validación
      </Button>
    </>
  )
}

ValidateDetectionCategoryForm.propTypes = {
  from: PropTypes.string,
  inspectionId: PropTypes.string.isRequired,
  detection: PropTypes.object,
  refetchInspection: PropTypes.func,
  isAssignedUser: PropTypes.bool,
  withCache: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
}

export default ValidateDetectionCategoryForm
