import propTypes from 'prop-types'
import './InspectionTitle.scss'

const TitleAndDescription = ({ name, description }) => (
  <div className="inspection-info">
    <p className="inspection-info__title">{ name }</p>
    <span className="inspection-info__content">
      <i className="inspection-info__description">{ description }</i>
    </span>
  </div>
)

TitleAndDescription.propTypes = {
  name: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
}

export default TitleAndDescription
