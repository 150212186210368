import { gql } from '@apollo/client'

export const field = 'deleteInspection'

export const mutation = gql`
  mutation deleteInspection($inspectionId: ID!) {
    deleteInspection(inspectionId: $inspectionId) {
      id
      name
    }
  }
`
