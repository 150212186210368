import Flag from 'ui/elements/Flag/Flag'
import Tooltip from 'ui/elements/Tooltip/Tooltip'
import { VALIDATION_STATUS as STATUS } from 'domain/detection'

const statusConsideredReviewed = [STATUS.CORRECT, STATUS.INCORRECT, STATUS.DOUBT]
const isReviewed = (review, field) => statusConsideredReviewed.includes(review[field])

const VALIDATION_GROUPS = {
  CATEGORY: 'categoryStatus',
  ANOMALY: 'anomalyTypeStatus',
}

const getClassName = (review, field) => {
  if (!review?.[field]) return ''
  return (isReviewed && "--reviewed") || ""
}

const getSubgroupForUsername = (validation, field) => {
  if (!validation[field]) return null
  if (!isReviewed(validation, field)) return null
  return validation[field]
}

const prepareGroup = (review, field) => ({
  [STATUS.CORRECT]: [],
  [STATUS.INCORRECT]: [],
  [STATUS.DOUBT]: [],
  className: getClassName(review, field)
})

const addUserToGroup = (group, validation, validationStatusField) => {
  const username = validation.user.username
  const subgroup = getSubgroupForUsername(validation, validationStatusField)
  group[subgroup].push(username)
}

function orderUsersByValidationStatus (index, { review, validations }) {
  const categories = prepareGroup(review, VALIDATION_GROUPS.CATEGORY)
  const anomalyTypes = prepareGroup(review, VALIDATION_GROUPS.ANOMALY)

  validations.forEach((validation) => {
    addUserToGroup(categories, validation, VALIDATION_GROUPS.CATEGORY)
    addUserToGroup(anomalyTypes, validation, VALIDATION_GROUPS.ANOMALY)
  })

  return {
    identifier: `validations-entry-${index + 1}`,
    categories,
    anomalyTypes
  }
}

const Semaphore = ({ selected, collection, onLaunchTooltip, className }) => (
  <>
    <Tooltip label={ onLaunchTooltip(collection.CORRECT, STATUS.CORRECT) }>
      <Flag className={`${className} --ok ${selected === STATUS.CORRECT && collection.className}`}>{ collection.CORRECT.length }</Flag>
    </Tooltip>
    <Tooltip label={ onLaunchTooltip(collection.INCORRECT, STATUS.INCORRECT) }>
      <Flag className={`${className} --ko ${selected === STATUS.INCORRECT && collection.className}`}>{ collection.INCORRECT.length }</Flag>
    </Tooltip>
    <Tooltip label={ onLaunchTooltip(collection.DOUBT, STATUS.DOUBT) }>
      <Flag className={`${className} --un ${selected === STATUS.DOUBT && collection.className}`}>{ collection.DOUBT.length }</Flag>
    </Tooltip>
  </>
)

export default Semaphore
export { orderUsersByValidationStatus }
