import { ApolloProvider } from '@apollo/client'
import { SettingsContextProvider } from 'adapter/context/SettingsContext'
import { SplitPanelContextProvider } from 'adapter/context/SplitPanelContext'
import { CropContextProvider } from 'adapter/context/CropContext'
import { ImagesContextProvider } from 'adapter/context/ImagesContext'
import { DetectionsContextProvider } from 'adapter/context/DetectionsContext'
import useApolloClient from 'hooks/auth/useApolloClient'

const ContextProviders = ({ children }) => {
  const apolloClient = useApolloClient()
  if (!apolloClient) return null

  return (
    <ApolloProvider client={apolloClient}>
      <SplitPanelContextProvider>
        <ImagesContextProvider>
          <CropContextProvider>
            <DetectionsContextProvider>
              <SettingsContextProvider>{children}</SettingsContextProvider>
            </DetectionsContextProvider>
          </CropContextProvider>
        </ImagesContextProvider>
      </SplitPanelContextProvider>
    </ApolloProvider>
  )
}

export default ContextProviders
