import { useContext, useState } from 'react'
import SimpleModal from 'ui/components/SimpleModal/SimpleModal'
import DetectionsContext from 'adapter/context/DetectionsContext'
import { DETECTION_TYPES } from 'domain/detection'
import Select from 'react-select'
import useGetCategories from 'hooks/models/categories/useGetCategories'
import './DetectionFilterModal.scss'

const formatCategoryOptions = (categories = []) =>
  categories.map((category) => ({
    value: category?.externalReference,
    label: category?.name,
  }))

const getCategoriesSelected = (categoriesOptions, categoriesSelected = {}) =>
  categoriesOptions.reduce((acc, option) => {
    if (categoriesSelected[option.value]) {
      return [...acc, option]
    }
    return acc
  }, [])

const getCategoriesValues = (categoriesOptions = []) =>
  categoriesOptions.reduce(
    (acc, option) => ({
      ...acc,
      [option.value]: true,
    }),
    {}
  )

const DetectionFilterModal = ({ isOpen, setIsOpen }) => {
  const {
    typeDetection,
    categoriesSelected,
    setTypeDetection,
    setCategoriesSelected,
  } = useContext(DetectionsContext)
  const { data: dataCategories = [], loading } = useGetCategories()
  const [valueDetection, setValue] = useState(typeDetection)
  const [categoriesValues, setCategoriesValues] = useState([])

  const optionA = 'anomaly'
  const optionALabel = 'Anomalía'
  const optionB = 'category'
  const optionBLabel = 'Conjunto'
  const optionGroup = 'detection-type'

  const handleClose = () => setIsOpen(false)

  const onChange = (event) => {
    event.persist()
    const { value } = event.target
    setValue(value)
  }

  const handleConfirm = () => {
    setTypeDetection(valueDetection)

    setCategoriesSelected(getCategoriesValues(categoriesValues))

    handleClose()
  }

  const categoriesOptions = formatCategoryOptions(dataCategories)

  const categoriesOptionsSelected = getCategoriesSelected(
    categoriesOptions,
    categoriesSelected
  )

  if (!isOpen) {
    return null;
  }

  return (
    <SimpleModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className="detection-filter-modal"
      actions={
        <div className="detection-filter-modal__buttons">
          <button
            className="button-clean --regular"
            variant="secondary"
            onClick={handleClose}
          >
            Cancelar
          </button>
          <button
            className="button-clean --regular"
            variant="primary"
            onClick={handleConfirm}
          >
            Aceptar
          </button>
        </div>
      }
    >
      {!loading && (
        <div className="detection-filter-modal__info">
          <h4>Tipo de detección</h4>
          <div className="detection-filter-modal__switchs">
            <label className={`switch__label ${optionA}`} htmlFor={'anomaly'}>
              {optionALabel}
            </label>
            <input
              className="switch__option"
              id={optionA}
              value={DETECTION_TYPES.ANOMALY}
              type="radio"
              name={optionGroup}
              defaultChecked={DETECTION_TYPES.ANOMALY === valueDetection}
              onChange={onChange}
            />
            <label className={`switch__label ${optionB}`} htmlFor={optionB}>
              {optionBLabel}
            </label>
            <input
                className="switch__option"
                id={optionB}
                value={DETECTION_TYPES.CATEGORY}
                type="radio"
                name={optionGroup}
                defaultChecked={DETECTION_TYPES.CATEGORY === valueDetection}
                onChange={onChange}
            />
          </div>

          <Select
            defaultValue={categoriesOptionsSelected}
            isMulti
            name="categoriesSelected"
            options={categoriesOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={setCategoriesValues}
            placeholder='Selecciona los conjuntos a visualizar (Ninguno para todos)'
          />
        </div>
      )}
    </SimpleModal>
  )
}

export default DetectionFilterModal
