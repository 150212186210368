import { gql } from '@apollo/client'
import { ANOMALIES } from '../anomalies/anomalies.fragment'
import { VALIDATION } from '../detection/validation.fragment'
import { ROIS } from '../partials/rois.fragment'

export const DETECTIONS = gql`
  ${ ANOMALIES }
  ${ VALIDATION }
  ${ ROIS }
  fragment DetectionsFragment on Detection {
    id
    type
    ...AnomalyFragment
    ...ValidationFragment
    ...RoisFragment
    severity {
      id
      value
    }
    crop {
      key
      smallKey
    }
    metadata {
      key
      value
    }
  }
`
