import { capitalize, trimUnderscore } from 'lib'
import { useCallback, useEffect } from 'react'
import { CATEGORIES_QUERY_WITH_HIERARCHY, field as CATEGORIES_FIELD } from 'infra/categories/categories.query'
import { addNameField } from 'infra'
import ANOMALIES_QUERY, { field as ANOMALIES_FIELD } from 'infra/anomalies/anomalies.query'
import useDependentQueries from '../../query/useDependentQueries'

const mainQuery = {
  query: CATEGORIES_QUERY_WITH_HIERARCHY,
  field: CATEGORIES_FIELD,
  variables: {
    hierarchy: true
  }
}

const dependentQuery = {
  query: ANOMALIES_QUERY,
  field: ANOMALIES_FIELD
}

function useCategorizeDetectionWithoutSeverity () {
  const getVariablesForAnomalies = useCallback(({ externalReference: ref }) =>
    ({ categoryExternalReference: ref }), [])

  const [category, anomaly] = useDependentQueries({
    main: mainQuery,
    dependent: dependentQuery,
    getVariables: getVariablesForAnomalies,
  })


  useEffect(() => {
    if (!category.selected) return
    anomaly.select(null)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.selected])

  const categoriesParsed = addNameField(category.collection, ({ name }) =>
    capitalize(trimUnderscore(name)))

  return {
    category: { ...category, collection: categoriesParsed },
    anomaly,
  }
}

export default useCategorizeDetectionWithoutSeverity
