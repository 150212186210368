import{ useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isVerticalRatio } from 'adapter/context/CropContext'
import useUrlOfKey from 'hooks/query/useUrlOfKey'
import './Crop.scss'

const ErrorMessage = ({ children }) => (
  <p className="crop__empty">{ children }</p>
)

const Crop = ({ url, urlKey: key, isVerticalOriginalImage }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isVerticalCrop, setIsVerticalCrop] = useState(isVerticalOriginalImage)
  const imageRef = useRef(null)

  const isDisabledClassName = url ? '': '--is-disabled'
  const isVerticalClassName = isVerticalCrop ? '--is-vertical': ''

  const { data: urlRequested, error } = useUrlOfKey({ key })

  useEffect(() => {
    if (!isLoaded || !imageRef.current) return
    const { clientWidth, clientHeight } = imageRef.current
    const isVertical = isVerticalRatio(clientWidth, clientHeight)
    setIsVerticalCrop(isVertical)
  }, [isLoaded, imageRef])

  if (!url && !key) return (
    <ErrorMessage>No hay foto asociada a este defecto</ErrorMessage>)
  if (error) return <ErrorMessage>Error al cargar la imagen</ErrorMessage>

  return (
    <section className={ `crop ${isDisabledClassName}` }>
      <img
        ref={ imageRef }
        src={ url || urlRequested }
        className={ `crop__image ${isVerticalClassName}` }
        onLoad={ () => setIsLoaded(true) }
        style={{ opacity: isLoaded ? 1: 0 }}
        alt="Crop"
        crossOrigin="Anonymous"
      />
      { !isLoaded && <span className="crop__skeleton" />}
    </section>
  )
}

Crop.propTypes = {
  url: PropTypes.string,
  urlKey: PropTypes.string,
  isVerticalOriginalImage: PropTypes.bool,
}

export default Crop
