const lng_radius = 0.00002 // (1)
const lat_to_lng = 111.23 / 71.7 // (2)
const lat_radius = lng_radius / lat_to_lng // (3)

function applyOffsetInItem (item, angle) {
  item.longitude = item.longitude + (Math.cos(angle) * lng_radius)
  item.latitude = item.latitude + (Math.sin(angle) * lat_radius)
}

function applyOffsetInCollection(collection) {
  let angle = 0.5 // (4)
  const step = 2 * Math.PI / collection.length

  collection.forEach((item) => {
    applyOffsetInItem(item, angle)
    angle += step
  })
  return collection
}

function applyOffsetInCollections (groups, callback) {
  groups.forEach(group => {
    const groupWithOffsetApplied = applyOffsetInCollection(group)
    callback(groupWithOffsetApplied)
  })
}

export default applyOffsetInCollections

// (1): degrees of longitude separation
// (2) lat to long proportion in Warsaw
// (3) degrees of latitude separation
// (4) starting angle, in radians
