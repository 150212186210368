import PropTypes from 'prop-types'
import './Headline.scss'

const Headline = ({ onClick, children, className = '' }) => (
  <div
    className={`headline ${className}`}
    onClick={ onClick }
  >{ children }</div>
)

Headline.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Headline
