import { gql } from '@apollo/client'
import createInput from 'lib/mutations'

export const field = 'reassignInspection'

const INPUT = {
  inspectionId: 'string.isRequired',
  userId: 'string.isRequired',
}

export const input = createInput(INPUT)

export const mutation = gql`
  mutation reassignInspection ($input: ReassignationInput!) {
    reassignInspection(input: $input) {
      assignedUser {
        id
        username
      }
    }
  }
`
