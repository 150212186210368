import PropTypes from 'prop-types'
import Pagination from 'ui/components/Pagination/Pagination'
import Detections from '../Detections/Detections'

const DetectionsList = ({
  inspection,
  info,
  onLoadMore,
  ...rest
}) => {
  const pageInfo = info?.pageInfo
  const hasNextPage = pageInfo?.hasNextPage

  return (
    <article className="layout-with-summary">
      <Detections
        date={ inspection?.createdAt }
        filesToDownload={ inspection?.metadata }
        className={ hasNextPage ? '--has-next-page': ''}
        { ...rest }
      />
      <Pagination
        className="wrapper-scroll__pagination"
        isSimplified
        disabled={ !hasNextPage }
        onLoadMore={ onLoadMore }
      />
    </article>
  )
}

const shape = PropTypes.shape

DetectionsList.propTypes = {
  inspection: shape({
    name: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string
  }),
  info: shape({
    pageInfo: shape({ hasNextPage: PropTypes.bool }),
    totalCount: PropTypes.number
  }),
  onLoadMore: PropTypes.func,
}
export default DetectionsList
