const IMAGES = {
  id: 'IMAGES_WITHOUT_TEXT',
  byFeatureFlag: ['images'],
  fileTypesSupported: ['image/*'],
  allowMultipleUploads: true,
  maxNumberOfFiles: null,
  withCheckGeolocation: true,
}

export default IMAGES
